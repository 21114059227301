<template>
	<div class="input-slider">

		<div class="input-slider-label flex-row flex-gap-small flex-center-v">
			<label :for="$attrs.id"
						 v-html='`${label} :`'></label>
			<output for="${$attrs.id}"
							v-if="!displayValue">{{ _value }}</output>
			<output for="${$attrs.id}"
							v-else>{{ displayValue }}</output>
		</div>

		<span class="hint"
					v-if="hint"
					v-html="hint"></span>

		<div class="range-slider">

			<span class="label label--before"
						v-if="before || $attrs['data-display-range']">
				<div v-if="$attrs['data-display-range'] && $attrs?.min">{{ $attrs.min }}<span v-if="$attrs['data-units']"> {{ $attrs['data-units'] }}</span></div>
				<span v-if="before"
							v-html="before"></span>
			</span>

			<div class="range-wrapper">
				<input type="range"
							 v-bind="$attrs"
							 v-model="_value">

				<div class="label label--middle">
					<!-- <span v-if="$attrs['data-display-range'] && $attrs?.max">{{ ($attrs.max / 2) }}</span> -->
					<span v-if="middle"
								v-html="middle"></span>
				</div>
			</div>

			<span class="label label--after"
						v-if="after || $attrs['data-display-range']">
				<div v-if="$attrs['data-display-range'] && $attrs?.max">{{ $attrs.max }}<span v-if="$attrs['data-units']"> {{ $attrs['data-units'] }}</span></div>
				<span v-if="after"
							v-html="after"></span>
			</span>

		</div>
	</div>
</template>


<script>
export default {
	name: "RangeInput",
	inheritAttrs: false,
	props: ['value', 'label', 'displayRange', 'before', 'after', 'middle', 'hint', 'displayValue',],

	computed: {
		// Model for the value prop of the component
		_value: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit("input", value);
			},
		},
	}
}
</script>


<style lang="scss"
			 scoped>

			.input-slider {
				display: flex;
				flex-flow: column wrap;
				gap: 1rem;

				// label {
				// 	font-weight: 500;
				// }

				output {
					border: 1px solid #d8d8d8;
					border-radius: 3px;
					font-variant-numeric: tabular-nums;
					min-width: 2.5em;
					padding: 0.25rem 0.5rem;
					text-align: center;
				}

				.label {
					color: #363636;
					align-items: center;
					display: flex;
					flex-flow: column wrap;
					// flex-basis: 20%;
					font-size: .875rem;
					gap: .5em;
					justify-content: space-evenly;
					text-align: center;
				}

				.range-slider {
					align-items: stretch;
					display: flex;
					flex-flow: row wrap;
					gap: 1rem;
					justify-content: center;

					// padding: 0.25em .5em;
					// border-radius: .25em;
					// border: 1px solid #dedede;

					.range-wrapper {
						flex: 1;

						[type="range"] {
							min-width: 6em;
						}
					}
				}

			}
		</style>