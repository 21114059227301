<template>
	<article class="view-users">
		<div class="site-wrapper rythm-v">
			<header class="rythm-v">
				<h1>Gestion des utilisateurs</h1>

				<div role="toolbar"
						 aria-label="Actions sur les utilisateurs"
						 class="toolbar flex-row flex-align-left flex-center-v card shadow">
					<router-link to="/users"
											 class="button--outline-inverse">
						<svg role="img"
								 aria-labelledby="button-new-user-title"
								 xmlns="http://www.w3.org/2000/svg"
								 width="24"
								 height="24"
								 viewBox="0 0 24 24"
								 fill="none"
								 stroke="currentColor"
								 stroke-width="2"
								 stroke-linecap="round"
								 stroke-linejoin="round"
								 class="icon feather feather-user-plus">
              <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
              <circle cx="8.5" cy="7" r="4" />
              <line x1="20" y1="8" x2="20" y2="14" />
              <line x1="23" y1="11" x2="17" y2="11" />
            </svg>
						<span class="caption"
									id="button-new-user-title">Liste des utilisateurs</span>
					</router-link>

					<router-link to="/users/new"
											 class="button--outline-inverse">
						<svg role="img"
								 aria-labelledby="button-new-user-title"
								 xmlns="http://www.w3.org/2000/svg"
								 width="24"
								 height="24"
								 viewBox="0 0 24 24"
								 fill="none"
								 stroke="currentColor"
								 stroke-width="2"
								 stroke-linecap="round"
								 stroke-linejoin="round"
								 class="icon feather feather-user-plus">
              <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
              <circle cx="8.5" cy="7" r="4" />
              <line x1="20" y1="8" x2="20" y2="14" />
              <line x1="23" y1="11" x2="17" y2="11" />
            </svg>
						<span class="caption"
									id="button-new-user-title">Ajouter un utilisateur</span>
					</router-link>

					<search-box v-if="currentRoute !== 'UsersNew'"
											class="input-group--inline rythm-h flex-item-right"></search-box>
				</div>
			</header>

			<div>
				<router-view></router-view>
			</div>
		</div>
	</article>
</template>

<style lang="scss">
@import "~@/scss/common-views.scss";
</style>

<script>
import searchBox from "@/components/searchBox.vue";

export default {
	name: "PageUsers",
	components: { searchBox },

	data: function () {
		return {};
	},

	computed: {
		currentRoute() {
			return this.$route.name;
		},
	},

	methods: {},

	mounted() { },
};
</script>
