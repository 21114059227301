// https://alligator.io/vuejs/rest-api-axios/
import { appConfig } from "./config.js";
import axios from "axios";
import { NProgress } from "./nprogress.js";
import { EventBus } from "./event-bus.js";

const HTTP = axios.create({
  baseURL: appConfig.API_BASE_URL,
});

// Indicateur de chargement à chaque requête AJAX
// https://www.digitalocean.com/community/tutorials/add-loading-indicators-to-your-vuejs-application

/*
// start before a request is made
HTTP.interceptors.request.use((config) => {
  NProgress.start();
  EventBus.$emit("trackEvent", "API Request", `${config.method} ${config.url}`);
  return config;
});

// stop when a response is returned
HTTP.interceptors.response.use((response) => {
  NProgress.done();
  EventBus.$emit(
    "trackEvent",
    "API Response",
    `${response.config.method} ${response.config.url} ${response.status} ${response.statusText}`
  );
  return response;
});
*/

export { HTTP };
