<template>
	<article class="view-legal">
		<div class="site-wrapper rythm-v">
			<section class="card rythm-v">
				<h2>Éditeur</h2>
				<p>
					Le site web
					<a href="https://adaric.fr/">adaric.fr</a>
					est édité par ADARIC.
				</p>
				<p>
					Le Directeur de la Publication est le Professeur Jérôme Avouac.
				</p>
			</section>
			<section class="card rythm-v">
				<h2>Création et hébergement</h2>
				<p>
					Le site a été créé par la société Anamorphik Studio S.A.R.L,
					représentée par M. Vincent Dhyèvre dont le siège social est 165 Avenue
					André Malraux – 54600 Villers-Lès-Nancy.
				</p>
				<ul>
					<li>RCS NANCY B 420 055 550</li>
					<li>SIRET 420 055 550 00040</li>
					<li>Tél. : +33 (0) 3 83 36 40 59</li>
					<li><a href="http://www.anamorphik.com/">www.anamorphik.com</a></li>
				</ul>
				<p>
					Le site est administré par la société
					<a href="http://www.anamorphik.com/">Anamorphik</a>.
				</p>
			</section>

			<section class="card rythm-v">
				<h2>Droit d’auteur</h2>
				<p>
					L’ensemble de ce site relève de la législation française et
					internationale sur le droit d’auteur et la propriété intellectuelle.
					Tous les droits de reproduction sont réservés, y compris pour les
					documents téléchargeables et les représentations iconographiques et
					photographiques. La reproduction de tout ou partie de ce site sur un
					support électronique quel qu’il soit est formellement interdite sauf
					autorisation expresse du directeur de publication. Vous pouvez
					contacter le secrétariat qui se chargera de transmettre votre demande
					et vous fera part de la réponse du directeur de publication.
				</p>
			</section>

			<section class="card rythm-v">
				<h2>Accès au site</h2>
				<p>
					L’utilisateur de ce site reconnaît disposer de la compétence et des
					moyens nécessaires pour accéder et utiliser ce site. ADARIC ne saurait
					être tenu responsable des éléments en dehors de son contrôle et des
					dommages qui pourraient éventuellement être subis par l’environnement
					technique de l’utilisateur et notamment, ses ordinateurs, logiciels,
					équipements réseaux et tout autre matériel utilisé pour accéder ou
					utiliser le service et/ou les informations. Il est rappelé que le fait
					d’accéder ou de se maintenir frauduleusement dans un système
					informatique, d’entraver ou de fausser le fonctionnement d’un tel
					système, d’introduire ou de modifier frauduleusement des données dans
					un système informatique constituent des délits passibles de sanctions
					pénales.
				</p>
			</section>

			<section class="card rythm-v">
				<h2>Contenu du site</h2>
				<p>
					ADARIC se réserve le droit de corriger, à tout moment et sans préavis,
					le contenu de ce site. La reproduction des contenus de ce site sur
					tout support n’est pas autorisée.
				</p>
			</section>

			<section class="card rythm-v">
				<h2>Liens Hypertextes</h2>
				<p>
					Le site de l'observatoire ADARIC contient des liens hypertextes
					permettant l’accès à des sites qui ne sont pas édités par ADARIC. En
					conséquence ce dernier ne saurait être tenu pour responsable du
					contenu des sites auxquels l’internaute aurait ainsi accès. La
					création de liens hypertextes vers le site de l'observatoire ADARIC
					doit faire l’objet d’une autorisation préalable de l’éditeur.
				</p>
			</section>

			<section class="card rythm-v">
				<h2 id="confidentialité">Politique de confidentialité</h2>
				<p>
					ADARIC traite vos données personnelles lors de la création de votre
					compte utilisateur, à toute fin légitime dans le cadre de
					l'utilisateur de l'application.
				</p>
				<p>
					Conformément à la loi informatique et libertés modifiée vous disposez
					d’un droit d’accès, de rectification et de suppression des données qui
					vous concernent.
				</p>
				<p>
					Vous avez accès et pouvez modifier ces informations à tout moment dans
					votre profil.
				</p>
			</section>

			<section class="card rythm-v">
				<h2 id="cookies">Cookies</h2>
				<p>
					L’utilisateur est informé que lors de ses visites sur le site, un
					cookie peut s’installer automatiquement et être conservé
					temporairement en mémoire ou sur son disque dur. Un cookie est un
					élément qui ne permet pas d’identifier l’utilisateur mais sert à
					enregistrer des informations relatives à la navigation de celui-ci sur
					le site internet. Les utilisateurs du site reconnaissent avoir été
					informés de cette pratique et autorisent ADARIC à l’employer. Ils
					pourront désactiver ce cookie par l’intermédiaire des paramètres
					figurant au sein de leur logiciel de navigation.
				</p>
			</section>

			<section class="card rythm-v">
				<h2>Analyse de l’audience du site</h2>
				<p>
					Ce site utilise <strong>Plausible Analytics</strong> pour analyser l’audience du site et
					améliorer son contenu.
				</p>
				<p>
					Cet outil utilise des cookies pour collecter des informations anonymes et générer des rapports sur l’audience et l’utilisation de notre site Web sans que les utilisateurs individuels soient identifiés personnellement.
					Plus d’informations sur les règles de confidentialité relatives à l’utilisation de Plausible : <a href="https://plausible.io/data-policy"
						 target="_blank">https://plausible.io/data-policy</a>
				</p>
				<p>
					Conformément à la loi informatique et libertés modifiée vous disposez
					d’un droit d’accès, de rectification et de suppression des données qui
					vous concernent.
				</p>
			</section>
		</div>
	</article>
</template>

<style lang="scss">
@import "~@/scss/config";
@import "~@/scss/06-components/components.card";
</style>

<style lang="scss" scoped>
.card {
	max-width: 85ch;
	margin-left: auto;
	margin-right: auto;
}
</style>

<script>
export default {
	name: "legal",

	components: {},

	data: function () {
		return {};
	},

	computed: {},

	methods: {},

	mounted: function () {
		if (this.$route.hash) {
			const el = document.querySelector(this.$route.hash);
			el && el.scrollIntoView();
		}
	},
};
</script>
