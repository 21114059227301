<template>
	<div :class="isRequired ? 'required' : ''">

		<div>
			<span class="label"
						v-html="label"></span>

			<span v-if="hint"
						class="hint"><em>{{ hint }}</em></span>
		</div>

		<div class="radios">
			<label :for="`${id}_on`">
				<input type="radio"
							 value="1"
							 :name="`${id}`"
							 :id="`${id}_on`"
							 :required="isRequired"
							 :checked="defaultValue === '1'"
							 v-model="status"
							 v-bind="$attrs" />
				<span class="caption">{{ captionOn }}</span>
			</label>

			<label :for="`${id}_off`">
				<input type="radio"
							 value="0"
							 :name="`${id}`"
							 :id="`${id}_off`"
							 :required="isRequired"
							 :checked="defaultValue === '1'"
							 v-model="status"
							 v-bind="$attrs" />
				<span class="caption">{{ captionOff }}</span>
			</label>
		</div>
	</div>
</template>

<script>
export default {
	name: "radio-toggle",
	inheritAttrs: false,

	props: {
		// The value of the component (status)
		value: {
			type: String,
			default: "",
		},

		// Unique slug for each instance inputs
		id: {
			type: String,
			default: "radio_toggle",
		},

		// Label for the group of inputs
		label: {
			type: String,
			default: "Label",
		},

		// Hint for the label
		hint: {
			type: String,
			default: "",
		},

		required: {
			type: Boolean,
			default: false,
		},

		defaultValue: {
			type: String,
			default: '',
		},

		captionOn: {
			type: String,
			default: "Oui",
		},

		captionOff: {
			type: String,
			default: "Non",
		},
	},

	data: function () {
		return {};
	},

	computed: {
		isRequired() {
			return this.required || false;
		},

		// Model for the value prop of the component
		status: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
	},
};
</script>
