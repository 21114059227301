<template>
  <button type="button" class="button" @click="handleClick()">
    <slot>Logout</slot>
  </button>
</template>

<script>
export default {
  name: "buttonLogout",
  methods: {
    handleClick: function(event) {
      this.$emit("click");
    },
  },
};
</script>
