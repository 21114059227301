<template>
  <article class="view-email-update">
    <div class="site-wrapper rythm-v">
      <header class="text-center">
        <h1>Modification de l’adresse e-mail</h1>
      </header>
      <section class="card shadow rythm-v">
        <p v-if="!tokenError">
          <strong
            >Votre nouvelle adresse e-mail est en cours de modification…</strong
          >
        </p>

        <div v-if="tokenError">
          <p>
            <strong>Ce lien de validation a expiré ou est invalide.</strong>
          </p>
          <p>
            <a href="/">Connectez-vous</a> pour essayer à nouveau de changer
            d’adresse e-mail.
          </p>
        </div>

        <p>
          Si vous rencontrez toujours des difficultés, n’hésitez pas à
          <a href="mailto:#">nous contacter.</a>
        </p>
      </section>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import "~@/scss/user-profile.scss";
</style>

<script>
import { HTTP } from "../http-common.js";

export default {
  name: "userEmailUpdate",

  // Route parameter
  props: ["userProfileID", "validationToken"],

  data: function() {
    return {
      tokenError: false,
    };
  },

  computed: {
    // Current user data
    user: function() {
      return this.$store.state.userData;
    },
  },

  methods: {
    // Redirect logged in/out user
    redirectUser: function() {
      if (this.user) {
        this.$router.push(`/user/${this.user.id}/profile`);
      } else {
        this.$router.push("/");
      }
    },

    // Update Email
    updateEmail: function() {
      if (!this.userProfileID || !this.validationToken) this.$router.push("/");

      this.$toasted.global.appInfo({
        message: "Mise à jour de l'adresse e-mail en cours…",
      });

      this.$store
        .dispatch("UPDATE_EMAIL", {
          userID: this.userProfileID,
          token: this.validationToken,
        })
        .then(() => {
          this.$toasted.clear();
          let toast = this.$toasted.global.appSuccess({
            message: "Votre adresse e-mail est modifiée !",
          });
          setTimeout(function() {
            toast.goAway();
          }, 5000);
          this.redirectUser();
        })
        .catch((error) => {
          let msg =
            "Ce lien de validation a expiré, impossible de modifier l'adresse e-mail.";

          if (error.status === 409) {
            msg = "Cette adresse e-mail est déjà utilisée&nbsp;!";
          }

          this.$toasted.clear();
          this.$toasted.global.appError({
            message: msg,
          });

          this.tokenError = true;
          // this.redirectUser();
        });
    },
  },

  mounted() {
    // Get the email update validation token from dynamic route
    this.updateEmail();
  },
};
</script>
