<template>
  <section class="section--profile card shadow rythm-v" v-if="users">
    <h2>Liste des utilisateurs</h2>

    <vue-good-table
      v-show="usersTable.rows.length"
      :fixed-header="true"
      :columns="usersTable.columns"
      :rows="usersTable.rows"
      :paginate="true"
      :pagination-options="{
        enabled: true,
        nextLabel: 'suivant',
        prevLabel: 'précédent',
        rowsPerPageLabel: 'lignes par page',
        ofLabel: 'sur',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'Toutes',
      }"
      :globalSearch="true"
      :search-options="{
        enabled: true,
        skipDiacritics: true,
        placeholder: 'Rechercher dans cette liste',
        externalQuery: searchTerms,
      }"
      styleClass="vgt-table striped"
    >
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'actions'" class="offscreen"
          >Actions</span
        >
        <span v-else>{{ props.column.label }}</span>
      </template>

      <template slot="table-row" slot-scope="props">
        <!-- Default columns -->
        <span v-if="['actions', 'email'].indexOf(props.column.field) === -1">{{
          props.row[props.column.field]
        }}</span>

        <!-- Default columns -->
        <span v-if="props.column.field === 'email'">
          <a :href="'mailto:' + props.row[props.column.field]">{{
            props.row[props.column.field]
          }}</a>
        </span>

        <!-- Action column -->
        <!-- User name: link to profile -->
        <router-link
          class="button--icon"
          :to="`/user/${props.row.id}/profile`"
          v-if="props.column.field == 'actions'"
        >
          <svg
            focusable="false"
            role="img"
            aria-labelledby="button-edit-user-title"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="icon icon-edit"
          >
            <title id="button-edit-user-title">
              Modifier
            </title>
            <path
              d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
            />
            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
          </svg>
        </router-link>

        <!-- Remove user button -->
        <button
          class="button--icon"
          v-if="
            props.column.field == 'actions' && userCan('delete_users', user)
          "
          @click="deleteUser(props.row.id)"
        >
          <svg
            aria-labelledby="button-remove-user-title"
            focusable="false"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="icon feather feather-user-x"
          >
            <title id="button-remove-user-title">
              Supprimer
            </title>
            <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
            <circle cx="8.5" cy="7" r="4" />
            <line x1="18" y1="8" x2="23" y2="13" />
            <line x1="23" y1="8" x2="18" y2="13" />
          </svg>
        </button>
      </template>
    </vue-good-table>
  </section>
</template>

<style lang="scss">
@import "~@/scss/common-views.scss";
</style>

<script>
// Event Bus
import { EventBus } from "@/event-bus.js";
// Vue Good Table
import { VueGoodTable } from "vue-good-table";

export default {
  name: "UsersList",
  components: {
    VueGoodTable,
  },

  data: function() {
    return {
      users: [],
      usersTable: {
        // custom table search input (in toolbar)
        searchTerms: "",
        columns: [
          {
            label: "Prénom",
            field: "firstname",
            sortable: true,
            tdClass: "row-firstname",
          },
          {
            label: "Nom",
            field: "lastname",
            sortable: true,
            tdClass: "row-lastname",
          },
          {
            label: "E-mail",
            field: "email",
            sortable: true,
            tdClass: "row-email",
          },
          {
            label: "Rôle",
            field: "role",
            sortable: true,
            tdClass: "row-role",
          },
          {
            label: "Action",
            field: "actions",
            sortable: false,
            tdClass: "row-actions",
          },
        ],
        rows: [],
      },
    };
  },

  methods: {
    // Delete User
    deleteUser(userID) {
      if (!window.confirm("Supprimer l’utilisateur ?")) return false;

      this.$store
        .dispatch("DELETE_USER", userID)
        .then((response) => {
          this.loadUsers();
        })
        .catch((error) => {
          console.warn(error);
        });
    },

    // Setup users table
    setupTable: function() {
      this.usersTable.rows = this.users;

      // Duplicate actions on mobile
      if (this.isMobile(window.navigator).any) {
        let actionsColumn = {
          label: "Action",
          field: "actions",
          sortable: false,
          tdClass: "row-actions",
        };
        this.usersTable.columns = [actionsColumn, ...this.usersTable.columns];
      }
    },

    // Get all users
    loadUsers: function() {
      this.$store
        .dispatch("GET_USERS")
        .then((response) => {
          this.users = response.filter((user) => user.id !== this.user.id);
          this.setupTable();
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },

  computed: {
    searchTerms() {
      return this.$store.state.searchTerms;
    },
  },

  mounted() {
    // List users
    this.loadUsers();

    // Search event
    EventBus.$on("search", (event) => {
      console.log("EventBus", event);
      this.usersTable.searchTerms = event.terms;
    });
  },
};
</script>
