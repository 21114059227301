<template>

	<div id="dasSelect"
			 class="das-select"
			 :data-set="currentSet"
			 :data-zone="zone">

		<div id="zoneDisplay"
				 class="zone-display">
			<zoneDisplaySvg :ref="`svgDisplay_${_uid}`"></zoneDisplaySvg>
		</div>

		<div id="zoneSelector"
				 class="zone-selector">
			<zoneSelectorSvg ref="svgSelector"></zoneSelectorSvg>
		</div>

	</div>

</template>



<script>
import zoneSelectorSvg from "@/assets/svg/zone-selector.svg";
import zoneDisplaySvg from "@/assets/svg/zone-display.svg";

// import VuePhotoZoomPro from 'vue-photo-zoom-pro';
// import 'vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css';

export default {
	name: "DasSelect",

	components: {
		zoneSelectorSvg,
		zoneDisplaySvg
	},

	props: ['value', 'set'],

	data() {
		return {
			zone: "upper", // upper, center, lower
			checkedDots: [],
			score: {}
		};
	},


	computed: {

		currentSet() {
			return this?.set || "das";
		}

	},

	mounted() {

		const $svgSelector = this.$refs.svgSelector;
		const $svgDisplay = this.$refs[`svgDisplay_${this._uid}`];
		const $zoneUpper = $svgSelector.querySelector("#zone-upper");
		const $zoneCenter = $svgSelector.querySelector("#zone-center");
		const $zoneLower = $svgSelector.querySelector("#zone-lower");

		// bind events
		$svgSelector.addEventListener("click", (event) => {
			const $target = event.target;

			[$zoneUpper, $zoneCenter, $zoneLower].forEach((zone) => {
				zone.classList.remove("active");
			});

			if ($target.closest("#zone-upper")) {
				this.zone = "upper";
			} else if ($target.closest("#zone-center")) {
				this.zone = "center";
			} else if ($target.closest("#zone-lower")) {
				this.zone = "lower";
			}
		});


		$svgDisplay.addEventListener("click", (event) => {
			const $target = event.target;
			const $dot = $target?.closest("circle");
			const $group = $dot?.closest("g");

			if ($dot && $group) {
				$dot.classList.toggle("active");

				if ($dot.classList.contains("active")) {
					this.checkedDots.push($group.id);
				} else {
					this.checkedDots = this.checkedDots.filter((groupId) => groupId !== $group.id);
				}

				this.$emit("input", this.checkedDots);
			}
		});

		this.initData();
	},


	methods: {
		// setup svgDisplay dot classes from checkedDots
		initData() {
			this.checkedDots.forEach((groupId) => {
				const $dot = this.$refs[`svgDisplay_${this._uid}`].querySelector(`#${groupId} circle`);
				$dot.classList.add("active");
			})
		}

	},

	watch: {
		// Init data values from component props ("value")
		value: {
			immediate: true,
			handler(data) {
				this.checkedDots = data || [];
			},
		},
	},

}

</script>


<style lang="scss"
			 scoped>

			.das-select {
				align-items: flex-end;
				background: #fafafa;
				border-radius: 6px;
				border: 1px solid #ccc;
				box-sizing: border-box;
				display: flex;
				flex-flow: row wrap;
				gap: 1rem;
				justify-content: center;
				position: relative;

				.zone-selector {
					display: flex;
					flex-flow: row wrap;
					justify-content: center;
					align-items: center;
				}

				// .zone-display {
				// 	// center display
				// 	margin-left: 9rem;
				// }

				#skeleton {
					opacity: .5;
				}

				/* display variables */
				--scale-upper: 1;
				--scale-center: 1;
				--scale-lower: 1.5;

				--offset-upper: -15%;
				--offset-center: -120%;
				--offset-lower: -260%;

				/* defaults */
				--offset-top: var(--offset-upper);
				--scale: 1;

				#das-dots,
				#dapsa-dots {
					display: none;
				}

				&[data-set="das"] {
					#das-dots {
						display: initial;
					}

					#zone-lower {
						display: none;
					}
				}

				&[data-set="dapsa"] {
					#dapsa-dots {
						display: initial;
					}

					#zone-lower {
						display: initial;
					}
				}

				&[data-zone="upper"] {
					--offset-top: var(--offset-upper);
					--scale: var(--scale-upper);
				}

				&[data-zone="center"] {
					--offset-top: var(--offset-center);
					--scale: var(--scale-center);
				}

				&[data-zone="lower"] {
					--offset-top: var(--offset-lower);
					--scale: var(--scale-lower);
				}

				svg {
					width: 100%;
					height: auto;
				}

				.zone-selector {
					width: 8rem;
					padding: 1rem;
					background: white;
					border-radius: 6px;
					box-shadow: 0 0 10px #00000029;
					margin-bottom: 2rem;
				}

				.zone-display {
					border-radius: inherit;
					height: 36rem;
					width: 36rem;
					overflow: hidden;
					position: relative;
				}

				.zone-display-svg {
					position: absolute;
					left: 50%;
					top: var(--offset-top);
					transform: translateX(-50%) scale(var(--scale));
					transition: top 0.3s cubic-bezier(0.22, 1, 0.36, 1), transform 0.3s cubic-bezier(0.22, 1, 0.36, 1);

					circle {
						cursor: pointer;

						&:hover {
							fill: hsl(342, 84%, 48%);
						}

						&.active {
							fill: hsl(342, 84%, 48%);
						}
					}
				}

				.zone-selector-svg {

					#zone-upper,
					#zone-center-right-hand,
					#zone-center-left-hand,
					#zone-center-hips,
					#zone-lower {
						cursor: pointer;
						opacity: 0;
						transition: opacity 0.3s cubic-bezier(0.22, 1, 0.36, 1);
					}

					#zone-upper,
					#zone-lower {
						&:hover {
							opacity: 1;
						}
					}

					#zone-center:hover {

						#zone-center-right-hand,
						#zone-center-left-hand,
						#zone-center-hips {
							opacity: 1;
						}
					}
				}

				&[data-zone="upper"] #zone-upper {
					opacity: 1;
				}

				&[data-zone="center"] #zone-center {

					#zone-center-right-hand,
					#zone-center-left-hand,
					#zone-center-hips {
						opacity: 1;
					}
				}

				&[data-zone="lower"] #zone-lower {
					opacity: 1;
				}
			}

			@media screen and (max-width: 850px) {
				.das-select {

					// // v1
					// .zone-display {
					// 	height: auto;
					// 	width: 36rem;
					// 	overflow: visible;
					// 	position: relative;
					// 	margin: 1rem;
					// }

					.zone-display-svg {
						position: relative;
						left: unset;
						transform: none;
					}


					// v2
					.zone-display {
						height: 36rem;
						margin: 0;
						max-height: 80dvh;
						max-height: 80svh;
						overflow: scroll;
						position: relative;
						width: 100%;
					}

					// v2
					.zone-display-svg {
						width: 250%;
					}

					.zone-selector {
						display: none;
					}

				}
			}


		</style>