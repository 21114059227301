<template>
	<section class="card shadow rythm-v"
					 v-if="cases">
		<h2>Liste des patients</h2>

		<vue-good-table v-if="cases.length"
										:fixed-header="true"
										:columns="casesTable.columns"
										:rows="casesTable.rows"
										:paginate="true"
										:pagination-options="{
											enabled: true,
											nextLabel: 'suivant',
											prevLabel: 'précédent',
											rowsPerPageLabel: 'lignes par page',
											ofLabel: 'sur',
											pageLabel: 'page', // for 'pages' mode
											allLabel: 'Toutes',
										}"
										:globalSearch="true"
										:search-options="{
											enabled: true,
											skipDiacritics: true,
											placeholder: 'Rechercher dans cette liste',
											// externalQuery: searchTerms,
										}"
										styleClass="vgt-table striped">

			<div slot="emptystate">
				<div class="text-center"><em>Aucun patient</em></div>
			</div>

			<template slot="table-column"
								slot-scope="props">
				<span v-if="props.column.field == 'actions'"
							class="offscreen">Actions</span>
				<span v-else>{{ props.column.label }}</span>
			</template>

			<template slot="table-row"
								slot-scope="props">

				<div v-if="props.column.field == 'actions'">
					<!-- Action column -->
					<!-- User name: link to profile -->
					<router-link class="button--icon"
											 :to="`/cases/${props.row.id}/patient`"
											 v-if="props.column.field == 'actions'">
						<svg focusable="false"
								 role="img"
								 aria-labelledby="button-edit-title"
								 xmlns="http://www.w3.org/2000/svg"
								 width="24"
								 height="24"
								 viewBox="0 0 24 24"
								 fill="none"
								 stroke="currentColor"
								 stroke-width="2"
								 stroke-linecap="round"
								 stroke-linejoin="round"
								 class="icon icon-edit">
              <title id="button-edit-title">
                Modifier
              </title>
              <path
                d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
              />
              <path
                d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
              />
            </svg>
					</router-link>

					<!-- Remove case button -->
					<button class="button--icon"
									v-if="props.column.field == 'actions' && userCan('delete_cases', user)
									"
									@click="deleteCase(props.row.id)">
						<svg aria-labelledby="button-remove-title"
								 focusable="false"
								 role="img"
								 xmlns="http://www.w3.org/2000/svg"
								 width="24"
								 height="24"
								 viewBox="0 0 24 24"
								 fill="none"
								 stroke="currentColor"
								 stroke-width="2"
								 stroke-linecap="round"
								 stroke-linejoin="round"
								 class="icon feather feather-user-x">
              <title id="button-remove-title">
                Supprimer
              </title>
              <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
              <circle cx="8.5" cy="7" r="4" />
              <line x1="18" y1="8" x2="23" y2="13" />
              <line x1="23" y1="8" x2="18" y2="13" />
            </svg>
					</button>
				</div>

				<!-- Case status -->
				<span v-if="props.column.field == 'case_status'"
							:class="isCaseComplete(props.row) === 'terminé'
								? 'tag--success'
								: 'tag--warning'
								">{{ isCaseComplete(props.row) }}</span>

				<!-- Desease type -->
				<span v-if="props.column.field == 'desease_data'">{{ props.row.desease_data.disease_type }}</span>

				<!-- Default columns -->
				<span v-if="props.column.field !== 'desease_data'">{{
					props.formattedRow[props.column.field]
				}}</span>
			</template>
		</vue-good-table>

		<div v-else
				 class="alert-info">
			<div class="text-center"><em>Aucun patient pour le moment.</em></div>
		</div>
	</section>
</template>

<style lang="scss">
@import "~@/scss/common-views.scss";
@import "~@/scss/06-components/_components.tag.scss";
@import "~@/scss/06-components/_components.schema-gma.scss";

.view-dashboard .site-wrapper {
	max-width: 1366px !important;
}

.view-dashboard .schema-string>* {
	margin: 0.25rem;
}
</style>


<script>
// Event Bus
import { EventBus } from "@/event-bus.js";
import { isObjectEmpty } from '../libs/helpers.js';

// Vue Good Table
import { VueGoodTable } from "vue-good-table";

export default {
	name: "DashboardListCases",
	components: {
		VueGoodTable,
	},

	data: function () {
		return {
			centers: [],
			cases: [],
			centersFilterItems: [],
			filterCenterID: null,
			casesTable: {
				// custom table search input (in toolbar)
				searchTerms: "",
				columns: [
					{
						label: "Initiales",
						field: "initials",
						sortable: true,
						tdClass: "row-initials",
					},
					{
						label: "N° de patient",
						field: "pid",
						sortable: true,
						tdClass: "row-pid",
					},
					{
						label: "Type de RIC",
						field: "desease_data",
						sortable: false,
						tdClass: "row-pid",
					},
					{
						label: "Centre",
						field: "center_name",
						sortable: true,
						filterable: true,
						tdClass: "row-center-name",
						filterOptions: {
							enabled: true,
							placeholder: "Tous les centres",
							// NOTE: Not working as it should…
							filterDropdownItems: [],
							filterValue: null,
							filterFn: this.filterByCenter
						},
						sortFn: this.invertOrder,
					},
					{
						label: "Statut",
						field: "case_status",
						sortable: true,
						tdClass: "row-case-status",
						sortFn: this.sortStatus,
					},
					{
						label: "Action",
						field: "actions",
						sortable: false,
						tdClass: "row-actions",
					},
				],
				rows: [],
			},
		};
	},

	methods: {

		// Filter cases by center
		filterByCenter(data, filterString) {
			let centerId = parseInt(filterString);
			let center = this.centers.find((c) => parseInt(c.id) === centerId);
			if (center) return data === center.name;
			return false;
		},

		// Invert column order
		invertOrder(x, y, col, rowX, rowY) {
			return 1;
		},

		// Sort by status
		sortStatus(x, y, col, rowX, rowY) {
			return this.isCaseComplete(rowX || rowY) === "terminé" ? -1 : 1;
		},

		// A case is complete if at least the intro session is completed and study_end is set
		isCaseComplete(row) {
			if (!row?.sessions || isObjectEmpty(row?.sessions)) return "en cours";
			const isIntroductionCompleted = row?.sessions?.s1?.form_completed || false;
			const date_study_end = row?.study_end?.date_study_end;
			const user_signature_date = row?.study_end?.user_signature_date;
			// console.log("status", isIntroductionCompleted, date_study_end, user_signature_date);
			return (isIntroductionCompleted && date_study_end && user_signature_date) ? "terminé" : "en cours";
		},

		// Delete Case (confirm…)
		deleteCase(caseID) {
			if (!window.confirm("Supprimer le patient ?")) return false;

			this.$store
				.dispatch("DELETE_CASE", caseID)
				.then((response) => {
					this.loadCases();
				})
				.catch((error) => {
					console.warn(error);
				});
		},

		// Setup table
		setupTable: function () {
			this.casesTable.rows = this.cases;

			// Duplicate actions on mobile
			if (this.isMobile(window.navigator).any) {
				let actionsColumn = {
					label: "Action",
					field: "actions",
					sortable: false,
					tdClass: "row-actions",
				};
				this.casesTable.columns = [actionsColumn, ...this.casesTable.columns];
			}

			// FIX: centers filter dropdown options
			this.casesTable.columns.map(c => {
				if (c.field === 'center_name') {
					c.filterOptions.filterDropdownItems = [...new Set(this.centersFilterItems.map(c => {
						return { text: `${c.center_name} (${c.case_count})`, value: c.center_id };
					}))];
					c.filterOptions.filterValue = this.filterCenterID;
				}
			});
		},

		// Get all cases ("patients")
		loadCases: function () {
			this.$store
				.dispatch("GET_CASES")
				.then((response) => {
					this.cases = response;

					// Setpup table
					this.setupTable();

				})
				.catch((error) => {
					console.warn(error);
				});
		},

		// Get all centers
		getCenters: function () {
			let centers = this.$store
				.dispatch("GET_CENTERS")
				.then((response) => {
					return response;
				})
				.catch((error) => {
					console.warn(error);
					return [];
				});
			return centers;
		},
	},

	computed: {

		searchTerms() {
			return this.$store.state.searchTerms;
		},
	},

	async mounted() {

		// Get the center id from URL query parameter
		if (this.$route.query.center_id) {
			this.filterCenterID = this.$route.query.center_id;
		}

		// Load centers
		this.centers = await this.getCenters();

		// centers filter dropdown options
		this.centersFilterItems = this.centers.map(c => {
			return { center_id: c.id, center_name: c.name, case_count: c.case_count };
		});

		// List cases
		await this.loadCases();

		// Search event
		// EventBus.$on("search", (event) => {
		//   console.log("EventBus", event);
		//   this.usersTable.searchTerms = event.terms;
		// });
	},
};
</script>
