<template>
	<section class="rythm-v section--score">
		<hgroup id="basdai-index">
			<h4>Index de BASDAI</h4>
			<p class="small">Bath Ankylosing Spondylitis Disease Activity Index</p>
		</hgroup>

		<p><strong>Au cours des 8 derniers jours :</strong></p>

		<ol class="rythm-v-large">
			<li>
				<RangeInput id="fatigue"
										label="Où situez-vous votre degré global de fatigue ?"
										before="Absent"
										after="Extrème"
										min="0"
										max="10"
										data-display-range="true"
										required
										v-bind="$attrs"
										v-model="localValues.fatigue"
										@input="emitScore" />
			</li>
			<li>
				<RangeInput id="pain"
										label="Où situez-vous votre degré global de douleur au niveau du cou, du dos et des hanches<br> dans le cadre de votre spondylarthrite ankylosante ?"
										before="Absent"
										after="Extrème"
										min="0"
										max="10"
										data-display-range="true"
										required
										v-bind="$attrs"
										v-model="localValues.pain"
										@input="emitScore" />
			</li>
			<li>
				<RangeInput id="swelling"
										label="Où situez-vous votre degré de gonflement articulaire en dehors du cou, du dos et des hanches ?"
										before="Absent"
										after="Extrème"
										min="0"
										max="10"
										data-display-range="true"
										required
										v-bind="$attrs"
										v-model="localValues.swelling"
										@input="emitScore" />
			</li>
			<li>
				<RangeInput id="discomfort"
										label="Où situez-vous votre degré de gêne pour les zones sensibles au toucher ou à la pression ?"
										before="Absent"
										after="Extrème"
										min="0"
										max="10"
										data-display-range="true"
										required
										v-bind="$attrs"
										v-model="localValues.discomfort"
										@input="emitScore" />
			</li>
			<li>
				<RangeInput id="stiffness"
										label="Où situez-vous votre degré de raideur matinale depuis votre réveil ?"
										before="Absent"
										after="Extrème"
										min="0"
										max="10"
										data-display-range="true"
										required
										v-bind="$attrs"
										v-model="localValues.stiffness"
										@input="emitScore" />
			</li>
			<li>
				<RangeInput id="stiffness"
										label="Quelle est la durée de votre raideur matinale à partir de votre réveil ?"
										before="Aucune"
										after="2 h ou plus"
										min="0"
										max="10"
										:displayValue="stiffnessDurationInHours"
										required
										v-bind="$attrs"
										v-model="localValues.stiffness_duration"
										@input="emitScore" />
			</li>
		</ol>


		<div class="alert-success w-100">
			<ul>
				<li><span class="label">Index de BASDAI :</span> <strong>{{ score }}</strong></li>
			</ul>
		</div>

	</section>

</template>


<script>
import RangeInput from "@/components/RangeInput.vue";

export default {
	name: 'ScoreBasdai',
	inheritAttrs: false,
	props: ['value'],

	components: {
		RangeInput
	},

	data() {
		return {
			localValues: {
				fatigue: 0,
				pain: 0,
				swelling: 0,
				discomfort: 0,
				stiffness: 0,
				stiffness_duration: 0
			},

			stiffness_duration_steps: [
				'Aucune',
				'0 – 15 min',
				'15 – 30 min',
				'30 – 45 min',
				'45 – 60 min',
				'1 h',
				'60 – 75 min',
				'75 – 90 min',
				'90 -105 min',
				'105 min – 2 h',
				'2 h ou plus'
			]

		}
	},

	computed: {

		stiffnessDurationInHours() {
			return this.stiffness_duration_steps[this.localValues.stiffness_duration];
		},

		stiffnessAverage() {
			return (parseInt(this.localValues.stiffness) + parseInt(this.localValues.stiffness_duration)) / 2;
		},

		score() {
			/*
			Faites la moyenne de `stiffness` et `stiffness_duration` pour obtenir la cote 5.
			Vous avez maintenant 5 cotes entre 0 et 10. Faites la moyenne (Additionnez les 5 cotes et divisez la somme par 5). 
			Vous avez l’index BASDAI, compris entre 0 et 10.
			*/
			const fatigue = parseInt(this.localValues.fatigue);
			const pain = parseInt(this.localValues.pain);
			const swelling = parseInt(this.localValues.swelling);
			const discomfort = parseInt(this.localValues.discomfort);

			return (fatigue + pain + swelling + discomfort + this.stiffnessAverage) / 5;
		},

		results() {
			return {
				score: `${this.score}`, // raw score always as string
				scoreRendered: `Index de BASDAI : ${this.score}`, // formatted score, ex. with unit 24%, 12mm, …
				values: { ...this.localValues }, // local values "as is" (do not cast, etc.)
				details: {
					stiffness_duration_in_hours: this.stiffnessDurationInHours,
					stiffness_average: this.stiffnessAverage,
				},
				detailsRendered: '',
			};
		}
	},

	methods: {
		emitScore() {
			this.$emit('input', this.results);
		}
	},

	watch: {
		// Init local model from `value` prop
		value: {
			immediate: true,
			handler: function (results) {
				if (results?.values) {
					this.localValues = { ...results.values }
				}
			},
		},
	},

}

</script>

<style lang="scss" scoped>
::v-deep {
	.input-slider {

		.input-slider-label,
		.range-slider {
			flex-basis: 100% !important;
		}

		.input-slider-label {
			gap: 1rem;
		}

		.label--before,
		.label--after {
			flex-basis: 15%;
		}
	}
}
</style>