<template>
	<div class="card shadow rythm-v"
			 v-if="caseData?.treatments">

		<form ref="saveForm"
					id="saveForm"
					method="post"
					@submit.prevent="saveCase"></form>

		<div class="form-wrapper rythm-v-large">

			<h2><strong>Inclusion</strong> – Traitements avant l’introduction d’ADACOLUMN®</h2>

			<!-- Traitements concomitants liés à d’autres pathologies -->
			<!-- NOTE: additionnal treatments only for "concomitant" -->
			<details class="rythm-v-large">
				<summary>
					<h3>
						Traitements <strong>concomitants liés à d’autres pathologies</strong>
					</h3>
				</summary>

				<section class="section--additionnal-treatments rythm-v">
					<div class="alert-info">
						<IconInfo></IconInfo>
						<p>Traitement en cours depuis au moins 1 mois</p>
					</div>

					<table v-if="Object.values(caseData.treatments?.concomitant?.additional).length">
						<thead>
							<tr>
								<th>Nom</th>
								<th>Dosage</th>
								<th></th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="(addt, index) in caseData.treatments.concomitant.additional"
									:key="`addt_concomitant_${index}`">
								<td>{{ addt.label }}</td>
								<td>{{ addt.dosage }}</td>
								<td class="text-right">
									<button type="button"
													class="button--danger--outline"
													title="Supprimer"
													@click="removeTreatment('concomitant', addt)">
										<IconBin class="icon" />
									</button>
								</td>
							</tr>
						</tbody>
					</table>

					<div class="card rythm-v">
						<!-- <p class="flex-row flex-center-v flex-gap-small">
							<IconPlus class="icon" /><strong>Ajouter un traitement synthétique ciblé</strong>
						</p> -->

						<!-- Add Treatment -->
						<div class="flex-row flex-gap flex-align-bottom">
							<div class="flex-item--grow">
								<div class="input-group">
									<label for="addt_concomitant_label">Nom</label>
									<input type="text"
												 name="addt_concomitant_label"
												 id="addt_concomitant_label"
												 v-model="additionnalTreatments.concomitant.label" />
								</div>
							</div>

							<div class="flex-item--grow">
								<div class="input-group">
									<label for="addt_concomitant_dosage">Dosage</label>
									<input type="text"
												 name="addt_concomitant_dosage"
												 id="addt_concomitant_dosage"
												 v-model="additionnalTreatments.concomitant.dosage" />
								</div>
							</div>

							<!-- <div class="flex-item--grow">
								<radio-group class="radio-group--inline"
														 :radios="stops"
														 label="Arrêt :"
														 name="addt_concomitant_stop"
														 id="addt_concomitant_stop"
														 v-model="additionnalTreatments.concomitant.stop"
														 required></radio-group>
							</div> -->

							<div class="flex-item">
								<div class="input-group--controls">
									<button class="button--secondary"
													type="button"
													@click="addTreatment('concomitant')"
													:disabled="!additionnalTreatments.concomitant.label">
										<IconPlus class="icon" />
										<span class="caption">Ajouter</span>
									</button>
								</div>
							</div>
						</div>
					</div>

				</section>
			</details>


			<!-- Traitements conventionnels -->
			<details class="rythm-v-large">
				<summary>
					<h3 class="required">
						Traitements <strong>conventionnels</strong> <!-- essayés avant l’introduction d'ADACOLUMN® -->
					</h3>
				</summary>

				<div class="stripped-children">
					<Treatment v-for="(treatment, index) in caseData.treatments.conventional_before"
										 :key="`treatment_${index}`"
										 v-model="caseData.treatments.conventional_before[treatment.slug]"
										 form="saveForm"
										 :mode="['methotrexate'].includes(treatment.slug)"
										 required></Treatment>
				</div>

				<section class="section--additionnal-treatments rythm-v">
					<h4>Autres traitements conventionnels</h4>

					<table v-if="Object.values(caseData.treatments?.conventional_before.additional).length">
						<thead>
							<tr>
								<th>Nom</th>
								<th>Forme</th>
								<th>Dosage</th>
								<th>Arrêt <small class="hint">(Motif si arrêt)</small></th>
								<th></th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="(addt, index) in caseData.treatments.conventional_before.additional"
									:key="`addt_conventional_${index}`">
								<td>{{ addt.label }}</td>
								<td>{{ addt.mode }}</td>
								<td>{{ addt.dosage }}</td>
								<td>{{ addt.stop }}</td>
								<td class="text-right">
									<button type="button"
													class="button--danger--outline"
													title="Supprimer"
													@click="removeTreatment('conventional_before', addt)">
										<IconBin class="icon" />
									</button>
								</td>

							</tr>
						</tbody>
					</table>

					<div class="card rythm-v">
						<!-- <p class="flex-row flex-center-v flex-gap-small">
							<IconPlus class="icon" /><strong>Ajouter un traitement conventionnel</strong>
						</p> -->

						<!-- Add Treatment -->
						<div class="flex-row flex-gap flex-align-bottom">

							<div class="flex-item--grow">
								<div class="input-group">
									<label for="">Nom</label>
									<input type="text"
												 name="addt_conventional_before_label"
												 id="addt_conventional_before_label"
												 v-model="additionnalTreatments.conventional_before.label" />
								</div>
							</div>

							<div class="flex-item--grow">
								<div class="input-group">
									<label for="addt_conventional_before_mode">Forme</label>
									<input type="text"
												 name="addt_conventional_before_mode"
												 id="addt_conventional_before_mode"
												 v-model="additionnalTreatments.conventional_before.mode" />
								</div>
							</div>

							<div class="flex-item--grow">
								<div class="input-group">
									<label for="addt_conventional_before_dosage">Dosage</label>
									<input type="text"
												 name="addt_conventional_before_dosage"
												 id="addt_conventional_before_dosage"
												 v-model="additionnalTreatments.conventional_before.dosage" />
								</div>
							</div>

							<div class="flex-item--grow">
								<radio-group class="radio-group--inline"
														 :radios="stops"
														 label="Arrêt :"
														 name="addt_conventional_before_stop"
														 id="addt_conventional_before_stop"
														 v-model="additionnalTreatments.conventional_before.stop"
														 required></radio-group>
							</div>

							<div class="flex-item">
								<div class="input-group--controls">
									<button class="button--secondary"
													type="button"
													@click="addTreatment('conventional_before')"
													:disabled="!additionnalTreatments.conventional_before.label || !additionnalTreatments.conventional_before.stop">
										<IconPlus class="icon" />
										<span class="caption">Ajouter</span>
									</button>
								</div>
							</div>
						</div>
					</div>

				</section>

			</details>


			<!-- Traitements biothérapies -->
			<details class="rythm-v-large">
				<summary>
					<h3 class="required">
						Traitements <strong>biothérapies</strong> <!-- essayés avant l’introduction d'ADACOLUMN® -->
					</h3>
				</summary>

				<div class="stripped-children">
					<Treatment v-for="(treatment, index) in caseData.treatments.biotherapies_before"
										 :key="`treatment_${index}`"
										 form="saveForm"
										 v-model="caseData.treatments.biotherapies_before[treatment.slug]"
										 required></Treatment>
				</div>

				<section class="section--additionnal-treatments rythm-v">
					<h4>Autres traitements biothérapies</h4>

					<table v-if="Object.values(caseData.treatments?.biotherapies_before.additional).length">
						<thead>
							<tr>
								<th>Nom</th>
								<th>Dosage</th>
								<th>Arrêt <small class="hint">(Motif si arrêt)</small></th>
								<th></th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="(addt, index) in caseData.treatments.biotherapies_before.additional"
									:key="`addt_biotherapies_${index}`">
								<td>{{ addt.label }}</td>
								<td>{{ addt.dosage }}</td>
								<td>{{ addt.stop }}</td>
								<td class="text-right">
									<button type="button"
													class="button--danger--outline"
													title="Supprimer"
													@click="removeTreatment('biotherapies_before', addt)">
										<IconBin class="icon" />
									</button>
								</td>
							</tr>
						</tbody>
					</table>

					<div class="card rythm-v">
						<!-- <p class="flex-row flex-center-v flex-gap-small">
							<IconPlus class="icon" /><strong>Ajouter un traitement biothérapie</strong>
						</p> -->

						<!-- Add Treatment -->
						<div class="flex-row flex-gap flex-align-bottom">
							<div class="flex-item--grow">
								<div class="input-group">
									<label for="addt_biotherapies_before_label">Nom</label>
									<input type="text"
												 name="addt_biotherapies_before_label"
												 id="addt_biotherapies_before_label"
												 v-model="additionnalTreatments['biotherapies_before'].label" />
								</div>
							</div>

							<div class="flex-item--grow">
								<div class="input-group">
									<label for="addt_biotherapies_before_dosage">Dosage</label>
									<input type="text"
												 name="addt_biotherapies_before_dosage"
												 id="addt_biotherapies_before_dosage"
												 v-model="additionnalTreatments['biotherapies_before'].dosage" />
								</div>
							</div>

							<div class="flex-item--grow">
								<radio-group class="radio-group--inline"
														 :radios="stops"
														 label="Arrêt :"
														 name="addt_biotherapies_before_stop"
														 id="addt_biotherapies_before_stop"
														 v-model="additionnalTreatments['biotherapies_before'].stop"
														 required></radio-group>
							</div>

							<div class="flex-item">
								<div class="input-group--controls">
									<button class="button--secondary"
													type="button"
													@click="addTreatment('biotherapies_before')"
													:disabled="!additionnalTreatments['biotherapies_before'].label || !additionnalTreatments['biotherapies_before'].stop">
										<IconPlus class="icon" />
										<span class="caption">Ajouter</span>
									</button>
								</div>
							</div>
						</div>
					</div>

				</section>

			</details>


			<!-- Traitements synthétiques ciblés -->
			<details class="rythm-v-large">
				<summary>
					<h3 class="required">
						Traitements <strong>synthétiques ciblés</strong>
					</h3>
				</summary>

				<div class="stripped-children">
					<Treatment v-for="(treatment, index) in caseData.treatments.synthetic_before"
										 :key="`treatment_${index}`"
										 v-model="caseData.treatments.synthetic_before[treatment.slug]"
										 form="saveForm"
										 required></Treatment>
				</div>

				<section class="section--additionnal-treatments rythm-v">
					<h4>Autres traitements synthétiques ciblés</h4>

					<table v-if="Object.values(caseData.treatments?.synthetic_before.additional).length">
						<thead>
							<tr>
								<th>Nom</th>
								<th>Dosage</th>
								<th>Arrêt <small class="hint">(Motif si arrêt)</small></th>
								<th></th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="(addt, index) in caseData.treatments.synthetic_before.additional"
									:key="`addt_synthetic_before_${index}`">
								<td>{{ addt.label }}</td>
								<td>{{ addt.dosage }}</td>
								<td>{{ addt.stop }}</td>
								<td class="text-right">
									<button type="button"
													class="button--danger--outline"
													title="Supprimer"
													@click="removeTreatment('synthetic_before', addt)">
										<IconBin class="icon" />
									</button>
								</td>
							</tr>
						</tbody>
					</table>

					<div class="card rythm-v">

						<!-- Add Treatment -->
						<div class="flex-row flex-gap flex-align-bottom">
							<div class="flex-item--grow">
								<div class="input-group">
									<label for="addt_synthetic_before_label">Nom</label>
									<input type="text"
												 name="addt_synthetic_before_label"
												 id="addt_synthetic_before_label"
												 v-model="additionnalTreatments['synthetic_before'].label" />
								</div>
							</div>

							<div class="flex-item--grow">
								<div class="input-group">
									<label for="addt_synthetic_before_dosage">Dosage</label>
									<input type="text"
												 name="addt_synthetic_before_dosage"
												 id="addt_synthetic_before_dosage"
												 v-model="additionnalTreatments['synthetic_before'].dosage" />
								</div>
							</div>

							<div class="flex-item--grow">
								<radio-group class="radio-group--inline"
														 :radios="stops"
														 label="Arrêt :"
														 name="addt_synthetic_before_stop"
														 id="addt_synthetic_before_stop"
														 v-model="additionnalTreatments['synthetic_before'].stop"
														 required></radio-group>
							</div>

							<div class="flex-item">
								<div class="input-group--controls">
									<button class="button--secondary"
													type="button"
													@click="addTreatment('synthetic_before')"
													:disabled="!additionnalTreatments['synthetic_before'].label || !additionnalTreatments['synthetic_before'].stop">
										<IconPlus class="icon" />
										<span class="caption">Ajouter</span>
									</button>
								</div>
							</div>
						</div>
					</div>

				</section>
			</details>


			<!-- Controls -->
			<div class="flex-row">
				<div class="input-group--controls flex-item--100 flex-row flex-center-h rythm-h flex-gap">
					<router-link :to="`/cases/${caseID}/mici`"
											 class="button--secondary">
						<IconLeft />
						<span class="caption">Précédent</span>
					</router-link>

					<button type="submit"
									form="saveForm"
									class="button--secondary"
									name="saveButton">
						<IconSave class="icon" />
						<span class="caption">Enregistrer</span>
					</button>

					<button form="saveForm"
									type="submit"
									class="button--primary">
						<span class="caption">Continuer</span>
						<IconRight />
					</button>
				</div>
			</div>


		</div>
	</div>
</template>

<script>
import { slugify } from "../libs/helpers";

import radioGroup from "@/components/radioGroup.vue";
import Treatment from "@/components/Treatment.vue";

import IconBin from "@/assets/svg/icons/icon-bin.svg";
import IconPlus from "@/assets/svg/icons/icon-plus.svg";
import IconSave from '../assets/svg/icons/icon-save.svg';
import IconRight from '../assets/svg/icons/icon-right.svg';
import IconLeft from '../assets/svg/icons/icon-left.svg';
import IconInfo from '../assets/svg/icons/info.svg';

export default {
	name: "DashboardCaseTreatmentsBefore",
	props: ["caseID"],
	components: { radioGroup, Treatment, IconBin, IconPlus, IconSave, IconRight, IconLeft, IconInfo },

	data: function () {
		return {
			caseData: {},
			additionnalTreatments: {}, // Additionnal treatments models
			// List of radios stop reasons
			stops: [
				{
					value: "Non",
				},
				{
					value: "Résistance",
				},
				{
					value: "Perte de réponse",
				},
				{
					value: "Intolérance",
				},
			],
		};
	},

	methods: {
		removeTreatment(type, addt) {
			this.$delete(this.caseData.treatments[type].additional, addt.slug);
		},

		addTreatment(type) {
			// Push a new treatment additional treatment
			if (!this.additionnalTreatments[type].label) return;

			// increment unique id
			this.additionnalTreatments[type].count++;
			const counter = this.additionnalTreatments[type].count;
			const additionnalTreatment = { ...this.additionnalTreatments[type] };

			let t = {
				'label': additionnalTreatment.label,
				'stop': additionnalTreatment?.stop ?? "",
				'dosage': additionnalTreatment.dosage,
				'mode': additionnalTreatment?.mode ?? "",
				'slug': slugify(additionnalTreatment.label, "_") + "_" + counter
			};

			// Add additional treatment
			this.$set(this.caseData.treatments[type].additional, t.slug, t);

			// Reset the form
			this.$set(this.additionnalTreatments, type, {
				label: "",
				stop: "",
				dosage: "",
				count: counter
			});
		},

		// Reset value if needle is "falsy"
		// needle: value to check
		// obj: Object to update (Objects are passed by reference in JS)
		// prop: Prop of the object to update (must mutate "obj")
		resetIfEmpty(needle, obj, prop) {
			if (!parseInt(needle)) {
				obj[prop] = "";
			}
		},

		// Reset this.caseData.treatments property if needle is "falsy"
		resetTreatmentIfEmpty(needle, prop) {
			if (!parseInt(needle)) {
				this.caseData.treatments[prop] = "";
			}
		},

		// Save the case
		saveCase(event) {
			const submitter = event?.submitter?.name;
			const validates = this.$refs.saveForm.checkValidity();
			if (!validates) return;

			this.$store
				.dispatch("SAVE_CASE", this.caseData)
				.then((caseData) => {
					this.$toasted.clear();

					let message = !this.caseID ? "Nouveau patient enregistré&nbsp;!" : "Informations du patient enregistrées";
					let toast = this.$toasted.global.appSuccess({
						message: message,
					});

					setTimeout(function () {
						toast.goAway();
					}, 5000);

					// save only
					if (submitter === "saveButton") return;

					this.scrollToTop();
					this.$router.push(`/cases/${this.caseID}/sessions/s1`);
				})
				.catch((error) => {
					console.warn(error);
					return [];
				});
		},

		// Get current case data
		async loadCase() {
			let caseData = await this.$store.state.caseData;
			if (null == caseData) {
				this.$router.push(`/cases`);
				return;
			}

			// Prepare local model for additional treatments
			// NOTE: treatments.concomitant only has "addtional" treatments
			if (caseData?.treatments) {
				Object.entries(caseData.treatments).forEach(([key, value]) => {
					if (value?.additional) {
						// init counters
						const additional_treatment_count = Object.entries(value.additional).length;
						this.$set(this.additionnalTreatments, key, { label: "", stop: "", count: additional_treatment_count });
					}
				})
			}

			// Set caseData
			this.caseData = caseData;
		},
	},

	async mounted() {
		// Load data
		this.loadCase();
	},

}
</script>




<style lang="scss" scoped>
@import "~@/scss/common-views.scss";

.section--additionnal-treatments [class*="flex-item"] {
	padding: 1rem;
}

details> :not(summary) {
	padding: 0 1rem 1rem 1rem;
}

::v-deep .radio-group--inline {
	.label {
		font-weight: 500;
	}
}

</>
