<template>
  <div class="searchbox">
    <label for="search">Recherche</label>
    <input
      type="search"
      id="search"
      name="search"
      v-model="searchTerms"
      @input="updateSearchTerms()"
    />
  </div>
</template>

<script>
export default {
  name: "searchBox",

  data: function() {
    return {
      searchTerms: "",
    };
  },

  methods: {
    updateSearchTerms() {
      // Sync via Store
      this.$store.dispatch("SEARCH", this.searchTerms);
      // Sync via events
      this.$emit("search", { terms: this.searchTerms });
    },
  },
};
</script>
