/*
## Authentification

- La plupart des requêtes à l'API doivent être authentifiées (token dans authorization-header)
- Si un userToken est en localStorage : requête de connexion de l'utilisateur (LOGIN : vérif. token et récupération des infos)
- Sinon : Requête avec login/password (AUTH: vérif. identifiants, retourne un token) pour la connexion de l'utilisateur.

## Notes

	- DISPATCH : actions (invoquées hors ou depuis le store)
	- COMMIT : mutations (uniquement invoquées par le store)
	
	Les mutations mettent à jour l'état de l'application (`state`).

## LocalStorage

	Certaines données sont enregistrées localement : App fait un `subscribe` sur les mutations du Store et lance l'action (`dispatch`) `STORAGE_SYNC`
	Lorsque les mutations observées sont appelées (`commit`) les valeurs du `state` sont enregistrées (userToken, etc.)
	De cette manière le localStorage est uniquement modifié par l'action STORAGE_SYNC, lui même invoqué par des mutations.

	Certaines données du state (ex. utilisateur) sont chargées automatiquement depuis le locatStorage.

*/

import Vue from "vue";

// Store
import Vuex from "vuex";
Vue.use(Vuex);

// Axios
import { HTTP } from "./http-common.js";

// LocalStorage
// This will register the instance `Vue.$localStorage`
import Storage from "vue-web-storage";
Vue.use(Storage, {
  drivers: ["session", "local"], // default 'local'
});

// Helpers
import { get } from "./libs/helpers.js";

// Store
export default new Vuex.Store({
  state: {
    // Authentication
    // userToken: Vue.$localStorage.get("userToken", null),
    userData: Vue.$localStorage.get("userData", null),

    // Heartbeat
    accessTokenExpires: Vue.$localStorage.get("accessTokenExpires", null),

    // JSON schemas
    jsonSchemas: Vue.$localStorage.get("jsonSchemas", null),

    // Case data
    caseData: Vue.$localStorage.get("caseData", null),

    // search box
    searchTerms: "",

    // RWD
    isMobileViewport: null,
  },

  // MUTATIONS
  // L'App root surveille les mutation avec subscribe.
  mutations: {
    // Case data
    JSON_SCHEMAS: (state, data) => {
      state.jsonSchemas = data;
    },

    // Reset case data
    CLOSE_CASE: (state) => {
      state.caseData = null;
    },

    // Case data
    CASE_DATA: (state, caseData) => {
      state.caseData = caseData;
    },

    // Retrieve updated user data
    UPDATE_USER_DATA: (state, userData) => {
      state.userData = userData;
    },

    // Retrieve authentified user data
    USER_LOGIN: (state, userData) => {
      state.userData = userData;
    },

    USER_LOGOUT: (state) => {
      state.userData = null;
      state.accessTokenExpires = null;
      state.caseData = null;
      clearInterval(window.heartbeatClock);
    },

    // Token received
    AUTH_SUCCESS: (state, accessTokenExpires) => {
      console.log("new state accessTokenExpires");
      state.accessTokenExpires = accessTokenExpires;
    },

    // Delete user data (also from storage with STORAGE_SYNC)
    AUTH_LOGOUT: (state) => {
      state.userData = null;
      state.accessTokenExpires = null;
      state.caseData = null;
      clearInterval(window.heartbeatClock);
    },

    // Search
    SEARCH: (state, searchTerms) => {
      state.searchTerms = searchTerms;
    },
  },

  actions: {
    // Search
    SEARCH: ({ commit, dispatch }, searchTerms) => {
      commit("SEARCH", searchTerms);
    },

    // Reset case
    CLOSE_CASE: ({ commit, dispatch }) => {
      commit("CLOSE_CASE");
    },

    // Delete case
    DELETE_CASE: ({ commit, dispatch }, caseID) => {
      return new Promise((resolve, reject) => {
        HTTP.delete(`cases/${caseID}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Save case data
    SAVE_CASE: ({ commit, dispatch }, caseData) => {
      return new Promise((resolve, reject) => {
        let req = null;

        // Insert
        if (!caseData.id) {
          req = HTTP.post("cases", caseData);
        }
        // Update
        else {
          req = HTTP.put(`cases/${caseData.id}`, caseData);
        }

        req
          .then(function(response) {
            const payload = response.data.payload || null;
            commit("CASE_DATA", payload);
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Get referrer data from centerID
    /*
    GET_CENTER_REFERRER: ({ commit, dispatch }, centerID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`centers/${centerID}/referrer`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    */

    // Load JSON Schemas
    LOAD_JSON_SCHEMAS: ({ commit }) => {
      return new Promise((resolve, reject) => {
        HTTP.get("schemas")
          .then(function(response) {
            const payload = response.data || null;
            commit("JSON_SCHEMAS", payload);
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Get a case
    GET_CASE_DATA: ({ commit, dispatch }, caseID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`cases/${caseID}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            commit("CASE_DATA", payload);
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Get all cases
    GET_CASES: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`cases`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Delete center
    DELETE_CENTER: ({ commit, dispatch }, centerID) => {
      return new Promise((resolve, reject) => {
        HTTP.delete(`centers/${centerID}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Centers: insert/update center
    SAVE_CENTER_DATA: ({ commit, dispatch }, center) => {
      return new Promise((resolve, reject) => {
        // Insert
        if (!center.id) {
          var req = HTTP.post(`centers`, center);
        }
        // Update
        else {
          var req = HTTP.put(`centers/${center.id}`, center);
        }

        req
          .then(function(response) {
            let payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Get center data
    GET_CENTER_DATA: ({ commit, dispatch }, centerID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`centers/${centerID}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Get all centers
    GET_CENTERS: ({ commit, dispatch }, user_id) => {
      return new Promise((resolve, reject) => {
        // Filter by user_id?
        let filters = "";
        if (user_id) {
          filters = `?filter=user&id=${user_id}`;
        }

        HTTP.get(`centers${filters}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Get all users
    GET_USERS: ({ commit, dispatch }, role) => {
      return new Promise((resolve, reject) => {
        // Filter by role?
        let filters = "";
        if (role) {
          filters = `?filter=role&role=${role}`;
        }

        HTTP.get(`users${filters}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // // Delete user
    // DELETE_USER: ({ commit, dispatch }) => {
    //   return new Promise((resolve, reject) => {
    //     HTTP.delete(`users/${userID}`)
    //       .then(function(response) {
    //         const payload = response.data.payload || null;
    //         resolve(payload);
    //       })
    //       .catch(function(error) {
    //         reject(error);
    //       });
    //   });
    // },

    // TOTP Pre-Authentification
    AUTH_TOTP_CHECK: ({ commit, dispatch }, loginData) => {
      return new Promise((resolve, reject) => {
        // API call to authenticate user, check if TOTP is enabled and send code via email if needed
        HTTP.post("users/verify", loginData)
          .then(function(response) {
            const payload = response.data ? response.data.payload || null : null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Authentification
    AUTH_REQUEST({ commit, dispatch }, loginData) {
      return new Promise((resolve, reject) => {
        // API call to authenticate user
        HTTP.post("sign-in", loginData)
          .then(function(response) {
            const payload = response.data ? response.data.payload || null : null;

            // Get the accessToken expiration time (for the refresh)
            const accessTokenExpires = payload?.expires || null;

            if (!accessTokenExpires) {
              dispatch("AUTH_LOGOUT");
              reject("accessTokenExpires is null");
            } else {
              commit("AUTH_SUCCESS", accessTokenExpires); // update status (mutation)
              resolve(accessTokenExpires);
            }
          })
          .catch(function(error) {
            dispatch("AUTH_LOGOUT");
            reject(error);
          });
      });
    },

    // Dés-authentification
    AUTH_LOGOUT: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOGOUT");
        resolve();
      });
    },

    // Refresh the accessToken to keep the user session alive.
    REFRESH_ACCESS_TOKEN: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        HTTP.put("refresh")
          .then(function(response) {
            const payload = response.data ? response.data.payload || null : null;

            // The new cookies should be set
            // Get the accessToken expiration time (for the refresh)
            const accessTokenExpires = payload?.expires || null;

            if (!accessTokenExpires) {
              console.error("Unexpected error", "accessTokenExpires is falsy");
              dispatch("AUTH_LOGOUT");
              reject("accessTokenExpires is falsy");
            } else {
              // update state and storage (mutation)
              commit("AUTH_SUCCESS", accessTokenExpires); // update status (mutation)
              resolve(accessTokenExpires);
            }
          })
          .catch(function(err) {
            // login error
            dispatch("AUTH_LOGOUT");
            reject(err);
          });
      });
    },

    // Connexion de l'utilisateur authentifié (le JWT est passé automatiquement dans les en-têtes de chaque requête)
    USER_LOGIN: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        // API call to login user
        HTTP.post("login")
          .then(function(response) {
            const userData = response?.data.payload || null;

            if (userData === null) {
              console.error("Unexpected error", "userData is null");
              dispatch("AUTH_LOGOUT");
              reject("userData is null");
            } else {
              // update status (mutation)
              commit("USER_LOGIN", userData);
              resolve(response);
            }
          })
          .catch(function(err) {
            // login error
            dispatch("AUTH_LOGOUT");
            reject(err);
          });
      });
    },

    // Déconnexion et désauthentification de l'utilisateur
    USER_LOGOUT: ({ commit, dispatch, state }) => {
      return new Promise((resolve, reject) => {
        // Unvalidate tokens
        HTTP.post("logout")
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });

        // Update State and Storage
        commit("USER_LOGOUT");
        resolve();
      });
    },

    VERIFY_CAPTCHA: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("/captcha", payload)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    RESET_TOTP_CODE: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.put("users/totp", payload)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    CHECK_TOTP_CODE: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("users/totp", payload)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Get TOTP QRCODE
    GET_TOTP_QRCODE: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("users/qrcode/totp", payload)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Send TOTP code by email
    SEND_TOTP_CODE: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("users/totp/email", payload)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Reset password
    RESET_PASSWORD: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.put("reset-password", payload)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Request a password reset (send a password reset token by email)
    REQUEST_PASSWORD_RESET: ({ commit, dispatch }, email) => {
      return new Promise((resolve, reject) => {
        HTTP.post("request-password-reset", { email: email })
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Requestion new validation email
    NEW_VALIDATION_EMAIL: ({ commit, dispatch, state }, email) => {
      return new Promise((resolve, reject) => {
        HTTP.post("users/validate", { email: email })
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Check email validation token
    VERIFY_EMAIL: ({ commit, dispatch, state }, { userID, token }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`users/${userID}/verify-email`, { token: token })
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Request email update
    REQUEST_EMAIL_UPDATE: ({ commit, dispatch }, { payload, userID }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`users/${userID}/request-email-update`, payload)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Update email
    UPDATE_EMAIL: ({ commit, dispatch }, { userID, token }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`users/${userID}/update-email`, { token: token })
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Update password
    UPDATE_PASSWORD: ({ commit, dispatch }, { payload, userID }) => {
      return new Promise((resolve, reject) => {
        HTTP.put(`users/${userID}/update-password`, payload)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Profile: update user
    UPDATE_USER_DATA: ({ commit, dispatch, state }, { userID, payload }) => {
      return new Promise((resolve, reject) => {
        HTTP.put(`users/${userID}`, payload)
          .then(function(response) {
            let userData = response.data.payload || null;
            if (userData) {
              // commit("UPDATE_USER_DATA", userData);
              resolve(userData);
            }
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Registration: create user
    INSERT_USER_DATA: ({ commit, dispatch, state }, { userID, formData }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`users/${userID}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(function(response) {
            let userData = response.data.payload || null;
            // User profile created -> login user
            commit("USER_LOGIN", userData);
            resolve(response);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Delete user
    DELETE_USER: ({ commit, dispatch }, userID) => {
      return new Promise((resolve, reject) => {
        HTTP.delete(`users/${userID}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Registration: create user
    CREATE_USER: ({ commit, dispatch, state }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("users", payload)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Profile: get user data
    GET_USER_DATA: ({ commit, dispatch }, userID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`users/${userID}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // localStorage Sync
    // L'App root subscribe aux mutations du Store pour mettre en cache les données de certaines mutations.
    STORAGE_SYNC: ({ commit, state }, mutation) => {
      return new Promise((resolve, reject) => {
        // console.log("STORAGE_SYNC", mutation, state);

        // JSON schemas
        if (mutation.type === "JSON_SCHEMAS") {
          Vue.$localStorage.set("jsonSchemas", state.jsonSchemas);
          resolve(mutation);
        }

        // Effacer données du patient
        if (mutation.type === "CLOSE_CASE") {
          Vue.$localStorage.remove("caseData");
          resolve(mutation);
        }

        // Données du patient
        if (mutation.type === "CASE_DATA") {
          Vue.$localStorage.set("caseData", state.caseData);
          resolve(mutation);
        }

        // Authentification réussie
        if (mutation.type === "AUTH_SUCCESS") {
          console.log("new accessTokenExpires");
          Vue.$localStorage.set("accessTokenExpires", state.accessTokenExpires);
          resolve(mutation);
        }

        // Logout / Erreur d'authentification ou Logout on supprime l'utilisateur
        if (mutation.type === "AUTH_LOGOUT") {
          Vue.$localStorage.clear(true);
          resolve(mutation);
        }

        // Connexion de l'utilisateur : on enregistre ses données
        if (mutation.type === "USER_LOGIN" || mutation.type === "UPDATE_USER_DATA") {
          Vue.$localStorage.set("userData", state.userData);
          resolve(mutation);
        }

        // Erreur de login ou Logout : on supprime les données de l'utilisateur
        if (mutation.type === "USER_LOGOUT") {
          Vue.$localStorage.clear(true);
          resolve(mutation);
        }

        // Si erreur : reject (mutation);
      });
    },
  },
  modules: {},
});
