<template>
	<div class="stripped-children">

		<div class="flex-row"
				 v-for="(field, index) in bioData.list"
				 :key="`bio_data_${index}`">

			<div class="flex-item--grow">
				<radio-toggle class="radio-group--inline required"
											:id="`bio_data_${field.slug}_toggle`"
											:label="`${field.label} <small>(${field.unit})</small> réalisé`"
											v-bind="$attrs"
											v-model="field.selected"
											required
											@input="resetConditionalProps(field, ['value'])"></radio-toggle>
			</div>

			<div class="flex-item"
					 v-if="parseInt(field.selected)">
				<div class="input-group--inline required">

					<label :for="field.slug"
								 v-html="`${field.label} <small>(${field.unit})</small>`"></label>
					<input :id="`bio_data_${field.slug}`"
								 :name="field.slug"
								 type="number"
								 :step="field.step"
								 v-model="field.value"
								 v-bind="$attrs"
								 required
								 @input="emitScore" />
				</div>
			</div>

		</div>


	</div>
</template>


<script>
import radioToggle from "@/components/radioToggle.vue";

export default {
	name: 'BiologicalData',
	inheritAttrs: false,
	props: ['value'],
	components: { radioToggle },

	data() {
		return {
			// local model
			bioData: {}
		}
	},

	computed: {},

	methods: {

		resetConditionalProps(field, props = []) {

			if (field && !parseInt(field?.selected)) {

				if (props?.length) {
					props.forEach(f => {
						console.log("field?", f);
						field[f] = '';
					});
				}

				if (field?.subfields?.length) {
					field.subfields.forEach(s => s.value = '');
				}

			}

			this.emitScore();
		},

		emitScore() {
			this.$emit('input', this.bioData);
		}
	},

	watch: {
		// Init data values from component props ("values")
		value: {
			immediate: true, deep: true,
			handler: function (data) {
				this.bioData = { ...data };
			}
		},
	}
}

</script>