import Vue from "vue";
import VueRouter from "vue-router";
import { NProgress } from "./nprogress.js";

// import Home from "./views/Home.vue";
const Home = () => import(/* webpackChunkName: "Home" */ "./views/Home.vue");
const Legal = () => import(/* webpackChunkName: "Legal" */ "./views/Legal.vue");

const Dashboard = () => import(/* webpackChunkName: "Dashboard" */ "./views/Dashboard.vue");
const DashboardListCases = () => import(/* webpackChunkName: "DashboardListCases" */ "./views/DashboardListCases.vue");
const DashboardCasePatient = () => import(/* webpackChunkName: "DashboardCasePatient" */ "./views/DashboardCasePatient.vue");
const DashboardCaseRIC = () => import(/* webpackChunkName: "DashboardCaseRIC" */ "./views/DashboardCaseRIC.vue");
const DashboardCaseTreatmentsBefore = () => import(/* webpackChunkName: "DashboardCaseTreatmentsBefore" */ "./views/DashboardCaseTreatmentsBefore.vue");
const DashboardCaseSessions = () => import(/* webpackChunkName: "DashboardCaseSessions" */ "./views/DashboardCaseSessions.vue");
const DashboardCaseEnd = () => import(/* webpackChunkName: "DashboardCaseEnd" */ "./views/DashboardCaseEnd.vue");
const DashboardAdverseEvents = () => import(/* webpackChunkName: "DashboardAdverseEvents" */ "./views/DashboardAdverseEvents.vue");

const UserProfile = () => import(/* webpackChunkName: "UserProfile" */ "./views/UserProfile.vue");
const userEmailUpdate = () => import(/* webpackChunkName: "userEmailUpdate" */ "./views/userEmailUpdate.vue");
const Users = () => import(/* webpackChunkName: "Users" */ "./views/Users.vue");
const UsersList = () => import(/* webpackChunkName: "UsersList" */ "./views/UsersList.vue");
const UsersNew = () => import(/* webpackChunkName: "UsersNew" */ "./views/UsersNew.vue");
const Centers = () => import(/* webpackChunkName: "Centers" */ "./views/Centers.vue");
const PasswordReset = () => import(/* webpackChunkName: "PasswordReset" */ "./views/PasswordReset.vue");

Vue.use(VueRouter);

const routes = [
  // Home
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "Accueil",
    },
  },
  // Legal
  {
    path: "/mentions-legales",
    name: "legal",
    component: Legal,
    meta: {
      title: "Mentions légales",
    },
  },

  // List cases
  {
    path: "/cases",
    name: "dashboard",
    component: Dashboard,

    children: [
      // default: list cases
      {
        path: "",
        name: "dashboard-list-cases",
        component: DashboardListCases,
        meta: {
          title: "Liste des patients",
          authenticated: true,
        },
      },

      // new case
      {
        path: "new",
        name: "dashboard-new-case",
        component: DashboardCasePatient,
        meta: {
          title: "Nouveau patient",
          authenticated: true,
        },
      },

      // new/edit case patient
      {
        path: ":caseID/patient",
        name: "dashboard-edit-patient",
        component: DashboardCasePatient,
        props: true,
        meta: {
          title: "Données du patient",
          authenticated: true,
        },
      },

      // case mici
      {
        path: ":caseID/mici",
        name: "dashboard-edit-mici",
        component: DashboardCaseRIC,
        props: true,
        meta: {
          title: "Données concernant la MICI",
          authenticated: true,
        },
      },

      // case treatments
      {
        path: ":caseID/treatments",
        name: "dashboard-edit-treatments-before",
        component: DashboardCaseTreatmentsBefore,
        props: true,
        meta: {
          title: "Traitements avant l'introduction d'ADACOLUMN®",
          authenticated: true,
        },
      },

      // case week data
      {
        path: ":caseID/sessions/:sid?",
        name: "dashboard-edit-sessions",
        component: DashboardCaseSessions,
        props: true,
        meta: {
          title: "Données des traitements d’aphérèse par ADACOLUMN®",
          authenticated: true,
        },
      },

      // case study end
      {
        path: ":caseID/study-end",
        name: "dashboard-case-end",
        component: DashboardCaseEnd,
        props: true,
        meta: {
          title: "Fin de l’étude",
          authenticated: true,
        },
      },

      // adverse events
      {
        path: ":caseID/adverse-events",
        name: "dashboard-adverse-events",
        component: DashboardAdverseEvents,
        props: true,
        meta: {
          title: "Évènements indésirables",
          authenticated: true,
        },
      },
    ],
  },

  // Password Reset, 2FA setup and User Email Validation (new user)
  {
    path: "/password-reset/:passwordResetToken?/:userProfileID?",
    name: "passwordReset",
    component: PasswordReset,
    props: true,
    meta: {
      title: "Changement de mot de passe",
      authenticated: false,
    },
  },

  // User Email Update
  {
    path: "/user/:userProfileID/email-update/:validationToken",
    name: "userEmailUpdate",
    component: userEmailUpdate,
    props: true,
    meta: {
      title: "Modification de l’adresse e-mail",
      authenticated: false,
    },
  },

  // User Profile (optional email update confirmation)
  {
    path: "/user/:userProfileID/profile*",
    name: "userProfile",
    component: UserProfile,
    props: true,
    meta: {
      title: "Profil utilisateur",
      authenticated: true,
    },
  },

  // Users
  {
    path: "/users",
    name: "users",
    component: Users,

    children: [
      // default: list users
      {
        path: "",
        component: UsersList,
        name: "UsersList",
        meta: {
          title: "Liste des utilisateurs",
          authenticated: true,
        },
      },

      // Create user
      {
        path: "new",
        component: UsersNew,
        name: "UsersNew",
        meta: {
          title: "Ajouter un utilisateurs",
          authenticated: true,
        },
      },
    ],
  },

  // Centers
  {
    path: "/centers",
    name: "centers",
    component: Centers,
    meta: {
      title: "Gestion des centres",
      authenticated: true,
    },
  },
];

// Router config
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Route gards: loader
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
