<template>
	<section class="rythm-v section--score">

		<hgroup>
			<h4>Score ASDAS</h4>
			<p class="small">Ankylosing Spondylitis Disease Activity Score</p>
		</hgroup>

		<div class="flex-row flex-gap-large">
			<div class="alert-success w-100 flex-item--50 flex-col flex-align-left">
				<p>Données :</p>
				<ul style="column-count: 1;column-gap: 2rem;">
					<li>
						<span class="label">Douleur (<a href="#basdai-index">BASDAI</a> Question 2) :</span> <strong v-if="localValues.pain !== ''">{{ localValues.pain }}</strong> <span v-else>DM</span>
					</li>

					<li>
						<span class="label">Raideur (<a href="#basdai-index">BASDAI</a> Question 6) :</span> <strong v-if="localValues.stiffness_duration !== ''">{{ localValues.stiffness_duration }}</strong> <span v-else>DM</span>
					</li>

					<li>
						<span class="label">Gonflement (<a href="#basdai-index">BASDAI</a> Question 3) :</span> <strong v-if="localValues.swelling !== ''">{{ localValues.swelling }}</strong> <span v-else>DM</span>
					</li>

					<li>
						<span class="label"><a href="#eva-index">EVA activité patient</a> :</span> <strong v-if="localValues.activity_patient !== ''">{{ localValues.activity_patient }}</strong> <span v-else>DM</span>
					</li>

					<li :class="localValues.crp === '' ? 'text-danger' : ''">
						<span class="label"><a href="#biological-data">CRP</a> :</span> <strong v-if="localValues.crp !== ''">{{ localValues.crp }} mg/L</strong> <span v-else>DM</span>
					</li>

					<li :class="localValues.vs === '' ? 'text-danger' : ''">
						<span class="label"><a href="#biological-data">VS</a> :</span> <strong v-if="localValues.vs !== ''">{{ localValues.vs }} mm/hr</strong> <span v-else>DM</span>
					</li>
				</ul>

				<p>Résultats :</p>
				<ul>
					<li>
						<span class="label">ASDAS-CRP :</span> {{ score_crp }} <span>({{ scoreInterpretation.crp }})</span>
					</li>
					<li>
						<span class="label">ASDAS-VS :</span> {{ score_vs }} <span>({{ scoreInterpretation.vs }})</span>
					</li>
				</ul>
			</div>

			<div class="alert-info flex-item--50">
				<div>
					<p class="label">Interprétation du score ASDAS :</p>
					<ul>
						<li>Score &lt; 1,3 : Inactif</li>
						<li>Score &ge; 1,3 et &lt; 2,1 : Modéré</li>
						<li>Score &ge; 2,1 et &lt; 3,5 : Actif</li>
						<li>Score &ge; 3,5 : Très actif</li>
					</ul>
				</div>
			</div>
		</div>

	</section>

</template>


<script>
export default {
	name: 'ScoreAsdas',
	inheritAttrs: false,
	// value: score values from database, others: data from other scores
	props: ['value', 'activity_patient', 'pain', 'stiffness_duration', 'swelling', 'crp', 'vs'],

	components: {},

	data() {
		return {
			localValues: {
				// from props
				activity_patient: '',
				pain: '',
				stiffness_duration: '',
				swelling: '',
				crp: '',
				vs: '',
			}
		}
	},

	computed: {

		// hasAllValues() {
		// 	return (this.localValues.activity_patient !== '' &&
		// 		this.localValues.pain !== '' &&
		// 		this.localValues.stiffness_duration !== '' &&
		// 		this.localValues.swelling !== '' &&
		// 		this.localValues.crp !== '' &&
		// 		this.localValues.vs !== '');
		// },

		/**
		 * Returns an object with the interpretation of the ASDAS (Ankylosing Spondylitis Disease Activity Score)
		 * based on the given score values. The interpretation is based on the following thresholds:
		 * - Score < 1.3: Inactive.
		 * - Score ≥ 1.3 and < 2.1: Moderate activity.
		 * - Score ≥ 2.1 and < 3.5: Active.
		 * - Score ≥ 3.5: Very active.
		 *
		 * @return {Object} An object with the interpretations of the ASDAS scores for 'crp' and 'vs'.
		 */
		scoreInterpretation() {
			return {
				crp: this.score_crp !== '' && this.score_crp !== 'DM' ? (this.score_crp < 1.3 ? "inactive" : this.score_crp < 2.1 ? "activité modérée" : this.score_crp < 3.5 ? "active" : "très active") : "données manquantes",
				vs: this.score_vs !== '' && this.score_vs !== 'DM' ? (this.score_vs < 1.3 ? "inactive" : this.score_vs < 2.1 ? "activité modérée" : this.score_vs < 3.5 ? "active" : "très active") : "données manquantes",
			}
		},

		score_crp() {
			if (!this.localValues?.crp || this.localValues.crp === "") return "DM";

			// NOTE: EVA = value.eva.activity_doctor (pas d'EVA globale)
			// NOTE: *Pour le calcul du score ASDAS je vous confirme que c'est l'EVA activité patient qui est utilisé non pas celle du médecin.*
			// NOTE: √(ESR) = √(vs)
			const pain = parseInt(this.localValues?.pain || 0);
			const swelling = parseInt(this.localValues?.swelling || 0);
			const stiffness_duration = parseInt(this.localValues?.stiffness_duration || 0);
			const eva = parseInt(this.localValues.activity_patient) || 0; // parseInt(this.localValues.activity_doctor || 0);
			const crp = parseFloat(this.localValues?.crp || 0);

			// 0.12 x question 2 du BASDAI + 0.06 x question 6 du BASDAI + 0.11 x EVA globale + 0.07 x question 3 du BASDAI + 0.58 x Ln(CRP+1)
			const score_crp = (0.12 * pain + 0.06 * stiffness_duration + 0.11 * eva + 0.07 * swelling + 0.58 * Math.log(crp + 1)).toFixed(2);

			return score_crp;
		},

		score_vs() {
			if (!this.localValues?.vs || this.localValues.vs === "") return "DM";

			// NOTE: EVA = value.eva.activity_doctor (pas d'EVA globale)
			// NOTE: *Pour le calcul du score ASDAS je vous confirme que c'est l'EVA activité patient qui est utilisé non pas celle du médecin.*
			// NOTE: √(ESR) = √(vs)
			const pain = parseInt(this.localValues?.pain || 0);
			const swelling = parseInt(this.localValues?.swelling || 0);
			const stiffness_duration = parseInt(this.localValues?.stiffness_duration || 0);
			const eva = parseInt(this.localValues.activity_patient) || 0; // parseInt(this.localValues.activity_doctor || 0);
			const vs = parseFloat(this.localValues?.vs || 0);

			// 0.08 x question 2 du BASDAI + 0.07 x question 6 du BASDAI + 0.11 x EVA globale + 0.09 x question 3 du BASDAI + 0.29 x √(ESR)
			// NOTE: formule utilisée avec ESR = VS + 1 : 0.08 * pain + 0.07 * stiffness_duration + 0.11 * eva + 0.09 * swelling + 0.29 * √(vs + 1)
			const score_vs = (0.08 * pain + 0.07 * stiffness_duration + 0.11 * eva + 0.09 * swelling + 0.29 * Math.sqrt(vs + 1)).toFixed(2);

			return score_vs;
		},


		// score() {
		// 	// NOTE: EVA = value.eva.activity_doctor (pas d'EVA globale)
		// 	// NOTE: *Pour le calcul du score ASDAS je vous confirme que c'est l'EVA activité patient qui est utilisé non pas celle du médecin.*
		// 	// NOTE: √(ESR) = √(vs)
		// 	const pain = parseInt(this.localValues?.pain || 0);
		// 	const swelling = parseInt(this.localValues?.swelling || 0);
		// 	const stiffness_duration = parseInt(this.localValues?.stiffness_duration || 0);

		// 	const eva = parseInt(this.localValues.activity_patient) || 0; // parseInt(this.localValues.activity_doctor || 0);
		// 	const crp = parseFloat(this.localValues?.crp || 0);
		// 	const vs = parseFloat(this.localValues?.vs || 0);

		// 	// 0.12 x question 2 du BASDAI + 0.06 x question 6 du BASDAI + 0.11 x EVA globale + 0.07 x question 3 du BASDAI + 0.58 x Ln(CRP+1)
		// 	this.localValues.score_crp = (0.12 * pain + 0.06 * stiffness_duration + 0.11 * eva + 0.07 * swelling + 0.58 * Math.log(crp + 1)).toFixed(2);

		// 	// 0.08 x question 2 du BASDAI + 0.07 x question 6 du BASDAI + 0.11 x EVA globale + 0.09 x question 3 du BASDAI + 0.29 x √(ESR)
		// 	// NOTE: formule utilisée avec ESR = VS + 1 : 0.08 * pain + 0.07 * stiffness_duration + 0.11 * eva + 0.09 * swelling + 0.29 * √(vs + 1)
		// 	this.localValues.score_vs = (0.08 * pain + 0.07 * stiffness_duration + 0.11 * eva + 0.09 * swelling + 0.29 * Math.sqrt(vs + 1)).toFixed(2);

		// 	return `${this.localValues.score_crp},${this.localValues.score_vs}`; // raw score always as string
		// },


		results() {
			return {
				score: `${this.score_crp},${this.score_vs}`, // raw score always as string
				scoreRendered: `ASDAS-CRP : ${this.score_crp}, ASDAS-VS : ${this.score_vs}`, // formatted score, ex. with unit 24%, 12mm, …
				values: { ...this.localValues }, // local values "as is" (do not cast, etc.)
				details: null,
				detailsRendered: `Interprétation ASDAS CRP : ${this.scoreInterpretation?.crp}, Interprétation ASDAS VS : ${this.scoreInterpretation?.vs}`,
			};
		}
	},

	methods: {
		emitScore() {
			this.$emit('input', this.results);
		}
	},


	watch: {

		// Watch results change and emit to parent
		results: {
			deep: true,
			handler: function (oldValue, newValue) {
				if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
					this.emitScore();
				}
			},
		},

		// Init local model from `value` prop
		value: {
			immediate: true,
			handler: function (data) {
				if (data?.values) {
					this.localValues = { ...this.localValues, ...data.values }
				}
			},
		},

		// Init local model from `activity_patient` prop
		activity_patient: {
			immediate: true,
			handler: function (activity_patient) {
				this.localValues.activity_patient = activity_patient || "";
			},
		},

		pain: {
			immediate: true,
			handler: function (pain) {
				this.localValues.pain = pain || "";
			},
		},

		stiffness_duration: {
			immediate: true,
			handler: function (stiffness_duration) {
				this.localValues.stiffness_duration = stiffness_duration || "";
			},
		},

		swelling: {
			immediate: true,
			handler: function (swelling) {
				this.localValues.swelling = swelling || "";
			},
		},

		crp: {
			immediate: true,
			handler: function (crp) {
				this.localValues.crp = crp || "";
			},
		},

		vs: {
			immediate: true,
			handler: function (vs) {
				this.localValues.vs = vs || "";
			},
		},

	},

}

</script>

<style lang="scss" scoped>
::v-deep {
	.input-slider {

		.input-slider-label,
		.range-slider {
			flex-basis: 100% !important;
		}

		.input-slider-label {
			gap: 1rem;
		}

		.label--before,
		.label--after {
			flex-basis: 15%;
		}
	}
}
</style>