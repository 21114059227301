<template>
	<section class="card shadow rythm-v-large"
					 v-if="caseDataNotEmpty">

		<div class="alert-info w-100">
			<div>
				<p>Les données manquantes ou indisponibles seront notées de la manière suivante :</p>
				<ul class="rythm-v-small">
					<li><code class="tag--outline tag--status-standby">DM</code> pour une donnée non disponible</li>
					<li><code class="tag--outline tag--status-standby">NF</code> pour un examen / bilan non fait</li>
					<li><code class="tag--outline tag--status-standby">NA</code> quand ce n’est pas applicable</li>
				</ul>
			</div>
		</div>


		<h2><strong>Inclusion</strong> – Données concernant le RIC</h2>

		<div class="form-wrapper rythm-v-large">
			<form ref="saveForm"
						id="saveForm"
						method="post"
						@submit.prevent="saveCase"></form>

			<section class="rythm-v-large"
							 v-if="caseData.desease_data">
				<h3>Type de maladie et antécédents</h3>

				<div class="rythm-v">
					<div class="flex-row flex-gap">

						<!-- disease_type -->
						<div class="flex-item flex-grow">

							<div class="input-group required">
								<label for="disease_type">Type de RIC</label>
								<select v-model="caseData.desease_data.disease_type"
												name="disease_type"
												id="disease_type"
												form="saveForm"
												required>
									<option value="">Choisir une option</option>
									<option v-for="(disease_type, index) in localModel.disease_type"
													:value="disease_type"
													:key="`disease_type_${index}`">
										{{ disease_type }}
									</option>
								</select>
							</div>
						</div>

						<div class="flex-item flex-grow"
								 v-if="caseData.desease_data.disease_type === 'Autre'">
							<div class="input-group required">
								<label for="disease_type_other">Précisez :</label>
								<input type="text"
											 name="disease_type_other"
											 id="disease_type_other"
											 form="saveForm"
											 v-model="caseData.desease_data.disease_type_other"
											 required />
							</div>
						</div>

						<div class="flex-item flex-grow">
							<div class="input-group required">
								<label for="date_diagnostic">Date du diagnostic</label>
								<input type="date"
											 name="date_diagnostic"
											 id="date_diagnostic"
											 form="saveForm"
											 v-model="caseData.desease_data.date_diagnostic"
											 required />
							</div>
						</div>
					</div>

					<!-- Tabagisme -->
					<div>
						<radio-group class="radio-group--inline"
												 :radios="localModel.tabagism"
												 label="Tabagisme :"
												 id="atcd_smoking"
												 form="saveForm"
												 required
												 v-model="caseData.desease_data.atcd_smoking"></radio-group>
					</div>
				</div>

				<div>
					<!-- :values feed the component with the saved or default values -->
					<!-- then the component returns { values, scoreInt, scoreString and values } -->
					<index-charlson :values="caseData.desease_data.index_charlson.values"
													:patientAge="patientAgeAtDiagnosis"
													v-model="caseData.desease_data.index_charlson" />
				</div>


				<div class="flex-item flex-grow">
					<div class="input-group">
						<label for="observations">Observations <small>(si d’autres antécédents non listés ci-haut)</small></label>
						<textarea name="observations"
											id="observations"
											form="saveForm"
											v-model="caseData.desease_data.observations"
											rows="6"></textarea>
					</div>
				</div>

			</section>

			<!-- Controls -->
			<div class="flex-row">
				<div class="input-group--controls flex-item--100 flex-row flex-center-h rythm-h flex-gap">
					<router-link :to="`/cases/${caseID}/patient`"
											 class="button--secondary">
						<IconLeft />
						<span class="caption">Précédent</span>
					</router-link>

					<button type="submit"
									form="saveForm"
									class="button--secondary"
									name="saveButton">
						<IconSave class="icon" />
						<span class="caption">Enregistrer</span>
					</button>

					<button type="submit"
									form="saveForm"
									class="button--primary">
						<span class="caption">Continuer</span>
						<IconRight />
					</button>
				</div>
			</div>

		</div>
	</section>
</template>

<style lang="scss" scoped>
@import "~@/scss/common-views.scss";
</style>

<script>
import { calculateAge, slugify, isObjectEmpty } from '../libs/helpers.js';

import IndexCharlson from "@/components/IndexCharlson.vue";
import radioGroup from "@/components/radioGroup.vue";
import IconSave from "@/assets/svg/icons/icon-save.svg";
import IconRight from "@/assets/svg/icons/icon-right.svg";
import IconLeft from "@/assets/svg/icons/icon-left.svg";

export default {
	name: "DashboardCaseRIC",
	props: ["caseID"],
	components: { IndexCharlson, radioGroup, IconSave, IconRight, IconLeft },

	data: function () {
		return {
			// Local model
			localModel: {
				disease_type: ["Polyarthrite Rhumatoïde", "Spondylarthrite", "Rhumatisme Psoriasique", "Autre"],
				tabagism: [
					{
						label: "Fumeur",
						value: "Fumeur",
					},
					{
						label: "Non-fumeur",
						value: "Non-fumeur",
					},
					{
						label: "Ex-fumeur",
						value: "Ex-fumeur",
					},
				],
			},

			// Final data
			caseData: null
		};
	},

	computed: {

		caseDataNotEmpty() {
			return !isObjectEmpty(this.caseData);
		},

		patientAge() {
			const birthdate = this.caseData?.birthdate;
			if (!birthdate) return '';
			const birthDateObject = new Date(`01/${this.caseData.birthdate}`);
			const now = new Date();
			return calculateAge(birthDateObject, now);
		},

		patientAgeAtDiagnosis() {
			const birthdate = this.caseData?.birthdate;
			const date_diagnostic = this.caseData?.desease_data?.date_diagnostic;
			if (!birthdate || !date_diagnostic) return 0;
			const birthDateObject = new Date(`01/${birthdate}`);
			const diagnosisDateObject = new Date(date_diagnostic);
			return calculateAge(birthDateObject, diagnosisDateObject);
		}
	},

	mounted() {
		this.loadCase();
	},

	methods: {

		// Save the case
		saveCase(event) {
			const submitter = event?.submitter?.name;
			const validates = this.$refs.saveForm.checkValidity();
			if (!validates) return;

			this.$store
				.dispatch("SAVE_CASE", this.caseData)
				.then((caseData) => {
					this.$toasted.clear();

					let toast = this.$toasted.global.appSuccess({
						message: "Données enregistrées",
					});

					// save only
					if (submitter === "saveButton") return;

					setTimeout(function () {
						toast.goAway();
					}, 5000);

					// save and continue
					this.scrollToTop();
					this.$router.push(`/cases/${this.caseID}/treatments`);
				})
				.catch((error) => {
					console.warn(error);
					return [];
				});
		},

		// Get current case data
		async loadCase() {

			// NOTE: important, init case data with JSON schemas
			const jsonSchemas = await this.$store.state.jsonSchemas;
			const caseData = await this.$store.state.caseData;

			this.caseData = { ...caseData };
			this.caseData.desease_data = { ...jsonSchemas.desease_data, ...caseData?.desease_data };
			this.caseData.treatments = { ...jsonSchemas.treatments, ...caseData?.treatments };
			this.caseData.sessions = { ...jsonSchemas.sessions, ...caseData?.sessions };

			const appendMissingKeys = (obj1, obj2) => {
				for (const key in obj2) {
					if (!obj1.hasOwnProperty(key)) {
						obj1[key] = obj2[key];
					} else if (typeof obj2[key] === "object" && obj2[key] !== null) {
						appendMissingKeys(obj1[key], obj2[key]);
					}
				}
			};

			appendMissingKeys(this.caseData.desease_data, jsonSchemas.desease_data);
			appendMissingKeys(this.caseData.treatments, jsonSchemas.treatments);
			appendMissingKeys(this.caseData.sessions, jsonSchemas.sessions);

		},
	},

	watch: {
		// reset disease_type_other when disease_type is not "Autre"
		"caseData.desease_data.disease_type": function () {
			if (this.caseData.desease_data.disease_type !== "Autre") {
				this.caseData.desease_data.disease_type_other = "";
			}
		},
	},

};
</script>
