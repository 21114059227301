// Options par défault
export const toastedDefaults = {
  containerClass: "toasted",
  theme: "primary",
  // position: "top-right",
  position: "top-right",
  // icon: "info",
  duration: 20000,
  action: {
    text: "Fermer",
    onClick: (e, toastObject) => toastObject.goAway(0),
  },
};

// Toast global pour les erreurs
export const toastedError = {
  ...toastedDefaults,
  type: "error",
  // icon: "error_outline",
  duration: null,
};

// Toast global pour les succès
export const toastedSuccess = {
  ...toastedDefaults,
  type: "success",
  duration: null,
};

// Toast global pour l'aide
export const toastedHelp = {
  ...toastedDefaults,
  type: "help",
  duration: null,
};

export default { toastedDefaults, toastedSuccess, toastedError, toastedHelp };
