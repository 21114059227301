<template>
	<div>
		<section class="rythm-v-large"
						 v-if="echoData?.list?.synovites">

			<h4>Synovites</h4>


			<details class="rythm-v"
							 v-for="(localization, index) in echoData.list.synovites"
							 :key="`echo_data_synovites_localization_${index}`"
							 style="margin-bottom: 2rem;">

				<summary>
					<h5><strong>{{ localization.label }}</strong></h5>
				</summary>

				<div class="stripped-children"
						 v-if="localization">

					<div class="flex-row flex-center-v flex-gap-large"
							 v-for="field in echoData?.list?.synovites[localization?.slug]?.data"
							 :key="`${localization.slug}_${field.slug}`">

						<div class="flex-item flex-item--grow">
							<p class="label"><strong>{{ field.label }} :</strong></p>
						</div>

						<div class="flex-item">
							<radio-group class="radio-group--inline strong-label"
													 :radios="mode_b_doppler_options"
													 label="Mode B :"
													 :id="`synovites_${localization.slug}_${field.slug}_mode_b`"
													 :name="`synovites_${localization.slug}_${field.slug}_mode_b`"
													 v-model="field.mode_b"
													 v-bind="$attrs"
													 @input="emitScore"></radio-group>
						</div>

						<div class="flex-item">
							<radio-group class="radio-group--inline strong-label"
													 :radios="mode_b_doppler_options"
													 label="Doppler :"
													 :id="`synovites_${localization.slug}_${field.slug}_doppler`"
													 :name="`synovites_${localization.slug}_${field.slug}_doppler`"
													 v-model="field.doppler"
													 v-bind="$attrs"
													 @input="emitScore"></radio-group>
						</div>

					</div>

				</div>
			</details>


			<div class="block-center-h"
					 style="height:1rem; margin: 2rem 0;"></div>

			<h4>Tenosynovites</h4>

			<details class="rythm-v"
							 v-for="(localization, index) in echoData.list.tenosynovites"
							 :key="`echo_data_tenosynovites_localization_${index}`"
							 style="margin-bottom: 2rem;">

				<summary>
					<h5><strong>{{ localization.label }}</strong></h5>
				</summary>

				<div class="stripped-children"
						 v-if="localization">

					<div class="flex-row flex-center-v flex-gap-large"
							 v-for="field in echoData?.list?.tenosynovites[localization?.slug]?.data"
							 :key="`${localization.slug}_${field.slug}`">

						<div class="flex-item flex-item--grow">
							<p class="label"><strong>{{ field.label }} :</strong></p>
						</div>

						<div class="flex-item">
							<radio-group class="radio-group--inline strong-label"
													 :radios="mode_b_doppler_options"
													 label="Mode B :"
													 :id="`tenosynovites_${localization.slug}_${field.slug}_mode_b`"
													 :name="`tenosynovites_${localization.slug}_${field.slug}_mode_b`"
													 v-model="field.mode_b"
													 v-bind="$attrs"
													 @input="emitScore"></radio-group>
						</div>

						<div class="flex-item">
							<radio-group class="radio-group--inline strong-label"
													 :radios="mode_b_doppler_options"
													 label="Doppler :"
													 :id="`tenosynovites_${localization.slug}_${field.slug}_doppler`"
													 :name="`tenosynovites_${localization.slug}_${field.slug}_doppler`"
													 v-model="field.doppler"
													 v-bind="$attrs"
													 @input="emitScore"></radio-group>
						</div>

					</div>

				</div>
			</details>
		</section>

	</div>
</template>


<script>
import radioGroup from "@/components/radioGroup.vue";

export default {
	name: 'EchoData',
	inheritAttrs: false,
	props: ['value'],
	components: { radioGroup },

	data() {
		return {
			// local model
			echoData: {},
			mode_b_doppler_options: [
				{ value: "DM", title: "Donnée manquante / indisponible" },
				{ value: "0", class: "label-padding" },
				{ value: "1", class: "label-padding" },
				{ value: "2", class: "label-padding" },
				{ value: "3", class: "label-padding" },
			]
		}
	},

	computed: {},

	methods: {

		// resetConditionalProps(field, props = []) {
		// 	if (!parseInt(field?.selected)) {

		// 		if (props?.length) {
		// 			props.forEach(f => {
		// 				field[f] = '';
		// 			});
		// 		}

		// 		if (field?.subfields?.length) {
		// 			field.subfields.forEach(s => s.value = '');
		// 		}

		// 	}

		// 	this.emitScore();
		// },

		emitScore() {
			this.$emit('input', this.echoData);
		}
	},

	watch: {
		// Init data values from component props ("values")
		value: {
			immediate: true, deep: true,
			handler: function (data) {
				this.echoData = { ...data };
			}
		},
	}
}

</script>


<style lang="scss" scoped>
::v-deep {

	.radio-group--inline.strong-label {
		.label {
			font-weight: 500;
		}
	}

	.label-padding .caption {
		display: inline-block;
		padding-inline: 1ch;
	}

	@media (max-width: 768px) {
		.label-padding .caption {
			padding-inline: 0;
		}

		.flex-gap-large {
			gap: 1rem
		}

		details [class*="flex-item"]+[class*="flex-item"] {
			margin-top: 0
		}
	}
}
</style>