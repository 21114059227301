<template>
	<section class="rythm-v section--score">
		<hgroup>
			<h4>Index de BASFI</h4>
			<p class="small">Bath Ankylosing Spondylitis Functional Index</p>
		</hgroup>

		<p><strong>Au cours des 8 derniers jours :</strong></p>

		<ol class="rythm-v-large">
			<li>
				<RangeInput id="activity_difficulty"
										label="Activités quotidiennes"
										hint="<em>Sélectionnez le chiffre qui correspond le mieux à la difficulté que vous avez éprouvée pour accomplir vos activités quotidiennes à cause de votre spondylarthrite.</em>"
										before="Aucune difficulté"
										after="Difficulté extrème"
										min="0"
										max="10"
										data-display-range="true"
										required
										v-bind="$attrs"
										v-model="localValues.activity_difficulty"
										@input="emitScore" />
			</li>
			<li>
				<RangeInput id="fatigue"
										label="Fatigue"
										hint="<em>Sélectionnez le chiffre qui correspond le mieux à votre fatigue due à votre spondylarthrite.</em>"
										before="Aucune fatigue"
										after="Complètement épuisé(e)"
										min="0"
										max="10"
										data-display-range="true"
										required
										v-bind="$attrs"
										v-model="localValues.fatigue"
										@input="emitScore" />
			</li>
			<li>
				<RangeInput id="sleep"
										label="Sommeil"
										hint="<em>Sélectionnez le chiffre qui correspond le mieux aux difficultés de sommeil (pour vous reposer la nuit) que vous avez ressenties à cause de votre spondylarthrite.</em>"
										before="Aucune difficulté"
										after="Difficulté extrème"
										min="0"
										max="10"
										data-display-range="true"
										required
										v-bind="$attrs"
										v-model="localValues.sleep"
										@input="emitScore" />
			</li>
			<li>
				<RangeInput id="physical_wellness"
										label="Bien-être physique"
										hint="<em>En considérant toutes les façons dont votre santé a été affectée par votre spondylarthrite, comment évaluez-vous votre bien-être physique ? <br>Sélectionnez le chiffre qui correspond le
mieux à votre bien-être physique.</em>"
										before="Très bon"
										after="Très mauvais"
										min="0"
										max="10"
										data-display-range="true"
										required
										v-bind="$attrs"
										v-model="localValues.physical_wellness"
										@input="emitScore" />
			</li>
			<li>
				<RangeInput id="psychological_wellness"
										label="Bien-être psychologique"
										hint="<em>En considérant toutes les façons dont votre santé a été affectée par votre spondylarthrite, comment évaluez-vous votre bien-être psychologique ? <br>Sélectionnez le chiffre qui correspond le mieux à votre bien-être psychologique.</em>"
										before="Très bon"
										after="Très mauvais"
										min="0"
										max="10"
										required
										v-bind="$attrs"
										v-model="localValues.psychological_wellness"
										@input="emitScore" />
			</li>
			<li>
				<RangeInput id="illness_coping"
										label="Faire face à sa maladie"
										hint="<em>En considérant toutes les façons dont votre santé a été affectée par votre maladie, comment avez-vous fait face à votre maladie (géré, « fait avec ») ?</em>"
										before="Très bien"
										after="Très mal"
										min="0"
										max="10"
										required
										v-bind="$attrs"
										v-model="localValues.illness_coping"
										@input="emitScore" />
			</li>
		</ol>


		<div class="alert-success w-100">
			<ul>
				<li><span class="label">Index de BASFI :</span> <strong>{{ score }}</strong></li>
			</ul>
		</div>

	</section>

</template>


<script>
import RangeInput from "@/components/RangeInput.vue";

export default {
	name: 'ScoreBasfi',
	inheritAttrs: false,
	props: ['value'],

	components: {
		RangeInput
	},

	data() {
		return {
			localValues: {
				activity_difficulty: 0,
				fatigue: 0,
				sleep: 0,
				physical_wellness: 0,
				psychological_wellness: 0,
				illness_coping: 0
			},
		}
	},

	computed: {

		score() {
			// average of all localValues values
			return Math.round(Object.values(this.localValues).reduce((a, b) => parseInt(a) + parseInt(b)) / 6);
		},

		results() {
			return {
				score: `${this.score}`, // raw score always as string
				scoreRendered: `Index de BASFI : ${this.score}`, // formatted score, ex. with unit 24%, 12mm, …
				values: { ...this.localValues }, // local values "as is" (do not cast, etc.)
				details: null,
				detailsRendered: '',
			};
		}
	},

	methods: {
		emitScore() {
			this.$emit('input', this.results);
		}
	},

	watch: {
		// Init local model from `value` prop
		value: {
			immediate: true,
			handler: function (results) {
				if (results?.values) {
					this.localValues = { ...results.values }
				}
			},
		},
	},

}

</script>

<style lang="scss"
			 scoped>

			::v-deep {
				.input-slider {

					.input-slider-label,
					.range-slider {
						flex-basis: 100% !important;
					}

					.input-slider-label {
						gap: 1rem;
					}

					.label--before, .label--after {
						flex-basis: 15%;
					}
				}
			}

		</style>