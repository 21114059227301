import FontFaceObserver from "fontfaceobserver";

/**
 *
 * @param {Array} fonts Array des font-family à charger, sinon depuis l'attribut `data-fonts` du tag `<html>`
 * @param {String} loadedClass Classname ajouté au document (`<html>`) quand **toutes les polices sont chargées** (default `fonts-loaded`)
 */
const themeFontLoader = function(fonts = [], loadedClass = "fonts-loaded") {
	// do nothing if fonts all loaded already
	if (document.documentElement.className.indexOf(loadedClass) > -1) return;

	// check if FontFaceObserver is installed
	if (typeof FontFaceObserver === "undefined") return console.warn("FontFaceObserver dependency not found");

	// no fonts array specified, check the data attribute
	if (!fonts.length) {
		let dataFonts = document.documentElement.getAttribute("data-fonts") || "";
		if (!!dataFonts || "" !== dataFonts) fonts = dataFonts.split(",");
	}
	// Still no fonts?
	if (!fonts.length) return console.warn("empty font list!");

	// OK let's instantiate an observer for each font family
	let observers = [];

	fonts.forEach((family) => {
		family = family.trim();
		let obs = new FontFaceObserver(family);
		observers.push(obs.load());
	});

	// then wait until they're all loaded
	Promise.all(observers)
		.then(function(fonts) {
			fonts.forEach(function(font) {
				window.WP_DEBUG && console.info("font-loader.js", font.family + " " + font.weight + " " + "loaded");
			});
			// set the document class
			document.documentElement.classList.add("fonts-loaded");
		})
		.catch(function(err) {
			console.warn("font-loader.js", "Some critical font are not available:", err);
			document.documentElement.classList.add("fonts-load-error");
		});
};

// Récupère la liste des polices de l'attribut "data-fonts" sur le tag HTML
// <html lang="en" data-fonts="Nunito">
// themeFontLoader(["Lato"]);

export default themeFontLoader;
