<template>
	<div class="score score--das-28 rythm-v-large">
		<h4>Score d’activité de la polyarthrite rhumatoïde DAS 28<br><small>(Disease Activity Score)</small></h4>

		<section class="rythm-v">
			<h5>Articulations douloureuses</h5>
			<div class="rythm-v">
				<p>Nombre d'articulations douloureuses : <strong>{{ painfulJointsTotal }}</strong></p>
				<DasSelect :key="`das_painfuljoints_${session.id}`"
									 set="das"
									 v-model="painfulJoints.checkedDots"
									 @input="emitScore"></DasSelect>
			</div>
		</section>

		<section class="rythm-v">
			<h5>Articulations gonflées</h5>
			<div class="rythm-v">
				<p>Nombre d'articulations gonflées : <strong>{{ swollenJointsTotal }}</strong></p>
				<DasSelect :key="`das_swollenjoints_${session.id}`"
									 set="das"
									 v-model="swollenJoints.checkedDots"
									 @input="emitScore"></DasSelect>
			</div>
		</section>


		<div class="flex-row flex-gap-large">

			<div class="flex-item--50">
				<div class="input-group--inline required">
					<label for="das_28_vs">VS (mm/h) :</label>
					<input type="number"
								 name="das_28_vs"
								 id="das_28_vs"
								 v-model="vs"
								 v-bind="$attrs"
								 step=".01"
								 required
								 @input="emitScore" />
				</div>
			</div>

			<div class="flex-item--50">
				<div class="input-group--inline required">
					<label for="das_28_crp">CRP(mg/l) :</label>
					<input type="number"
								 name="das_28_crp"
								 id="das_28_crp"
								 v-model="crp"
								 v-bind="$attrs"
								 step=".01"
								 required
								 @input="emitScore" />
				</div>
			</div>

			<!-- Display results -->
			<div class="alert-success flex-item--50">
				<ul>
					<li><span class="label">Articulations douloureuses :</span> <strong>{{ painfulJointsTotal }}</strong></li>
					<li><span class="label">Articulations gonflées :</span> <strong>{{ swollenJointsTotal }}</strong></li>
					<li>
						<span class="label">DAS 28 VS :</span> <template v-if="!isNaN(scoreVS)"><strong>{{ scoreVS }}</strong> <span v-if="das28vsSeverity">({{ das28vsSeverity.label }})</span></template>
					</li>
					<li>
						<span class="label">DAS 28 CRP :</span> <template v-if="!isNaN(scoreCRP)"><strong>{{ scoreCRP }}</strong></template>
					</li>
				</ul>
			</div>

			<div class="alert-info flex-item--50">
				<div>
					<p class="label">Interprétation du score DAS 28 VS :</p>
					<ul>
						<li>DAS 28 &lt; 2,6 : Rémission</li>
						<li>DAS 28 &gt;= 2,6 et &lt;= 3,2 : Activité de la maladie faible</li>
						<li>DAS 28 &gt; 3,2 et &lt;= 5,1 : Activité de la maladie modérée</li>
						<li>DAS 28 > 5,1 : Activité de la maladie élevée</li>
					</ul>
				</div>
			</div>

		</div>

	</div>

</template>


<script>

import DasSelect from "./DasSelect.vue";

export default {
	name: 'ScoreDas28',
	inheritAttrs: false,
	components: {
		DasSelect
	},
	props: [
		'value', 'session'
	],

	data() {
		return {
			painfulJoints: {
				checkedDots: [],
			},
			swollenJoints: {
				checkedDots: [],
			},
			vs: 0,
			crp: 0
		}
	},

	computed: {

		das28vsSeverity() {
			// Interprétation du score DAS 28 VS

			if (this.scoreVS < 2.6) {
				return {
					label: "rémission",
					level: 1,
				};
			}
			else if (this.scoreVS >= 2.6 && this.scoreVS <= 3.2) {
				return {
					label: "activité faible",
					level: 2,
				}
			}
			else if (this.scoreVS > 3.2 && this.scoreVS <= 5.1) {
				return {
					label: "activité modérée",
					level: 3
				}
			}
			else if (this.scoreVS > 5.1) {
				return {
					label: "activité élevée",
					level: 4
				}
			}
			else {
				return '';
			}

		},

		painfulJointsTotal() {
			if (!this?.painfulJoints?.checkedDots.length) return 0;
			return this.painfulJoints.checkedDots.length;
		},

		swollenJointsTotal() {
			if (!this?.swollenJoints?.checkedDots.length) return 0;
			return this.swollenJoints.checkedDots.length;
		},

		scoreVS() {
			return this.calculateDAS28Score(this.painfulJointsTotal, this.swollenJointsTotal, this.vs);
		},

		scoreCRP() {
			return this.calculateDAS28Score(this.painfulJointsTotal, this.swollenJointsTotal, this.crp);
		},

		results() {
			return {
				score: `${this.scoreVS},${this.scoreCRP}`, // raw score always as string
				scoreRendered: `DAS 28 VS : ${this.scoreVS}, DAS 28 CRP : ${this.scoreCRP}`, // formatted score, ex. with unit 24%, 12mm, …
				values: {
					painfulJoints: this.painfulJoints.checkedDots,
					swollenJoints: this.swollenJoints.checkedDots,
					painfulJointsTotal: this.painfulJointsTotal,
					swollenJointsTotal: this.swollenJointsTotal,
					vs: this.vs,
					crp: this.crp,
					score_vs: this.scoreVS,
					score_crp: this.scoreCRP
				},
				details: null,
				detailsRendered: '',
			};
		}
	},

	methods: {

		/**
		 * DAS = 0,55 x(Indice articulaire : 28) + 0,284 x(synovites : 28) + 0,33 x log VS (ou CRP)
		 * 
		 * Non ajouté au score : (0,0142 x appréciation globale du patient)
		 */
		calculateDAS28Score(jointIndex, synovites, vs_or_crp) {
			// Check if the input values are valid
			if (isNaN(jointIndex) || isNaN(synovites) || isNaN(vs_or_crp) || vs_or_crp <= 0) {
				return "DM";
			}

			// Calculate the log of vs_or_crp
			const logVsCrp = Math.log(vs_or_crp);

			// Calculate the DAS 28 score using the provided formula
			const das28Score = 0.55 * jointIndex + 0.284 * synovites + 0.33 * logVsCrp;

			// Round the result to two decimal places
			return das28Score.toFixed(2);
		},


		emitScore() {
			this.$emit('input', this.results);
		}
	},

	watch: {
		// Init data values from component props ("values")
		value: {
			immediate: true,
			handler: function (data) {
				this.painfulJoints.checkedDots = data?.values?.painfulJoints || [];
				this.swollenJoints.checkedDots = data?.values?.swollenJoints || [];
				this.vs = data?.values?.vs || 0;
				this.crp = data?.values?.crp || 0;
			},
		},
	},
}
</script>