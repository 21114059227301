<template>
	<header class="app-header">
		<div class="site-title">
			<p class="text--app-name text-gradient">Observatoire ADARIC</p>
			<p>Étude sur l’aphérèse leucocytaire ADACOLUMN®</p>
		</div>
		<div class="site-nav-wrapper"
				 v-if="!isMobileCheck">
			<nav class="navigation-primary flex-row flex-center-v flex-center-h rythm-h">
				<router-link to="/"
										 class="button">Accueil</router-link>
				<router-link v-if="userIsLoggedIn"
										 to="/cases"
										 class="button">Tableau de bord</router-link>
				<router-link v-if="userIsAdmin"
										 to="/users"
										 class="button">Utilisateurs</router-link>
				<router-link v-if="userIsAdmin"
										 to="/centers"
										 class="button">Centres</router-link>
				<router-link v-if="userIsLoggedIn"
										 :to="`/user/${user.id}/profile`"
										 class="button">Profil</router-link>

				<button-logout @click="logoutUser"
											 v-if="userIsLoggedIn">Déconnexion</button-logout>
			</nav>
		</div>
	</header>
</template>

<style lang="scss">
@import "~@/scss/config";
@import "~@/scss/06-components/components.header";
</style>

<script>
import buttonLogout from "@/components/buttonLogout";

export default {
	name: "AppHeader",
	components: { buttonLogout },
};
</script>
