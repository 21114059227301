<template>
	<article class="view-user-profile">
		<div class="site-wrapper rythm-v">
			<header>
				<h1 v-if="userData && user && userData.id === user.id">Mon compte</h1>
				<h1 v-else>Profil de l’utilisateur</h1>
			</header>

			<section class="card shadow rythm-v"
							 v-if="!userData && !!userDataError">
				<h2>Cet utilisateur n’existe pas :(</h2>
				<p role="alert"
					 class="alert-error">
					Une erreur est survenue lors du chargement des données de cet
					utilisateur.
				</p>
			</section>

			<section class="section--profile card shadow rythm-v"
							 v-if="userData">
				<h2>Informations professionnelles</h2>

				<form method="post"
							@submit.prevent="updateUserData">
					<div class="flex-row flex-center-v">
						<div class="flex-item--50">
							<div class="input-group required">
								<label for="firstname">Prénom</label>
								<input type="text"
											 name="firstname"
											 id="firstname"
											 v-model="userData.firstname"
											 required />
							</div>
						</div>

						<div class="flex-item--50">
							<div class="input-group required">
								<label for="lastname">Nom</label>
								<input type="text"
											 name="lastname"
											 id="lastname"
											 v-model="userData.lastname"
											 required />
							</div>
						</div>

						<div class="flex-item--50">
							<div class="input-group required">
								<label for="city">Ville d'exercice</label>
								<input type="text"
											 name="city"
											 id="city"
											 v-model="userData.city"
											 required />
							</div>
						</div>

						<div class="flex-item--50 flex-item--grow">
							<div class="input-group required">
								<label for="country">Pays</label>
								<select name="country"
												id="country"
												required
												@change="setCountry">
									<option value="">Aucune sélection</option>
									<option v-for="(country, index) in lists.countries"
													:key="`country_${index}`"
													:value="country.code"
													:selected="country.code === userData.country">{{ country.name }}</option>
								</select>
							</div>
						</div>

						<div class="flex-item--auto flex-item--grow"
								 v-if="userData.country === 'FR' || userData.rpps !== ''">
							<div class="input-group required">
								<label class="label-rpps"
											 for="rpps">
									<span class="caption">N° R.P.P.S</span>
									<span class="small">(numéro composé de 11 caractères)</span>
								</label>

								<input type="text"
											 name="rpps"
											 id="rpps"
											 v-model="userData.rpps"
											 pattern="^(\d{11})$"
											 placeholder="Exemple : 10000123456"
											 required />
							</div>
						</div>

						<div class="flex-item--auto flex-item--grow">
							<div class="input-group required">
								<span class="label">Rôle</span>
								<select name="role"
												id="role"
												:disabled="!userCan('manage_users', user) ||
					(!userCan('manage_options', user) && userData.role === 4)
					"
												v-model="userData.role">
									<option value="">Choisir une option</option>
									<option v-for="(role, index) in lists.roles"
													:key="`opt_${index}`"
													:value="role.id"
													:disabled="role.id === 4 && !userCan('manage_options', user)
					">
										{{ role.name }}
									</option>
								</select>
							</div>
						</div>

						<div class="input-group--controls flex-item--100">
							<button type="submit"
											class="button--primary">
								<svg class="icon icon-check"
										 role="img"
										 xmlns="http://www.w3.org/2000/svg"
										 width="18"
										 height="13"
										 viewBox="0 0 18 13">
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
                    clip-rule="evenodd"
                  />
                </svg>
								<span class="caption">Enregistrer</span>
							</button>
						</div>
					</div>
				</form>
			</section>

			<section class="section--password card shadow rythm-v"
							 v-if="userData">
				<h2>Changer de mot de passe</h2>

				<div><span class="tag--success"
								style="margin-right: .5em;">Information</span> <span style="line-height: 2;">Pour reconfigurer l’authentification à deux facteurs (2FA/TOTP), rendez-vous sur la page <RouterLink to="/password-reset">Mot de passe oublié</RouterLink>.</span></div>

				<form @submit.prevent="updatePassword">
					<div class="flex-row">
						<div class="flex-item--50">
							<div class="input-group">
								<label for="password">Mot de passe
									<span class="small">(12 caractères minimum)</span></label>
								<input type="password"
											 name="password"
											 id="password"
											 v-model="updatePasswordData.password"
											 :invalid="passwordIsInvalid"
											 required />
							</div>
						</div>

						<div class="flex-item--50">
							<div class="input-group">
								<label for="password-check">Mot de passe <span class="small">(vérification)</span></label>
								<input type="password"
											 name="password-check"
											 id="password-check"
											 v-model="updatePasswordData.passwordCheck"
											 :invalid="passwordIsInvalid"
											 required />
							</div>
						</div>
					</div>

					<ul class="form-validations">
						<li v-for="(validate, index) in passwordValidations"
								:key="index">
							<IconCheckmark :class="validate.checked
					? 'icon icon-checkmark checked'
					: 'icon icon-checkmark'
					"></IconCheckmark>
							<span class="caption"
										v-html="validate.caption"></span>
						</li>
					</ul>

					<div class="input-group--controls">
						<button type="submit"
										class="button--primary"
										:disabled="!formValidates(passwordValidations)">
							<svg class="icon icon-check"
									 role="img"
									 xmlns="http://www.w3.org/2000/svg"
									 width="18"
									 height="13"
									 viewBox="0 0 18 13">
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
                  clip-rule="evenodd"
                />
              </svg>
							<span class="caption">Modifier le mot de passe</span>
						</button>
					</div>
				</form>
			</section>

			<section class="section--email card shadow rythm-v"
							 v-if="userData">
				<h2>Changer d'adresse e-mail</h2>

				<form @submit.prevent="requestEmailUpdate">
					<div class="flex-row flex-align-bottom">
						<div class="flex-item--50">
							<div class="input-group">
								<label for="email">E-mail <span class="small"></span></label>
								<input type="email"
											 name="email"
											 id="email"
											 v-model="updateEmailData.email"
											 :invalid="emailIsInvalid"
											 required />
							</div>
						</div>
						<ul class="form-validations">
							<li v-for="(validate, index) in emailValidations"
									:key="index">
								<svg role="img"
										 :class="validate.checked
					? 'icon icon-checkmark checked'
					: 'icon icon-checkmark'
					"
										 xmlns="http://www.w3.org/2000/svg"
										 width="22"
										 height="22"
										 viewBox="0 0 22 22">
                  <svg:style>
                    .icon-checkmark .icon-checked {
                      opacity: 0;
                      fill: #3aaa35;
                    }
                    .icon-checkmark .icon-unchecked {
                      fill: #95c11f;
                      opacity: 1;
                    }

                    .icon-checkmark.checked .icon-checked {
                      opacity: 1;
                    }
                    .icon-checkmark.checked .icon-unchecked {
                      opacity: 0;
                    }
                  </svg:style>
                  <path
                    class="icon-unchecked"
                    fill="#95c11f"
                    fill-rule="evenodd"
                    d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
                    clip-rule="evenodd"
                  />
                  <g class="icon-checked">
                    <path
                      fill="#3AAA35"
                      fill-rule="evenodd"
                      d="M14.663 2.77915C12.8902 1.98926 10.9096 1.79357 9.01657 2.22128C7.12351 2.649 5.41942 3.67719 4.15845 5.15252C2.89749 6.62785 2.14721 8.47126 2.01951 10.4078C1.89181 12.3444 2.39354 14.2704 3.44987 15.8985C4.50619 17.5266 6.06051 18.7697 7.88102 19.4423C9.70153 20.1149 11.6907 20.1809 13.5518 19.6307C15.413 19.0804 17.0464 17.9432 18.2084 16.3888C19.3705 14.8344 19.9989 12.9459 20 11.0052V10.0857C20 9.53344 20.4477 9.08572 21 9.08572C21.5523 9.08572 22 9.53344 22 10.0857V11.0057C21.9986 13.3778 21.2306 15.6864 19.8103 17.5863C18.39 19.4862 16.3936 20.876 14.1189 21.5486C11.8442 22.2211 9.41299 22.1404 7.18792 21.3183C4.96285 20.4963 3.06312 18.977 1.77206 16.9871C0.480993 14.9971 -0.13223 12.6432 0.0238445 10.2762C0.179919 7.90932 1.09693 5.65626 2.63811 3.85308C4.17929 2.0499 6.26206 0.793217 8.57581 0.270457C10.8896 -0.252303 13.3103 -0.0131332 15.477 0.952297C15.9815 1.17708 16.2082 1.76825 15.9834 2.27272C15.7587 2.7772 15.1675 3.00393 14.663 2.77915Z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill="#3AAA35"
                      fill-rule="evenodd"
                      d="M21.7068 2.29816C22.0975 2.68848 22.0978 3.32165 21.7075 3.71237L11.7075 13.7224C11.52 13.9101 11.2656 14.0155 11.0002 14.0156C10.7349 14.0157 10.4805 13.9103 10.2929 13.7227L7.29289 10.7227C6.90237 10.3322 6.90237 9.69903 7.29289 9.30851C7.68342 8.91798 8.31658 8.91798 8.70711 9.30851L10.9996 11.601L20.2925 2.29886C20.6829 1.90814 21.316 1.90783 21.7068 2.29816Z"
                      clip-rule="evenodd"
                    />
                  </g>
                </svg>

								<span class="caption"
											v-html="validate.caption"></span>
							</li>
						</ul>
					</div>

					<div class="input-group--controls">
						<button type="submit"
										class="button--primary"
										:disabled="!formValidates(emailValidations)">
							<svg class="icon icon-check"
									 role="img"
									 xmlns="http://www.w3.org/2000/svg"
									 width="18"
									 height="13"
									 viewBox="0 0 18 13">
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
                  clip-rule="evenodd"
                />
              </svg>
							<span class="caption">Modifier l'e-mail</span>
						</button>
					</div>
				</form>
			</section>
		</div>
	</article>
</template>

<style lang="scss">
@import "~@/scss/common-views.scss";

.caption code {
	letter-spacing: .5ch;
}
</style>

<style lang="scss"
			 scoped>
			@import "~@/scss/user-profile.scss";
		</style>

<script>
import { emailIsValid } from "../libs/helpers";
import countries from "../libs/countries.json";
import IconCheckmark from '../assets/svg/icon-checkmark.svg';
import { checkPasswordChars } from '../libs/helpers.js';

export default {
	name: "userProfile",
	components: {
		IconCheckmark
	},
	props: ["userProfileID"], // Route parameter

	data: function () {
		return {
			// User profile
			userData: null,
			userDataError: false,
			tooltip_rpps:
				"<strong>Le numéro R.P.P.S. est composé de 11 caractères.</strong>",

			// radio/selects lists
			lists: {
				countries: countries,
				roles: [
					// member (default)
					{
						id: 1,
						name: "Médecin",
					},
					// referrer
					{
						id: 2,
						name: "Référent",
					},
					// admin
					{
						id: 3,
						name: "ARC",
					},
					// superadmin
					{
						id: 4,
						name: "Administrateur",
					},
				],
			},

			// Password change
			passwordResetToken: null,
			passwordResetPending: false,
			passwordResetSuccess: false,
			updatePasswordData: {
				password: "",
				passwordCheck: "",
			},

			// Email change
			updateEmailData: {
				email: "",
			},
		};
	},

	computed: {
		emailIsInvalid: function () {
			if (this.updateEmailData.email === "") return false; // No data
			let invalidEmail = this.emailValidations.find(
				(el) => el.type === "email" && !el.checked
			);
			return invalidEmail;
		},

		passwordIsInvalid: function () {
			if (
				this.updatePasswordData.password === "" &&
				this.updatePasswordData.passwordCheck === ""
			)
				return false; // No data
			let passwordLength = this.passwordValidations.find(
				(el) => el.type === "passwordLength"
			);

			let passwordMatch = this.passwordValidations.find(
				(el) => el.type === "passwordMatch"
			);

			let passwordStrength = this.passwordValidations.find(
				(el) => el.type === "passwordStrength"
			);

			return !passwordStrength.checked || !passwordLength.checked || !passwordMatch.checked;
		},

		emailValidations: function () {
			let validations = [
				{
					type: "validEmail",
					caption: "L'adresse e-mail est valide.",
					checked: this.validateEmail(this.updateEmailData.email),
				},
			];

			if (this.userData)
				validations.push({
					type: "validEmail",
					caption: `L'adresse e-mail est différente de <strong>${this.userData.email}</strong>`,
					checked:
						this.updateEmailData.email !== "" &&
						this.validateEmail(this.updateEmailData.email) &&
						this.updateEmailData.email !== this.userData.email,
				});

			return validations;
		},

		passwordValidations: function () {
			return [
				{
					type: "passwordStrength",
					caption:
						'Le mot de passe comprend des majuscules, minuscules, chiffres et au moins un des caractères suivants : <code>#?!@$%^&*-.</code>',
					checked: checkPasswordChars(this.updatePasswordData.password) !== null,
				},
				{
					type: "passwordLength",
					caption: "La longueur du mot de passe est de 12 caractères minimum.",
					checked: this.updatePasswordData.password.length >= 12,
				},
				{
					type: "passwordMatch",
					caption: "Les mots de passe correspondent.",
					checked:
						this.updatePasswordData.password != "" &&
						this.updatePasswordData.passwordCheck != "" &&
						this.updatePasswordData.password ===
						this.updatePasswordData.passwordCheck,
				},
			];
		},

		// Current user data
		user: function () {
			return this.userIsLoggedIn && this.$store.state.userData;
		},
	},

	methods: {
		// Check if all validation object "checked" properties are true
		formValidates: function (validations) {
			let trues = 0;
			validations.forEach((el) => {
				if (el.checked) trues++;
			});
			return trues === validations.length;
		},

		// Check the validity of an email address
		validateEmail: function (email) {
			return emailIsValid(email);
		},

		// set country select value
		setCountry: function (e) {
			this.userData.country = e.target.value;
		},

		// set specialty select value
		setSpecialty: function (e) {
			this.userData.specialty = e.target.value;
		},

		// set practice select value
		setPractice: function (e) {
			this.userData.practice = e.target.value;
		},

		// Send email update validation (double opt-in)
		requestEmailUpdate: function () {
			let payload = this.updateEmailData;
			let userID = this.userProfileID;

			this.$store
				.dispatch("REQUEST_EMAIL_UPDATE", { payload: payload, userID: userID })
				.then(() => {
					this.$toasted.global.appSuccess({
						message:
							"Consultez votre boîte de réception, un lien vous a été envoyé pour modifier votre adresse e-mail.",
					});
				})
				.catch((error) => {
					let msg =
						"Impossible d'envoyer la demande de changement d'e-mail, veuillez nous contacter.";

					if (error.status === 409) {
						msg = "Cette adresse e-mail est déjà utilisée&nbsp;!";
					}

					this.$toasted.global.appError({
						message: msg,
					});
				});
		},

		// Update password
		updatePassword: function () {
			let payload = this.updatePasswordData;
			let userID = this.userProfileID;

			this.$store
				.dispatch("UPDATE_PASSWORD", { payload: payload, userID: userID })
				.then(() => {
					this.passwordResetSuccess = true;
					let toast = this.$toasted.global.appSuccess({
						message: "Votre mot de passe est modifié.",
					});
					setTimeout(function () {
						toast.goAway();
					}, 5000);
				})
				.catch((error) => {
					this.passwordResetSuccess = false;
					this.passwordResetToken = null;

					let message = "Impossible de modifer le mot de passe !";

					if (error?.status === 422 && error?.data?.payload?.error === 'invalid-password-strength') {
						message = "Le mot de passe ne respecte pas les critères de securité.";
					}

					if (error?.status === 422 && error?.data?.payload?.error === 'old-password-used') {
						message = "Vous ne pouvez pas choisir un mot de passe utilisé précédemment.";
					}

					this.$toasted.global.appError({
						message: message
					});
				});
		},

		/**
		 * Update user profile
		 */
		updateUserData: function () {
			let userID = this.userProfileID;
			this.userData.user_id = userID;

			let formData = new FormData();
			formData.append("data", JSON.stringify(this.userData));

			let payload = this.userData;

			this.$store
				.dispatch("UPDATE_USER_DATA", { userID, payload })
				.then((response) => {
					// this.loadUserData();
					this.userData = response;

					let toast = this.$toasted.global.appSuccess({
						message: "Les informations ont été mise à jour.",
					});
					setTimeout(function () {
						toast.goAway();
					}, 5000);
				})
				.catch((error) => {
					return this.$toasted.global.appError({
						message:
							"Une erreur est survenue lors de l'enregistrement de votre profil.<br>Veuillez nous contacter pour résoudre le problème.",
					});
				});
		},

		// Load user data from database
		loadUserData: function () {
			this.$store
				.dispatch("GET_USER_DATA", this.userProfileID)
				.then((response) => {
					this.userData = response;
					// Set email
					this.updateEmailData.email = this.userData.email;
				})
				.catch((error) => {
					this.userDataError = true;
				});
		},
	},

	mounted() {
		// NOTE: Get the `userProfileID` parameter value from the component's props or this.$route.params.userProfileID
		// NOTE: Only admins can view another user profile: Check if the current user ID (this.user.id) is different of the user profile id (userProfileID)
		this.loadUserData();
	},
};
</script>
