<template>
	<div class="card shadow rythm-v"
			 v-if="caseData">

		<form ref="saveForm"
					id="saveForm"
					method="post"
					@submit.prevent="saveCase"></form>

		<div class="form-wrapper rythm-v-large">

			<h2>Autres évènements indésirables</h2>

			<section class="section rythm-v-large">

				<!-- NOTE: this component autosaves (saveCase) -->
				<AdverseEventsForm form="saveForm"
													 :key="`adverse_events_${caseID}`"
													 v-model="caseData.adverse_events"
													 @submit="saveCase"></AdverseEventsForm>

			</section>


			<!-- Controls -->
			<div class="flex-row">
				<div class="input-group--controls flex-item--100 flex-row flex-center-h rythm-h flex-gap">
					<router-link :to="`/cases/${caseID}/sessions/s1`"
											 class="button--secondary">
						<IconLeft />
						<span class="caption">Retour</span>
					</router-link>
				</div>
			</div>


		</div>
	</div>
</template>

<script>
import AdverseEventsForm from "@/components/AdverseEventsForm.vue";
import IconLeft from '../assets/svg/icons/icon-left.svg';

export default {
	name: "DashboardAdverseEvents",
	props: ["caseID"],
	components: { AdverseEventsForm, IconLeft },

	data: function () {
		return {
			caseData: {},
		};
	},

	methods: {
		// Save the case
		saveCase(event) {
			const submitter = event?.submitter?.name;
			const validates = this.$refs.saveForm.checkValidity();
			if (!validates) return;

			this.$store
				.dispatch("SAVE_CASE", this.caseData)
				.then((caseData) => {

					this.scrollToTop();
					this.$toasted.clear();

					let toast = this.$toasted.global.appSuccess({
						message: "Merci, les données ont été enregistrées.",
					});

					setTimeout(function () {
						toast.goAway();
					}, 5000);
				})
				.catch((error) => {
					console.warn(error);
					return [];
				});
		},


		// Get current case data
		async loadCase() {
			let caseData = await this.$store.state.caseData;
			if (null == caseData) {
				this.$router.push(`/cases`);
				return;
			}

			// Set caseData
			this.caseData = caseData;
		},
	},

	async mounted() {
		// Load data
		this.loadCase();
	},

}
</script>


<style lang="scss" scoped>
@import "~@/scss/common-views.scss";

.section--additionnal-treatments [class*="flex-item"] {
	padding: 1rem;
}

details> :not(summary) {
	padding: 0 1rem 1rem 1rem;
}

::v-deep .radio-group--inline {
	.label {
		font-weight: 500;
	}
}
</style>
