<template>
	<div class="score score--index-charlson rythm-v">
		<h4>Indice de comorbidité de Charlson</h4>

		<section class="rythm-v stripped-children">

			<div class="input-group--inline">
				<span class="label">Age &le; 40 ans (à date du diagnostic) :</span>
				<div class="text-right"
						 style="width:15rem">
					<span v-if="patientAge && scoreModel.age_inf_eq_40">{{ scoreModel.age_inf_eq_40 === "1" ? "Oui" : "Non" }}</span> <span v-if="patientAge">({{ patientAge }} ans)</span>
					<p v-else><small><em>Saisir la date du diagnostic</em></small></p>
				</div>
			</div>

			<radio-toggle id="infarctus_myocarde"
										label="Infarctus de myocarde<br><small>(antécédents pas seulement les modifications de l’ECG)</small> :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.infarctus_myocarde"
										@input="calculateScore"
										required></radio-toggle>

			<radio-toggle id="insuffisance_cardiaque_congestive"
										label="Insuffisance cardiaque congestive :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.insuffisance_cardiaque_congestive"
										@input="calculateScore"
										required></radio-toggle>

			<radio-toggle id="maladie_vasculaire"
										label="Maladie vasculaire périphérique<br><small>(incluant l’anévrisme de l’aorte &gt; 6 cm)</small> :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.maladie_vasculaire"
										@input="calculateScore"
										required></radio-toggle>

			<radio-toggle id="maladies_cerebrovasculaires"
										label="Maladies cérébrovasculaires<br><small>AVC avec séquelles modérées ou sans séquelles ou AIT</small> :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.maladies_cerebrovasculaires"
										@input="calculateScore"
										required></radio-toggle>

			<radio-toggle id="troubles_neurocognitifs"
										label="Troubles neurocognitifs :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.troubles_neurocognitifs"
										@input="calculateScore"
										required></radio-toggle>

			<radio-toggle id="maladie_chronique_pulmonaire"
										label="Maladie chronique pulmonaire :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.maladie_chronique_pulmonaire"
										@input="calculateScore"
										required></radio-toggle>

			<radio-toggle id="connectivite"
										label="Connectivite :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.connectivite"
										@input="calculateScore"
										required></radio-toggle>

			<radio-toggle id="maladie_ulcereuse"
										label="Maladie ulcéreuse :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.maladie_ulcereuse"
										@input="calculateScore"
										required></radio-toggle>

			<radio-toggle id="maladie_hepatique_legere"
										label="Maladie hépatique légère<br><small>(sans hypertension portale, incluant les hépatites chroniques)</small> :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.maladie_hepatique_legere"
										@input="calculateScore"
										required></radio-toggle>


			<radio-toggle id="diabete_sans_lesion_organique"
										label="Diabète sans lésion organique associée<br><small>(sauf si traité par régime seul)</small> :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.diabete_sans_lesion_organique"
										@input="calculateScore"
										required></radio-toggle>

			<radio-toggle id="hemiplegie"
										label="Hémiplégie :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.hemiplegie"
										@input="calculateScore"
										required></radio-toggle>

			<radio-toggle id="maladie_renale_moderee_severe"
										label="Maladie rénale modérée ou sévère :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.maladie_renale_moderee_severe"
										@input="calculateScore"
										required></radio-toggle>

			<radio-toggle id="diabete_lesion_organique"
										label="Diabète avec lésion organique<br><small>(rétinopathie, neuropathie, néphropathie)</small> :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.diabete_lesion_organique"
										@input="calculateScore"
										required></radio-toggle>

			<radio-toggle id="tumeur_sans_metastases"
										label="Tumeur sans métastases<br><small>(sauf si diagnostiqué depuis plus de 6 mois)</small> :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.tumeur_sans_metastases"
										@input="calculateScore"
										required></radio-toggle>

			<radio-toggle id="leucemie_aigue_chronique"
										label="Leucémie aigüe ou chronique :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.leucemie_aigue_chronique"
										@input="calculateScore"
										required></radio-toggle>

			<radio-toggle id="lymphome"
										label="Lymphome :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.lymphome"
										@input="calculateScore"
										required></radio-toggle>

			<radio-toggle id="maladie_hepatique_moderee_severe"
										label="Maladie hépatique modérée ou sévère :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.maladie_hepatique_moderee_severe"
										@input="calculateScore"
										required></radio-toggle>


			<radio-toggle id="tumeur_solide_metastatique"
										label="Tumeur solide métastatique :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.tumeur_solide_metastatique"
										@input="calculateScore"
										required></radio-toggle>

			<radio-toggle id="vih_avec_sida"
										label="VIH avec SIDA :"
										class="radio-group--inline flex-align-spaced"
										v-model="scoreModel.vih_avec_sida"
										@input="calculateScore"
										required></radio-toggle>

			<!-- Display results -->
			<div class="alert-success w-100"
					 v-if="!isNaN(result.score)">
				<ul>
					<li><span class="label">Score :</span> <strong>{{ result.score }}</strong></li>
					<li><span class="label">Mortalité à 1 an :</span> <strong>{{ result.details.one_year_mortality }}</strong></li>
					<li><span class="label">Mortalité à 10 ans :</span> <strong>{{ result.details.ten_years_mortality }}</strong></li>
				</ul>
			</div>

		</section>

	</div>
</template>

<style lang="scss">
.score--index-charlson {

	&>section {
		max-width: 100%;
	}

}


@media (max-width: 600px) {
	.score--index-charlson {
		.radio-group--inline .label {
			flex-basis: 100%;
			flex-grow: 1;
		}
	}
}
</style>


<script>
import radioToggle from "@/components/radioToggle.vue";

export default {
	name: "IndexCharlson",
	components: { radioToggle },
	props: {
		// Values from the parent component
		values: {
			type: Object,
			default: () => ({}),
		},
		patientAge: {
			type: Number
		},
	},

	data: function () {
		return {
			// Component lists to render checkboxes
			checkboxes: {
				yesNo: ["Oui", "Non"],
			},

			// local values
			scoreModel: {},

			// score
			score: 0

			// patient age points
			// patientAgePoints: 0,
		};
	},

	// Non reactive data
	staticData: {
		// score data points
		scorePoints: {
			age_inf_eq_40: 1, // Pour chaque décennie après 40 ans, ajouter un score de 1, Formule : (âge - 40)/10)
			infarctus_myocarde: 1,
			insuffisance_cardiaque_congestive: 1,
			maladie_vasculaire: 1,
			maladies_cerebrovasculaires: 1,
			troubles_neurocognitifs: 1,
			maladie_chronique_pulmonaire: 1,
			connectivite: 1,
			maladie_ulcereuse: 1,
			maladie_hepatique_legere: 1,
			diabete_sans_lesion_organique: 1,
			hemiplegie: 2,
			maladie_renale_moderee_severe: 2,
			diabete_lesion_organique: 2,
			tumeur_sans_metastases: 2,
			leucemie_aigue_chronique: 2,
			lymphome: 2,
			maladie_hepatique_moderee_severe: 3,
			tumeur_solide_metastatique: 6,
			vih_avec_sida: 6,
		},
		// defaults
		defaultValues: {
			infarctus_myocarde: "0",
			insuffisance_cardiaque_congestive: "0",
			maladie_vasculaire: "0",
			maladies_cerebrovasculaires: "0",
			troubles_neurocognitifs: "0",
			maladie_chronique_pulmonaire: "0",
			connectivite: "0",
			maladie_ulcereuse: "0",
			maladie_hepatique_legere: "0",
			diabete_sans_lesion_organique: "0",
			hemiplegie: "0",
			maladie_renale_moderee_severe: "0",
			diabete_lesion_organique: "0",
			tumeur_sans_metastases: "0",
			leucemie_aigue_chronique: "0",
			lymphome: "0",
			maladie_hepatique_moderee_severe: "0",
			tumeur_solide_metastatique: "0",
			vih_avec_sida: "0",
		}
	},

	computed: {
		// NOTE: "result.values" will be saved by the parent component (cf. emitScore())
		// and ** reused as a props ** to initialize this component.
		result() {

			/*
				Interprétation :

				Score 			Mortalité à 1 an
				0 					12%
				1-2 				26%
				3-4 				52%
				≥ 5 				85%

				Score 			Mortalité à 10 ans
				0 					8%
				1 					25%
				2 					48%
				≥ 3 				59%
			*/

			let one_year_mortality = "";

			if (this.score === 0) {
				one_year_mortality = "12 %";
			}
			else if (this.score >= 1 && this.score <= 2) {
				one_year_mortality = "26 %";
			}
			else if (this.score >= 3 && this.score <= 4) {
				one_year_mortality = "52 %";
			}
			else if (this.score >= 5) {
				one_year_mortality = "85 %";
			}

			let ten_years_mortality = "";

			if (this.score === 0) {
				ten_years_mortality = "8 %";
			}
			else if (this.score === 1) {
				ten_years_mortality = "25 %";
			}
			else if (this.score === 2) {
				ten_years_mortality = "48 %";
			}
			else if (this.score >= 3) {
				ten_years_mortality = "59 %";
			}

			return {
				score: `${this.score}`, // raw score always as string
				scoreRendered: '', // formatted score, ex. with unit 24%, 12mm, …
				detailsRendered: `Mortalité à 1 an : ${one_year_mortality}, Mortalité à 10 ans : ${ten_years_mortality}`,
				values: { ...this.scoreModel },
				details: {
					one_year_mortality: one_year_mortality,
					ten_years_mortality: ten_years_mortality,
				}
			};
		},
	},

	methods: {

		calculateScore() {
			let total = 0;

			Object.keys(this.scoreModel).forEach((key) => {
				let itemPoints = parseInt(this.$options.staticData.scorePoints[key]);
				let itemValue = parseInt(this.scoreModel[key]);
				let itemScore = 0
				let patientAgePoints = 0;

				// age_inf_eq_40: Pour chaque décennie après 40 ans, ajouter un score de 1, Formule : (âge - 40)/10)
				if (key === "age_inf_eq_40") {
					if (itemValue === 0) { // Non
						patientAgePoints = Math.floor((this.patientAge - 40) / 10);
						itemScore = itemPoints + patientAgePoints;
					}
					else {
						itemScore = 0; // Oui
					}
				}
				else {
					itemScore = (itemValue !== 0) ? itemPoints : 0;
				}

				total += itemScore;
			});

			this.score = Math.round(total);
			this.emitScore();
			return this.score;
		},


		emitScore() {
			this.$emit("input", this.result);
		},
	},

	watch: {
		// Init data values from component props ("values")
		values: {
			immediate: true,
			handler: function (valuesFromProps) {
				// use defaults values
				if (!valuesFromProps || (Object.keys(valuesFromProps).length === 0 && valuesFromProps.constructor === Object)) {
					this.scoreModel = { ...this.$options.staticData.defaultValues };
				}
				else {
					// init from parent's data
					this.scoreModel = { ...valuesFromProps };
				}
			},
		},

		// Upadate score if patientAge changes
		patientAge(age) {
			if (!age) return;
			// Set score "age <= 40" value
			this.scoreModel.age_inf_eq_40 = age <= 40 ? "1" : "0";
			// Update score
			this.calculateScore();
		},
	},

	mounted() {
		// Calculate score with loaded values
		this.calculateScore();
	}
};
</script>
