/* eslint-disable */
// requestAnimationFrame polyfill
window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

import Vue from "vue";

Vue.config.productionTip = false;

// Router (VueRouter)
import router from "./router.js";

// Vue head
import VueHead from "vue-head";
Vue.use(VueHead);

// Store (vuex)
import store from "./store.js";

// NProgres
import { NProgress } from "./nprogress.js";

// Axios
import { appConfig } from "./config.js";
import { HTTP } from "./http-common.js";

// Font Loader (FontFaceObserver)
import themeFontLoader from "./libs/font-loader.js";
themeFontLoader(["Roboto", "Roboto Mono", "Montserrat"]);

// Notifications
import Toasted from "vue-toasted";
import { toastedDefaults, toastedSuccess, toastedError, toastedDefaultConfirm } from "./libs/toasted-options.js";

Vue.use(Toasted);

Vue.toasted.register(
  "appError",
  (payload) => {
    // if there is no message passed show default message
    if (!payload.message) return "Une erreur inconnue est survenue !";
    // if there is a message show it with the message
    // ❗⚠ ⚠️
    return `<div class="message-wrapper">${payload.message}</div>`;
  },
  toastedError
);

Vue.toasted.register(
  "appSuccess",
  (payload) => {
    // ✓
    return `<div class="message-wrapper">${payload.message}</div>`;
  },
  toastedSuccess
);

Vue.toasted.register(
  "appHelp",
  (payload) => {
    // ✓
    return `<div class="message-wrapper">${payload.message}</div>`;
  },
  toastedSuccess
);

Vue.toasted.register(
  "appInfo",
  (payload) => {
    return `<div class="message-wrapper">${payload.message}</div>`;
  },
  toastedDefaults
);

// tooltips
import VTooltip from "v-tooltip";
Vue.use(VTooltip);

// Google Tag Manager
// import VueGtag from "vue-gtag";

// isMobile
import isMobile from "ismobilejs";

// Global Mixins
Vue.mixin({
  data: function() {
    return {};
  },

  methods: {
    /**
     * Scroll to top
     */
    scrollToTop: function() {
      setTimeout(function() {
        window.scrollTo(0, 0, { behavior: "smooth" });
      }, 300);
    },

    /**
     * dateDIff - calculate the diffrence between two timestamps
     * @param {Int} fromDate Javascript timestamp (ms)
     * @param {Int} toDate Javascript timestamp (ms)
     */
    dateDiff: function(fromDate, toDate) {
      const diff = Math.abs(fromDate - toDate);
      return {
        milliseconds: diff,
        seconds: Math.floor(diff / 1000),
        minutes: Math.floor(diff / 1000 / 60),
        hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
      };
    },

    stopHeartBeat: function() {
      console.info("stopHeartBeat");
      clearInterval(window.heartbeatClock);
    },

    // Check the token expiration every 5 minutes
    startHeartBeat: function() {
      // Already started?
      if (null === window.heartbeatClock || typeof window.heartbeatClock !== "undefined") {
        console.info("HeartBeat already started");
        return false;
      }

      // Start
      console.info("startHeartBeat");
      window.heartbeatClock = setInterval(this.sessionKeepAlive, 10000);
    },

    // Request new tokens if needed
    sessionKeepAlive: async function() {
      const accessTokenExpires = await this.$store.state.accessTokenExpires;

      // Bail if not authenticated (no accessTokenExpires)
      if (!accessTokenExpires) {
        this.stopHeartBeat();
        return;
      }

      // Calculate the difference between now and the expiration date (in JS timestamp)
      const diff = this.dateDiff(Date.now(), accessTokenExpires * 1000);
      this.isDev && console.info(`sessionKeepAlive: token expires in ${diff.minutes} minutes`);

      // If the token expires in 15 minutes, request a new one.
      if (parseInt(diff.minutes) <= 15) {
        this.isDev && console.info("Requesting a new token");

        this.$store
          .dispatch("REFRESH_ACCESS_TOKEN")
          .then((accessTokenExpires) => {
            this.isDev && console.info("new accessTokenExpires", accessTokenExpires);
          })
          .catch((err) => {
            // User Logged out (refresh token expired)
            this.$toasted.global.appError({
              message: "Votre session a expirée, veuillez vous connecter.",
            });
            this.isDev && console.warn("user was logged out");
          });
      }
      // else {
      //   this.isDev && console.info("no need to refresh access token");
      // }
    },

    // Check user capabilities
    userCan: function(capabilities, user) {
      // eventual string to array
      if (typeof capabilities == "string") capabilities = [capabilities];
      // find capabilities
      let f = Object.values(user.caps).find((cap) => capabilities.indexOf(cap) !== -1);
      return f;
    },

    // Logout user and redirect to home
    logoutUser: function(event) {
      this.$store.dispatch("USER_LOGOUT").then(() => this.$router.push("/"));
    },

    // Connexion de l'utilisateur (récupération des infos de l'utilisateur grace à l'access_token)
    autoLoginUser: function() {
      this.$store
        .dispatch("USER_LOGIN")
        .then((response) => {
          EventBus.$emit("trackEvent", "Connexion", `Utilisateur ${response.data.payload.id} connecté (auto)`);

          // Initiate the accessToken refresh hearbeat
          this.startHeartBeat();
        })
        .catch((err) => {
          let errMsg = "";

          // Failed to auto login user (usually the tokens expired)
          if (!err) {
            errMsg = "Échec de connexion automatique (erreur inconnue)";
          } else {
            errMsg = `Échec de connexion automatique (${err.status})`;
          }

          EventBus.$emit("trackEvent", "Connexion", errMsg);

          this.$toasted.global.appError({
            message: "Votre session a expirée, veuillez vous connecter.",
          });

          // Redirect to home
          if (this.$route.name !== "home") this.$router.replace("/");
        });
    },

    mobileCheck: function() {
      const mobileViewport = window.innerWidth <= 768;
      store.state.isMobileViewport = mobileViewport;
    },

    mobileCheckOnResize: function(event) {
      window.requestAnimationFrame(this.mobileCheck);
    },

    // Return results of the isMobile libray as a global mixin
    isMobile: function() {
      return isMobile(window.navigator);
    },
  },

  computed: {
    // Current Disease Type
    // is_crohn: function() {
    //   return store.state.caseData.desease_data.disease_type.toLowerCase() === "crohn";
    // },
    // is_rch: function() {
    //   return store.state.caseData.desease_data.disease_type.toLowerCase() === "rch";
    // },

    // Return the value of isMobileViewport from the store.
    // Updated on app created and on window resize.
    isMobileCheck: function() {
      return store.state.isMobileViewport;
    },

    // User object
    user: function() {
      return this.userIsLoggedIn && this.$store.state.userData;
    },

    // Is the user a admin? (ARC)
    userIsAdmin: function() {
      return this.user && this.userCan("manage_users", this.user);
    },

    // Is the user a Super Admin?
    userIsSuperAdmin: function() {
      return this.user && this.userCan("manage_options", this.user);
    },

    // Utilisateur connecté ?
    userIsLoggedIn: function() {
      return this.$store.state.userData && this.$store.state.userData.is_verified;
    },

    // Utilisateur connecté mais e-mail non vérifiée
    userValidationPending: function() {
      return !!this.$store.state.userToken && this.$store.state.userData && !this.$store.state.userData.is_verified;
    },
  },

  created: function() {},
});

// Enable Analytics
// Vue.use(
//   VueGtag,
//   {
//     config: {
//       id: appConfig.trackingID,
//       enabled: appConfig.eventTracking,
//     },
//     appName: "ADARIC",
//     pageTrackerScreenviewEnabled: appConfig.eventTracking,
//   },
//   router
// );

// Global event bus
import { EventBus } from "./event-bus.js";

// Main Vue
import App from "./App.vue";

new Vue({
  router,
  store,
  render: (h) => h(App),

  data: {
    isDev: process.env.NODE_ENV !== "production",
  },

  head: {
    title: function() {
      return {
        inner: "Observatoire ADARIC",
        complement: "Étude sur l’aphérèse leucocytaire ADACOLUMN®",
      };
    },

    // Meta tags
    meta: [
      { name: "msapplication-TileColor", content: "#00aba9" },
      { name: "theme-color", content: "#ffffff" },
      {
        name: "description",
        content: "Étude sur l’aphérèse leucocytaire ADACOLUMN® : un traitement mécanique de circulation extra corporelle du sang pour retirer les cellules inflammatoires.",
      },
    ],

    // Scripts
    script: [
      {
        type: "text/javascript",
        // src: "https://cdnjs.cloudflare.com/ajax/libs/outdated-browser/1.1.5/outdatedbrowser.min.js",
        src: "/js/outdatedbrowser.min.js",
        async: true,
        body: true,
      },
    ],
  },

  created() {
    // Token stored in a secure cookie
    HTTP.defaults.withCredentials = true;

    // Authentification with JSON Web Token
    // Try to login user
    if (this.user) this.autoLoginUser();

    // Synchronisation du localStorage
    // L'application root surveille les mutations du Store (`subscribe`) et execute l'action STORAGE_SYNC pour mettre à jour le localStorage.
    this.$store.subscribe((mutation, state) => {
      this.$store
        .dispatch("STORAGE_SYNC", mutation)
        .then((response) => this.isDev && console.info("STORAGE_SYNC done", mutation))
        .catch((error) => this.isDev && console.warn("STORAGE_SYNC error", error));
    });

    // Network / Authentication error handling
    HTTP.interceptors.response.use(
      (response) => response,
      (error) => {
        NProgress.done();

        this.isDev && console.info("Interceptor error (url,status)", error.config.url, error.response.status);

        // Error analytics event
        if (error) {
          EventBus.$emit("trackEvent", `Request Error - ${error.config.url}`, `${error.response.status} – ${JSON.stringify(error.response)} `);
        }

        // Login - return all errors to original promise (store/login.vue)
        // error.config.url is the requested remote URL
        // Ex. if (error.config.url === "login" && error.response.status === 403) {
        if (error.config.url === "sign-in") {
          return Promise.reject(error.response);
        }

        // Auth error on request - Hijack promise response (return nothing)
        if (error.response && [401].indexOf(error.response.status) !== -1) {
          console.warn("AUTH ERROR (interceptor)");

          this.$toasted.global.appError({
            message: "Votre session a expirée, veuillez vous reconnecter.",
          });

          this.$store.dispatch("AUTH_LOGOUT").then(() => this.$router.push("/"));

          return Promise.reject(error.response);
        }

        // 404
        if (error.response && [404].indexOf(error.response.status) !== -1) {
          console.warn("404 ERROR (interceptor)");

          this.$toasted.global.appError({
            message: "Ressource introuvable !",
          });
          return Promise.reject(error.response);
        }

        // return Promise.reject(error); // error.response?
        return Promise.reject(error.response);

        // Cancelling a promise?
        // https://github.com/axios/axios/issues/583
        // return new Promise(() => {});
      }
    );
  },

  mounted() {
    // Load data JSON schemas / default values and structure for v-models
    this.$store.dispatch("LOAD_JSON_SCHEMAS");

    // Listen for the resize event to dynamically update the isMobileCheck value
    this.mobileCheck();
    window.addEventListener("resize", this.mobileCheckOnResize, false);

    // Global Route Guards
    // Les routes qui nécessitent une authentification ont une meta `authenticated` définie sur `true`
    // Ces routes nécessitent que l'utilisateur soit **authentifié** (`store.userData` ne peut pas être indéfini)
    this.$router.beforeEach((to, from, next) => {
      // Analytics "page view"
      let fromPage = typeof from.name !== "undefined" ? from.name : "?";
      let toPage = typeof to.name !== "undefined" ? to.name : "?";
      EventBus.$emit("trackEvent", "Router", `from:${fromPage} to:${toPage}`);

      // no authentication needed
      if (!to.meta || !to.meta.authenticated) next();
      // route needs an authenticated user
      else {
        if (!store.state.userData) {
          let msg = "";
          let nextRoute = "/";
          msg = "Vous devez vous identifier pour accéder à cette page.";

          Vue.toasted.global.appError({
            message: msg,
          });

          next(nextRoute);
        } else next();
      }
    });

    // Configure NProgress when the DOM is ready…
    NProgress.configure({
      parent: ".app-header",
      showSpinner: false,
      easing: "ease-out",
    });

    // GA track events
    EventBus.$on("trackEvent", (action, label, value = 1) => {
      console.log("EventBus", action, label);
      if (!appConfig.eventTracking) return false;

      // this.$gtag.event(action, {
      //   event_category: "Application ADARIC",
      //   event_label: label,
      //   value: value,
      // });
    });
  },

  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener("resize", this.mobileCheckOnResize);

    // Unregister the heartbeat interval
    this.stopHeartBeat();
  },
}).$mount("#app");
