<template>
  <section class="section--new-user card shadow rythm-v">
    <h2>Ajouter un utilisateur</h2>

    <div class="form-wrapper ">
      <form method="post" @submit.prevent="registerUser">
        <div class="flex-row">
          <div class="flex-item--30">
            <div class="input-group required">
              <label for="firstname">Prénom</label>
              <input
                type="text"
                name="firstname"
                id="firstname"
                v-model="registerData.firstname"
                required
              />
            </div>
          </div>

          <div class="flex-item--30">
            <div class="input-group required">
              <label for="lastname">Nom</label>
              <input
                type="text"
                name="lastname"
                id="lastname"
                v-model="registerData.lastname"
                required
              />
            </div>
          </div>

          <div class="flex-item--30">
            <div class="input-group required">
              <label for="email">Adresse e-mail</label>
              <input
                type="email"
                name="email"
                id="email"
                v-model="registerData.email"
                required
              />
            </div>
          </div>

          <div class="flex-item--50 flex-item--grow">
            <div class="input-group required">
              <label class="label-rpps" for="rpps">
                <span class="caption">N° R.P.P.S</span>
                <span
                  class="info"
                  v-tooltip="{
                    content: tooltip_rpps,
                    placement: 'top-center',
                    classes: ['info'],
                    delay: {
                      show: 100,
                      hide: 500000,
                    },
                    offset: 10,
                    trigger: 'hover focus click',
                  }"
                  ><svg
                    class="icon icon-info"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="none"
                    viewBox="0 0 22 22"
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M11 10C11.5523 10 12 10.4477 12 11V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V11C10 10.4477 10.4477 10 11 10Z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill="currentColor"
                      d="M12 7C12 7.55228 11.5523 8 11 8C10.4477 8 10 7.55228 10 7C10 6.44772 10.4477 6 11 6C11.5523 6 12 6.44772 12 7Z"
                    />
                  </svg>
                </span>
              </label>
              <input
                type="text"
                name="rpps"
                id="rpps"
                v-model="registerData.rpps"
                pattern="^(\d{11})$"
                required
                placeholder="Exemple : 10000123456"
              />
              <!-- 9 OR 11 pattern="^[0-9]{9}([0-9]{2})?$" -->
            </div>
          </div>

          <div class="flex-item--50 flex-item--grow">
            <div class="input-group required">
              <span class="label">Rôle</span>
              <select
                name="role"
                id="role"
                :disabled="!userCan('manage_users', user)"
                v-model="registerData.role"
              >
                <option value="">Choisir une option</option>
                <option
                  v-for="(role, index) in lists.roles"
                  :key="`opt_${index}`"
                  :value="role.id"
                  :disabled="role.id === 4 && !userCan('manage_options', user)"
                >
                  {{ role.name }}
                </option>
              </select>
            </div>
          </div>

          <!-- <div class="flex-item--50">
            <div class="input-group required">
              <label for="center">Centre</label>
              <select
                name="center"
                id="center"
                v-model="registerData.center"
                required
              >
                <option
                  v-for="(index, option) in centers"
                  :value="option.value"
                  :key="`center_${index}`"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div> -->

          <div
            class="input-group--controls flex-item--100 flex-row flex-align-right"
          >
            <button type="submit" class="button--primary">
              <svg
                class="icon icon-check"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="13"
                fill="none"
                viewBox="0 0 18 13"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="caption">Enregister</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<style lang="scss">
@import "~@/scss/common-views.scss";
</style>

<style lang="scss" scoped>
@import "~@/scss/config";

[class*="flex-item"] {
  padding: 1rem;
}

.input-group-radio {
  label {
    white-space: nowrap;
  }
}

.label + [class*="input-group--inline"].input-group-radio {
  margin-left: 0;
}

.label-rpps {
  & > * + * {
    margin-left: 0.5em;
  }

  .icon {
    width: 1em;
    height: auto;
    color: $color-cta;
  }
}
</style>

<script>
import { HTTP } from "../http-common";
import { emailIsValid } from "../libs/helpers";
import { EventBus } from "../event-bus.js";

export default {
  name: "UsersNew",
  components: {},

  data: function() {
    return {
      // Member center
      centers: {},

      // Registration data
      registerData: {
        firstname: "",
        lastname: "",
        email: "",
        rpps: "",
        center: "",
        role: "",
      },

      // radio/selects lists
      lists: {
        roles: [
          // member (default)
          {
            id: 1,
            name: "Médecin",
          },
          // referrer
          {
            id: 2,
            name: "Référent",
          },
          // admin
          {
            id: 3,
            name: "ARC",
          },
          // superadmin
          {
            id: 4,
            name: "Administrateur",
          },
        ],
      },

      tooltip_rpps:
        "<strong>Le numéro R.P.P.S. est composé de 11 caractères.</strong>",
    };
  },

  methods: {
    // Create new user
    registerUser: function() {
      this.$store
        .dispatch("CREATE_USER", this.registerData)
        .then(() => {
          EventBus.$emit(
            "trackEvent",
            "Inscription",
            "Compte validé, utilisateur ajouté"
          );

          let toast = this.$toasted.global.appSuccess({
            message:
              "<strong>Le compte est enregistré, merci !</strong><br>Un e-mail a été envoyé à l’utilisateur.",
          });

          setTimeout(function() {
            toast.goAway();
          }, 5000);

          this.$router.push("/users");
        })
        .catch((response) => {
          // Email exists
          if (response.status === 409) {
            EventBus.$emit(
              "trackEvent",
              "Inscription",
              "Erreur : e-mail existant"
            );
            this.$toasted.global.appError({
              message: "Cette adresse e-mail est déjà utilisée",
            });
          }

          // Invalid email
          if (
            response.status === 422 &&
            response.data.payload.error.type === "invalid-email"
          ) {
            EventBus.$emit(
              "trackEvent",
              "Inscription",
              "Erreur : e-mail invalide"
            );
            this.$toasted.global.appError({
              message: "L'adresse e-mail est invalide",
            });
          }

          // Something's missing
          if (
            response.status === 422 &&
            response.data.payload.error.type === "required-field"
          ) {
            EventBus.$emit(
              "trackEvent",
              "Inscription",
              "Erreur : champs requis manquant"
            );
            this.$toasted.global.appError({
              message: "Un champs requis est manquant",
            });
          }
        });
    },

    validateEmail: function(email) {
      return emailIsValid(email);
    },
  },

  mounted() {},
};
</script>
