/**
 * Check if object is empty
 * @param {object} obj
 * @returns boolean
 */
const isObjectEmpty = (obj) => {
  if (null === obj) return true;
  return Object.keys(obj).length === 0;
};

/**
 * Calculate age to today or specific date
 * @param {Date} birthdate
 * @param {Date} newdate
 * @return {Number}
 */
const calculateAge = (birthdate, newdate = null) => {
  const _newdate = null === newdate ? Date.now() : newdate;
  const diff_ms = _newdate - birthdate.getTime();
  const age_dt = new Date(diff_ms);
  return Math.abs(age_dt.getUTCFullYear() - 1970);
};

/**
 * Check password characters
 * Lowercase, uppercase, number and special chars (#?!@$%^&*-)
 *
 * @return array
 */
const checkPasswordChars = (password) => {
  const regex = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/gm;
  const str = password;
  const m = regex.exec(str);
  return m;
};

// Gets the value at path of object.
// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get
const get = (obj, path, defaultValue) => {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

// Create the slug of a string
const slugify = (value, sep = "-") => {
  value = value.replace(/^\s+|\s+$/g, ""); // trim
  value = value.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
  const to = "aaaaaeeeeeiiiiooooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    value = value.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  value = value
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  // replace dashes with custom separator
  if (sep !== "-") value = value.replace(/-+/g, sep);

  return value;
};

const replaceDiacritics = (str) => {
  const diacritics = [
    { char: "A", base: /[\300-\306]/g },
    { char: "a", base: /[\340-\346]/g },
    { char: "E", base: /[\310-\313]/g },
    { char: "e", base: /[\350-\353]/g },
    { char: "I", base: /[\314-\317]/g },
    { char: "i", base: /[\354-\357]/g },
    { char: "O", base: /[\322-\330]/g },
    { char: "o", base: /[\362-\370]/g },
    { char: "U", base: /[\331-\334]/g },
    { char: "u", base: /[\371-\374]/g },
    { char: "N", base: /[\321]/g },
    { char: "n", base: /[\361]/g },
    { char: "C", base: /[\307]/g },
    { char: "c", base: /[\347]/g },
  ];

  diacritics.forEach(function(letter) {
    str = str.replace(letter.base, letter.char);
  });

  return str;
};

const emailIsValid = function(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// Generate UUID
// https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
const generateUUID = function() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  // Time in microseconds since page-load or 0 if unsupported
  var d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export { isObjectEmpty, calculateAge, checkPasswordChars, replaceDiacritics, get, slugify, emailIsValid, generateUUID };
