<template>
	<div>
		<radio-group class="radio-group"
								 id="efficiency_percentage"
								 ref="efficiency_percentage"
								 :radios="efficiency_percentage_options"
								 name="efficiency_percentage"
								 v-model="efficiency_percentage"
								 v-bind="$attrs"
								 @change="emitScore"
								 :required="true"></radio-group>
	</div>
</template>

<script>
import radioGroup from "@/components/radioGroup.vue";

export default {
	name: 'EfficiencyPercentage',
	inheritAttrs: false,
	props: ['value'],

	components: {
		radioGroup,
	},

	data() {
		return {
			attrsObject: null,
			efficiency_percentage: "",
			efficiency_percentage_options: [
				{ value: "Pas de changement ou c’est devenu pire" },
				{ value: "Presque pareil, pratiquement pas d’amélioration" },
				{ value: "Un peu mieux, mais pas de changement notable" },
				{ value: "Plutôt mieux mais le changement ne fait pas de réelle différence" },
				{ value: "Mieux, le changement est modéré mais notable" },
				{ value: "Mieux, avec sans aucun doute une amélioration réelle qui fait la différence" },
				{ value: "Nettement mieux, une amélioration considérable qui fait toute la différence" }
			]
		}
	},

	methods: {
		emitScore(value) {
			this.$emit('input', value);
		}
	},

	watch: {
		// Init data values from component props ("values")
		value: {
			immediate: true, deep: true,
			handler: function (data) {
				this.efficiency_percentage = data;
			}
		},
	}

}

</script>