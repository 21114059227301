var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.cases)?_c('section',{staticClass:"card shadow rythm-v"},[_c('h2',[_vm._v("Liste des patients")]),(_vm.cases.length)?_c('vue-good-table',{attrs:{"fixed-header":true,"columns":_vm.casesTable.columns,"rows":_vm.casesTable.rows,"paginate":true,"pagination-options":{
											enabled: true,
											nextLabel: 'suivant',
											prevLabel: 'précédent',
											rowsPerPageLabel: 'lignes par page',
											ofLabel: 'sur',
											pageLabel: 'page', // for 'pages' mode
											allLabel: 'Toutes',
										},"globalSearch":true,"search-options":{
											enabled: true,
											skipDiacritics: true,
											placeholder: 'Rechercher dans cette liste',
											// externalQuery: searchTerms,
										},"styleClass":"vgt-table striped"},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.field == 'actions')?_c('span',{staticClass:"offscreen"},[_vm._v("Actions")]):_c('span',[_vm._v(_vm._s(props.column.label))])]}},{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('div',[(props.column.field == 'actions')?_c('router-link',{staticClass:"button--icon",attrs:{"to":`/cases/${props.row.id}/patient`}},[_c('svg',{staticClass:"icon icon-edit",attrs:{"focusable":"false","role":"img","aria-labelledby":"button-edit-title","xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('title',{attrs:{"id":"button-edit-title"}},[_vm._v(" Modifier ")]),_c('path',{attrs:{"d":"M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"}}),_c('path',{attrs:{"d":"M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"}})])]):_vm._e(),(props.column.field == 'actions' && _vm.userCan('delete_cases', _vm.user)
									)?_c('button',{staticClass:"button--icon",on:{"click":function($event){return _vm.deleteCase(props.row.id)}}},[_c('svg',{staticClass:"icon feather feather-user-x",attrs:{"aria-labelledby":"button-remove-title","focusable":"false","role":"img","xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('title',{attrs:{"id":"button-remove-title"}},[_vm._v(" Supprimer ")]),_c('path',{attrs:{"d":"M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"}}),_c('circle',{attrs:{"cx":"8.5","cy":"7","r":"4"}}),_c('line',{attrs:{"x1":"18","y1":"8","x2":"23","y2":"13"}}),_c('line',{attrs:{"x1":"23","y1":"8","x2":"18","y2":"13"}})])]):_vm._e()],1):_vm._e(),(props.column.field == 'case_status')?_c('span',{class:_vm.isCaseComplete(props.row) === 'terminé'
								? 'tag--success'
								: 'tag--warning'},[_vm._v(_vm._s(_vm.isCaseComplete(props.row)))]):_vm._e(),(props.column.field == 'desease_data')?_c('span',[_vm._v(_vm._s(props.row.desease_data.disease_type))]):_vm._e(),(props.column.field !== 'desease_data')?_c('span',[_vm._v(_vm._s(props.formattedRow[props.column.field]))]):_vm._e()]}}],null,false,2023321786)},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center"},[_c('em',[_vm._v("Aucun patient")])])])]):_c('div',{staticClass:"alert-info"},[_c('div',{staticClass:"text-center"},[_c('em',[_vm._v("Aucun patient pour le moment.")])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }