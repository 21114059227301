// src/config.js
const appConfig = {
  API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
  TURNSTILE_SITE_KEY: process.env.VUE_APP_TURNSTILE_SITE_KEY,
  // trackingID: "UA-314596-57",
  // event tracking off for Plausible
  eventTracking: false, // process.env.NODE_ENV !== "development",
};

if (process.env.NODE_ENV === "development") {
  console.clear();
  console.info("process.env", process.env);
  console.info("appConfig", appConfig);
}

export { appConfig };
