<template>
	<div class="flex-row flex-gap-large"
			 v-if="localModel">

		<table v-if="adverseEventsCount"
					 ref="adverseEventsTable"
					 class="striped-table"
					 @mouseover="scrollTable">
			<thead>
				<tr>
					<th class="label">Évènement</th>
					<th class="dateStart">Date de début</th>
					<th class="dateEnd">Date de fin</th>
					<th class="intensity">Intensité</th>
					<th class="severity">Gravité</th>
					<th class="studyAccountability">Imputabilité à l’étude ou son traitement</th>
					<th class="studyProcessingEffect">Action sur le traitement de l’étude</th>
					<th class="otherEffect">Autre action</th>
					<th class="evolution">Évolution</th>
					<th></th>
				</tr>
			</thead>

			<tbody>
				<tr v-for="(ev, index) in localModel"
						:key="`adverseEvent_${index}`">
					<td>
						<details>
							<summary>
								<IconDanger class="icon icon-danger"
														v-if="isSeverityHigh(ev)" /> {{ summarize(ev.label, 30) }}
							</summary>
							<div>{{ ev.label }}</div>
						</details>
					</td>
					<td>{{ ev.dateStart ? convertYMDtoFR(ev.dateStart) : 'DM' }}</td>
					<td>{{ ev.dateEnd ? convertYMDtoFR(ev.dateEnd) : 'DM' }}</td>
					<td>{{ ev.intensity || 'DM' }}</td>
					<td :class="isSeverityHigh(ev)?.severity ? 'severity-high' : ''">{{ ev.severity || 'DM' }}</td>
					<td>{{ ev.studyAccountability || 'DM' }}</td>
					<td>{{ ev.studyProcessingEffect || 'DM' }}</td>
					<td :class="isSeverityHigh(ev)?.otherEffect ? 'severity-high' : ''">{{ ev.otherEffect || 'DM' }} {{ ev.otherEffectDetails || '' }}</td>
					<td :class="isSeverityHigh(ev)?.evolution ? 'severity-high' : ''">{{ ev.evolution || 'DM' }}</td>

					<td class="text-right">
						<button type="button"
										class="button--danger--outline"
										title="Supprimer"
										@click="removeEvent(ev)">
							<IconBin class="icon" />
						</button>
					</td>

				</tr>
			</tbody>
		</table>


		<!-- NOTE: Nouveau message : "Compléter et faxer au promoteur la fiche de déclaration d’un événement indésirable grave" -->
		<div v-if="adverseEventsCount">
			<p style="max-width: initial;">
				<IconDanger class="icon"
										style="vertical-align: sub; width: 18px;" />  Les effets indésirables sont notifiés par les investigateurs selon les plans locaux de vigilance mis en place dans le cadre des activités de soin.
			</p>

			<ul>
				<li v-if="adverseEventsCount">
					Si <strong>Gravité</strong> = 1
				</li>

				<li v-if="adverseEventsCount">
					Si <strong>Autre action</strong> = 3
				</li>

				<li v-if="adverseEventsCount">
					Si <strong>Evolution</strong> = 5
				</li>
			</ul>
		</div>

		<p v-else><em>Aucun évènement indésirable renseigné.</em></p>


		<details class="card w-100 rythm-v-large"
						 :open="!adverseEventsCount">
			<summary>
				<h4>Ajouter un évènement indésirable</h4>
			</summary>

			<div class="flex-row flex-gap-large">

				<div class="flex-item--50 flex-col flex-gap flex-align-top">

					<div class="flex-item w-100">
						<div class="input-group">
							<label for="event_label">Évènement</label>
							<textarea id="event_label"
												name="event_label"
												rows="3"
												cols="30"
												v-model="adverseEventModel.label"></textarea>
						</div>
					</div>


					<div class="flex-row flex-gap-large">
						<div class="flex-item">
							<div class="flex-col flex-gap-small">
								<label for="event_dateStart">Date de début</label>

								<div class="flex-col flex-gap">
									<input id="event_dateStart"
												 name="event_dateStart"
												 type="date"
												 v-model="adverseEventModel.dateStart" />

									<radio-toggle class="radio-group--inline"
																id="event_inProgress"
																name="event_inProgress"
																label="En cours"
																v-model="adverseEventModel.inProgress"
																@input="resetDateEnd"></radio-toggle>
								</div>
							</div>
						</div>

						<div class="flex-item"
								 v-if="adverseEventModel?.inProgress && !parseInt(adverseEventModel?.inProgress)">

							<div class="input-group">
								<label for="event_dateEnd">Date de fin</label>
								<input id="event_dateEnd"
											 name="event_dateEnd"
											 type="date"
											 v-model="adverseEventModel.dateEnd" />
							</div>
						</div>
					</div>

					<div class="flex-item">
						<div class="input-group">
							<label for="event_intensity">Intensité</label>
							<select id="event_intensity"
											name="event_intensity"
											v-model="adverseEventModel.intensity">
								<option value="">Choisir</option>
								<option v-for="(item, index) in lists.intensity"
												:key="`intensity_${index}`"
												:value="item.value">{{ item.label }}</option>
							</select>
						</div>
					</div>


					<div class="flex-item">
						<div class="input-group">
							<label for="event_severity">Gravité</label>
							<select id="event_severity"
											name="event_severity"
											v-model="adverseEventModel.severity">
								<option value="">Choisir</option>
								<option v-for="(item, index) in lists.severity"
												:key="`severity_${index}`"
												:value="item.value">{{ item.label }}</option>
							</select>
						</div>
					</div>

				</div>

				<div class="flex-item--50 flex-col flex-gap flex-align-top">

					<div class="flex-item">
						<div class="input-group">
							<label for="event_studyAccountability">Imputabilité à l’étude ou au traitement de l’étude</label>
							<select id="event_studyAccountability"
											name="event_studyAccountability"
											v-model="adverseEventModel.studyAccountability">
								<option value="">Choisir</option>
								<option v-for="(item, index) in lists.studyAccountability"
												:key="`studyAccountability_${index}`"
												:value="item.value">{{ item.label }}</option>
							</select>
						</div>
					</div>


					<div class="flex-item">
						<div class="input-group">
							<label for="event_studyProcessingEffect">Action sur le traitement de l’étude</label>
							<select id="event_studyProcessingEffect"
											name="event_studyProcessingEffect"
											v-model="adverseEventModel.studyProcessingEffect">
								<option value="">Choisir</option>
								<option v-for="(item, index) in lists.studyProcessingEffect"
												:key="`studyProcessingEffect_${index}`"
												:value="item.value">{{ item.label }}</option>
							</select>
						</div>
					</div>


					<div class="flex-item rythm-v">
						<div class="input-group">
							<label for="event_otherEffect">Autre action</label>
							<select id="event_otherEffect"
											name="event_otherEffect"
											v-model="adverseEventModel.otherEffect"
											@input="resetOtherEffectDetails">
								<option value="">Choisir</option>
								<option v-for="(item, index) in lists.otherEffect"
												:key="`otherEffect_${index}`"
												:value="item.value">{{ item.label }}</option>
							</select>
						</div>

						<div class="input-group"
								 v-if="adverseEventModel.otherEffect?.includes('autre')">
							<label for="event_otherEffectDetails">Précisez :</label>
							<input id="event_otherEffectDetails"
										 name="event_otherEffectDetails"
										 type="text"
										 v-model="adverseEventModel.otherEffectDetails" />
						</div>
					</div>


					<div class="flex-item">
						<div class="input-group">
							<label for="event_evolution">Évolution</label>
							<select id="event_evolution"
											name="event_evolution"
											v-model="adverseEventModel.evolution">
								<option value="">Choisir</option>
								<option v-for="(item, index) in lists.evolution"
												:key="`evolution_${index}`"
												:value="item.value">{{ item.label }}</option>
							</select>
						</div>
					</div>


					<div class="flex-item">
						<div class="input-group--controls">
							<button class="button--cta"
											type="button"
											@click="addEvent()"
											:disabled="!adverseEventModel.label"
											:title="!adverseEventModel.label ? 'Veuillez décrire l’évènement' : ''">
								<IconPlus class="icon" />
								<span class="caption">Ajouter l’évènement</span>
							</button>
						</div>
					</div>


				</div>

			</div>
		</details>

	</div>
</template>

<script>
import IconPlus from "@/assets/svg/icons/icon-plus.svg";
import IconBin from "@/assets/svg/icons/icon-bin.svg";
import IconDanger from "@/assets/svg/icons/icon-danger.svg";
import radioToggle from "@/components/radioToggle.vue";

export default {
	name: 'AdverseEventsForm',
	inheritAttrs: false,
	props: ['value'],

	components: {
		IconPlus, IconBin, IconDanger, radioToggle
	},

	staticData: {
		adverseEventDefaults: {
			slug: "",
			label: "",
			dateStart: "",
			dateEnd: "",
			inProgress: "",
			intensity: "",
			severity: "",
			studyAccountability: "",
			studyProcessingEffect: "",
			otherEffect: "",
			otherEffectDetails: "",
			evolution: "",
		},
	},

	data() {
		return {
			localModel: {},
			adverseEventModel: structuredClone(this.$options.staticData.adverseEventDefaults), // { ...this.$options.staticData.adverseEventDefaults }

			lists: {
				intensity: [
					{
						value: "légère (1)",
						label: "légère",
					},
					{
						value: "modérée (2)",
						label: "modérée",
					},
					{
						value: "sévère (3)",
						label: "sévère",
					},
				],
				severity: [
					{
						value: "grave (1)",
						label: "grave",
					},
					{
						value: "non grave (2)",
						label: "non grave",
					},

				],
				studyAccountability: [
					{
						value: "exclue (1)",
						label: "exclue",
					},
					{
						value: "douteuse (2)",
						label: "douteuse",
					},
					{
						value: "possible (3)",
						label: "possible",
					},
					{
						value: "non évaluable (4)",
						label: "non évaluable",
					},

				],
				studyProcessingEffect: [
					{
						value: "aucune (1)",
						label: "aucune",
					},
					{
						value: "diminution de posologie (2)",
						label: "diminution de posologie",
					},
					{
						value: "augmentation de posologie (3)",
						label: "augmentation de posologie",
					},
					{
						value: "suspension provisoire (4)",
						label: "suspension provisoire",
					},
					{
						value: "arrêt définitif (5)",
						label: "arrêt définitif",
					},
				],
				otherEffect: [
					{
						value: "aucune (1)",
						label: "aucune",
					},
					{
						value: "traitement(s) symptomatique(s) (2)",
						label: "traitement(s) symptomatique(s)",
					},
					{
						value: "hospitalisation (3)",
						label: "hospitalisation",
					},
					{
						value: "autre (4)",
						label: "autre (préciser)",
					},
				],
				evolution: [
					{
						value: "guérison sans séquelles (1)",
						label: "guérison sans séquelles",
					},
					{
						value: "guérison avec séquelles (2)",
						label: "guérison avec séquelles",
					},
					{
						value: "non encore rétabli (3)",
						label: "non encore rétabli",
					},
					{
						value: "aggravation (4)",
						label: "aggravation",
					},
					{
						value: "décès (5)",
						label: "décès",
					},
					{
						value: "inconnue (6)",
						label: "inconnue",
					},
				]
			},

			tableEventsSetup: false,
		}
	},

	computed: {

		eventInProgress() {
			return this.localModel;
		},

		adverseEventsCount() {
			const count = Object.values(this.localModel).length
			return count ? count : 0;
		},
	},

	methods: {
		emitScore() {
			this.$emit('input', this.localModel);
			this.$emit('submit');
		},

		isSeverityHigh(event) {
			const severity = event.severity?.includes("grave");
			const otherEffect = event.otherEffect?.includes("hospitalisation");
			const evolution = event.evolution?.includes("décès");

			if (severity || otherEffect || evolution) {
				return {
					severity: severity,
					otherEffect: otherEffect,
					evolution: evolution
				}
			}

			return null;
		},

		// auto scroll table with mouse
		scrollTable() {
			const isMobile = this.isMobile(window.navigator).any;

			if (!isMobile || !this.tableEventsSetup) {
				const table = this.$refs.adverseEventsTable;
				table.addEventListener('mousemove', (event) => {
					requestAnimationFrame(() => {
						const mouseX = event.clientX;
						table.scrollLeft = mouseX - (table.clientWidth / 2);
					})
				});
				this.tableEventsSetup = true;
			}
		},

		// summarize text to 80 characters by default
		summarize(text, length = 80) {
			return text?.length > length ? text.slice(0, length) + "..." : text;
		},

		// convert yyyy-mm-dd to french date format
		convertYMDtoFR(date) {
			return date ? new Date(date).toLocaleDateString('fr-FR') : "";
		},

		resetOtherEffectDetails(event) {
			if (!this.adverseEventModel.otherEffect?.includes("autre")) {
				this.adverseEventModel.otherEffectDetails = "";
			}
		},

		resetDateEnd(event) {
			if (parseInt(this.adverseEventModel.inProgress)) {
				this.adverseEventModel.dateEnd = "";
			}
		},

		removeEvent(ev) {
			this.$delete(this.localModel, ev.slug);
			// emit data to parent component
			this.emitScore();
		},

		addEvent(ev) {
			if (!this.adverseEventModel.label) return;

			const adverseEvent = { ...this.adverseEventModel }; // clone object
			adverseEvent.slug = `event_${Date.now()}`;

			// push adverse event
			const adverseEvents = { ...this.localModel, [adverseEvent.slug]: { ...adverseEvent } };
			Object.assign(this.localModel, adverseEvents);

			// emit data to parent component
			this.emitScore();

			// Reset model
			this.adverseEventModel = { ...this.$options.staticData.adverseEventDefaults }
		},
	},

	watch: {
		// Init localModel from component prop "values" (parent v-model = caseData.adverseEvents)
		value: {
			immediate: true, deep: true,
			handler: function (data) {
				// init local model
				if (data) this.localModel = structuredClone(data);
				// init object
				if (!this.localModel) this.localModel = {};
			}
		},
	},


}

</script>


<style lang="scss" scoped>
.button--cta {
	font-size: 1rem;
	padding: 0.5em;
}

.severity-high {
	color: hsl(6.9, 100%, 40%);
	font-weight: 500;
}

::v-deep {

	.icon-danger {
		color: hsl(6.9, 100%, 40%);
		vertical-align: sub;
		height: 16px;
	}

	.radio-group--inline {

		&,
		.radios {
			flex-wrap: nowrap;
		}

		label,
		.label {
			white-space: nowrap;
			margin: 0;
			font-weight: 400 !important;
		}
	}
}

.checkbox-group--inline {
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 0.5rem;

	label {
		margin: 0;
	}
}

table {
	display: block;
	border-collapse: collapse;
	max-width: 100%;
	overflow-x: scroll;
}

th.label {
	min-width: 20rem;
}

th.studyAccountability,
th.studyProcessingEffect {
	min-width: 11rem;
}

th,
td {
	padding: .5rem;
}

th {
	vertical-align: top;
	min-width: 8rem;
}

td {
	vertical-align: top;
}
</style>