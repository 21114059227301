<template>
	<div class="score score--dapsa rythm-v-large">
		<h4>Score DAPSA<br><small>(Disease Activity in PSoriatic Arthritis)</small></h4>

		<section class="rythm-v">
			<h5>Articulations douloureuses</h5>
			<div class="rythm-v">
				<p>Nombre d'articulations douloureuses : <strong>{{ painfulJointsTotal }}</strong></p>
				<DasSelect :key="`dapsa_painfuljoints_${session.id}`"
									 set="dapsa"
									 v-model="painfulJoints.checkedDots"
									 @input="emitScore"></DasSelect>
			</div>
		</section>


		<section class="rythm-v">
			<h5>Articulations gonflées</h5>
			<div class="rythm-v">
				<p>Nombre d'articulations gonflées : <strong>{{ swollenJointsTotal }}</strong></p>
				<DasSelect :key="`dapsa_swollenjoints_${session.id}`"
									 set="dapsa"
									 v-model="swollenJoints.checkedDots"
									 @input="emitScore"></DasSelect>
			</div>
		</section>

		<!-- CRP -->
		<div class="flex-row flex-gap-large">
			<div class="flex-item--50">
				<div class="input-group--inline required">
					<label for="dapsa_crp">CRP (mg/l) :</label>
					<input type="number"
								 name="dapsa_crp"
								 id="dapsa_crp"
								 v-model="crp"
								 v-bind="$attrs"
								 step=".01"
								 required
								 @input="emitScore" />
				</div>
			</div>
		</div>

		<!-- EVA Index -->
		<section class="rythm-v">
			<h5>EVA (DAPSA)</h5>

			<div class="eva rythm-v-large">
				<div>
					En tenant compte des conséquences de votre rhumatisme psoriasique sur votre vie quotidienne, conjugale, familiale et sociale, comment évalueriez-vous l’activité de votre rhumatisme durant la semaine précédente sur cette échelle où <strong>0 représente une maladie inactive</strong> et <strong>10 une maladie très active</strong> ?
				</div>

				<RangeInput id="activity_patient"
										label="<strong>EVA activité patient</strong>"
										min="0"
										max="10"
										before="Maladie inactive"
										after="Maladie très active"
										data-display-range="true"
										required
										v-bind="$attrs"
										v-model="eva.activity_patient"
										@input="emitScore" />

				<div>Comment décrirez-vous l’intensité de vos douleurs articulaires durant la semaine précedente. Son extrémité gauche est marquée « pas de douleur ».
					<br>Elle est reliée par un trait à l’extrémité droite marquée « douleur maximale imaginable ».
				</div>

				<RangeInput id="pain_patient"
										label="<strong>EVA douleur patient</strong>"
										min="0"
										max="10"
										before="Pas de douleur"
										after="Douleur maximale"
										data-display-range="true"
										required
										v-bind="$attrs"
										v-model="eva.pain_patient"
										@input="emitScore" />

				<div>
					<radio-group class="radio-group--inline"
											 :radios="psoriasis_area_options"
											 label="<strong>Surface corporelle atteinte par le psoriasis</strong> (sachant que 1% correspond à la surface de la main doigts inclus) :"
											 id="psoriasis_area"
											 name="psoriasis_area"
											 v-model="eva.psoriasis_area"
											 v-bind="$attrs"
											 required
											 @input="emitScore"></radio-group>
				</div>

			</div>

		</section>


		<div class="flex-row flex-gap-large">
			<div class="flex-item--100 flex-row flex-gap-large">
				<!-- Display results -->
				<div class="alert-success flex-item--100">
					<ul>
						<li><span class="label">Articulations douloureuses :</span> <strong>{{ painfulJointsTotal }}</strong></li>
						<li><span class="label">Articulations gonflées :</span> <strong>{{ swollenJointsTotal }}</strong></li>
						<li>
							<span class="label">DAPSA CRP :</span> <template v-if="!isNaN(scoreCRP)"><strong>{{ scoreCRP }}</strong> <span v-if="diseaseActivity">({{ diseaseActivity }})</span></template>
						</li>
					</ul>
				</div>
			</div>
		</div>

	</div>

</template>


<script>
import DasSelect from "./DasSelect.vue";
import RangeInput from "./RangeInput.vue";
import radioGroup from "@/components/radioGroup.vue";

export default {
	name: 'ScoreDapsa',
	inheritAttrs: false,
	components: {
		DasSelect, RangeInput, radioGroup
	},
	props: [
		'value', 'session'
	],

	data() {
		return {
			painfulJoints: {
				checkedDots: [],
			},
			swollenJoints: {
				checkedDots: [],
			},
			crp: 0,
			eva: {
				activity_patient: 0,
				pain_patient: 0,
				psoriasis_area: "",
			},

			psoriasis_area_options: [
				{ value: 'De 1 % à 9 %', },
				{ value: 'De 10 % à 29 %', },
				{ value: 'De 30 % à 49 %', },
				{ value: 'De 50 % à 69 %', },
				{ value: 'De 70 % à 89 %', },
				{ value: '90 % à 100 %', },
			]
		}
	},

	computed: {

		// Disease Activity: scoreCRP = 0-4 Remission, 5-14 low, 15-28 moderate, >28 high Disease Activity
		diseaseActivity() {
			if (this.scoreCRP >= 0 && this.scoreCRP <= 4) {
				return 'rémission';
			} else if (this.scoreCRP >= 5 && this.scoreCRP <= 14) {
				return 'activité faible';
			} else if (this.scoreCRP >= 15 && this.scoreCRP < 28) {
				return 'activité modérée';
			} else if (this.scoreCRP >= 28) {
				return 'activité élevée';
			} else {
				return '';
			}
		},

		painfulJointsTotal() {
			if (!this?.painfulJoints?.checkedDots.length) return 0;
			return this.painfulJoints.checkedDots.length;
		},

		swollenJointsTotal() {
			if (!this?.swollenJoints?.checkedDots.length) return 0;
			return this.swollenJoints.checkedDots.length;
		},

		/**
		 * DAPSA score = TenderJoints + SwollenJoints + CRP + Activity + Pain
		 * https://rheuma.charite.de/fileadmin/user_upload/microsites/ohne_AZ/m_cc13/rheuma/Templates/DAPSA_ENG.pdf
		 * 
		 */
		scoreCRP() {
			const painfulJointsTotal = this.painfulJointsTotal || 0;
			const swollenJointsTotal = this.swollenJointsTotal || 0;
			const crp = this?.crp ? parseFloat(this.crp) : 0;
			const activity = this?.eva?.activity_patient ? parseFloat(this.eva.activity_patient) : 0;
			const pain = this?.eva?.pain_patient ? parseFloat(this.eva.pain_patient) : 0;
			const score = (painfulJointsTotal + swollenJointsTotal + crp + activity + pain).toFixed(2);
			return score;
		},

		results() {
			return {
				score: `${this.scoreCRP}`, // raw score always as string
				scoreRendered: `DAPSA CRP : ${this.scoreCRP}`, // formatted score, ex. with unit 24%, 12mm, …
				values: {
					painfulJoints: this.painfulJoints.checkedDots,
					swollenJoints: this.swollenJoints.checkedDots,
					painfulJointsTotal: this.painfulJointsTotal,
					swollenJointsTotal: this.swollenJointsTotal,
					crp: this.crp,
					eva: this.eva,
					score_crp: this.scoreCRP
				},
				details: null,
				detailsRendered: '',
			};
		}
	},

	methods: {
		emitScore() {
			this.$emit('input', this.results);
		}
	},

	watch: {
		// Init data values from component props ("values")
		value: {
			immediate: true,
			handler: function (data) {
				this.painfulJoints.checkedDots = data?.values?.painfulJoints || [];
				this.swollenJoints.checkedDots = data?.values?.swollenJoints || [];
				this.crp = data?.values?.crp || 0;
				if (data?.values?.eva) this.$set(this, 'eva', { ...data?.values?.eva });
			},
		},
	},
}
</script>


<style lang="scss" scoped>
::v-deep {
	.radio-group--psoriasis_area.radio-group--inline {
		.label {
			flex-basis: 100%;
		}
	}
}
</style>