<template>
	<div :class="componentClass">
		<span class="label"
					v-html="label"
					v-if="label"></span>

		<label v-for="(radio, index) in radios "
					 :key="`${id}_${index}`"
					 :for="`${id}_${index}`"
					 :class="radio?.class"
					 :title="radio?.title">
			<input type="radio"
						 :value="radio.value"
						 :name="`${id}`"
						 :id="`${id}_${index}`"
						 :required="isRequired"
						 v-model="status"
						 v-bind="$attrs" />
			<span class="caption">{{ radio.label || radio.value }}</span>
		</label>
	</div>
</template>

<script>
export default {
	name: "radio-group",
	inheritAttrs: false,
	props: {
		// The value of the component (status)
		value: {
			type: String,
			default: "",
		},

		// Unique slug for each instance inputs
		id: {
			type: String,
			default: "radio_group",
		},

		// Label for the group of inputs
		label: {
			type: String,
			default: "",
		},

		required: {
			type: Boolean,
			default: false,
		},

		// Radios input
		radios: {
			type: Array,
			default: function () {
				let radios = [
					{
						label: "Radio One",
						value: "radio-one",
					},
					{
						label: "Radio Two",
						value: "radio-two",
					},
				];
				return radios;
			},
		},
	},

	data: function () {
		return {};
	},

	computed: {

		componentClass() {
			const componentId = this.id;
			const required = this.isRequired ? "required" : "";
			return `radio-group--${componentId} ${required}`;
		},

		isRequired() {
			return this.required || false;
		},

		// Model for the value prop of the component
		status: {
			get() {
				if (typeof this.value === "undefined") return "";
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
				this.$emit("change", val);
			},
		},
	},
};
</script>
