var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.users)?_c('section',{staticClass:"section--profile card shadow rythm-v"},[_c('h2',[_vm._v("Liste des utilisateurs")]),_c('vue-good-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.usersTable.rows.length),expression:"usersTable.rows.length"}],attrs:{"fixed-header":true,"columns":_vm.usersTable.columns,"rows":_vm.usersTable.rows,"paginate":true,"pagination-options":{
      enabled: true,
      nextLabel: 'suivant',
      prevLabel: 'précédent',
      rowsPerPageLabel: 'lignes par page',
      ofLabel: 'sur',
      pageLabel: 'page', // for 'pages' mode
      allLabel: 'Toutes',
    },"globalSearch":true,"search-options":{
      enabled: true,
      skipDiacritics: true,
      placeholder: 'Rechercher dans cette liste',
      externalQuery: _vm.searchTerms,
    },"styleClass":"vgt-table striped"},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.field == 'actions')?_c('span',{staticClass:"offscreen"},[_vm._v("Actions")]):_c('span',[_vm._v(_vm._s(props.column.label))])]}},{key:"table-row",fn:function(props){return [(['actions', 'email'].indexOf(props.column.field) === -1)?_c('span',[_vm._v(_vm._s(props.row[props.column.field]))]):_vm._e(),(props.column.field === 'email')?_c('span',[_c('a',{attrs:{"href":'mailto:' + props.row[props.column.field]}},[_vm._v(_vm._s(props.row[props.column.field]))])]):_vm._e(),(props.column.field == 'actions')?_c('router-link',{staticClass:"button--icon",attrs:{"to":`/user/${props.row.id}/profile`}},[_c('svg',{staticClass:"icon icon-edit",attrs:{"focusable":"false","role":"img","aria-labelledby":"button-edit-user-title","xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('title',{attrs:{"id":"button-edit-user-title"}},[_vm._v(" Modifier ")]),_c('path',{attrs:{"d":"M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"}}),_c('path',{attrs:{"d":"M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"}})])]):_vm._e(),(
          props.column.field == 'actions' && _vm.userCan('delete_users', _vm.user)
        )?_c('button',{staticClass:"button--icon",on:{"click":function($event){return _vm.deleteUser(props.row.id)}}},[_c('svg',{staticClass:"icon feather feather-user-x",attrs:{"aria-labelledby":"button-remove-user-title","focusable":"false","role":"img","xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('title',{attrs:{"id":"button-remove-user-title"}},[_vm._v(" Supprimer ")]),_c('path',{attrs:{"d":"M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"}}),_c('circle',{attrs:{"cx":"8.5","cy":"7","r":"4"}}),_c('line',{attrs:{"x1":"18","y1":"8","x2":"23","y2":"13"}}),_c('line',{attrs:{"x1":"23","y1":"8","x2":"18","y2":"13"}})])]):_vm._e()]}}],null,false,1636537747)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }